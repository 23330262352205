import { createPageTitle } from "@/shared/routing/TitleGuard";
import { useFlowState } from "@/shared/stateMachine/hooks";
import { Grid, Typography } from "@mui/material";
import { gtm, virtualPageView } from "@racwa/analytics";
import { MultiChoiceInput } from "raci-react-library";
import { useEffect } from "react";
import { FormProvider, useWatch } from "react-hook-form";
import { useLocation } from "react-router-dom";
import {
  StyledMultiChoiceInputContainer,
  StyledSubheadingContainer,
  StyledSubheadingText,
  StyledSubmitButton,
} from "../styled";
import { KnockoutNotificationCard } from "./components/KnockoutNotificationCard";
import { StormDamagedFenceClaimFormProps } from "./types";
const testId = "storm-damaged-fence-claim";
const selectedFenceTypeName = "selectedFenceType";

const StormDamagedFenceClaimForm = ({
  form,
  subheading,
  options,
  onSubmit,
  onSelectionChange,
}: StormDamagedFenceClaimFormProps) => {
  const {
    control,
    formState: { isSubmitting },
  } = form;

  const location = useLocation();
  const { getFlowUrl } = useFlowState();

  useEffect(() => {
    const pageName = "Storm damaged fence claim";
    const currentPage = location.pathname.toLocaleLowerCase();
    const virtualPageViewUrl = `${currentPage}${getFlowUrl()}`;
    gtm(
      virtualPageView({
        url: virtualPageViewUrl,
        title: createPageTitle(pageName),
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const watchSelectedFenceType = useWatch({ control, name: selectedFenceTypeName });
  const knockout = watchSelectedFenceType?.length > 1;

  return (
    <FormProvider {...form}>
      <form action="#" onSubmit={form.handleSubmit(onSubmit)} data-testid={testId} noValidate>
        <Grid container item xs={12} direction="column" alignItems="center">
          <StyledSubheadingContainer item xs={12}>
            <StyledSubheadingText variant="h2">{subheading}</StyledSubheadingText>
          </StyledSubheadingContainer>
          <Grid>
            <Typography>You can choose more than one option.</Typography>
          </Grid>
          <StyledMultiChoiceInputContainer item>
            <MultiChoiceInput
              optionsFullWidth
              name={selectedFenceTypeName}
              data-testid={testId}
              options={options}
              onSelectionChange={onSelectionChange}
              errorLabel="Please select all options that apply"
            />
            {knockout && <KnockoutNotificationCard />}
            <Grid item>
              <StyledSubmitButton
                variant="contained"
                color="primary"
                type="submit"
                name="submit"
                data-testid={`${testId}-button`}
                fullWidth
                disabled={knockout || isSubmitting}
              >
                Next
              </StyledSubmitButton>
            </Grid>
          </StyledMultiChoiceInputContainer>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default StormDamagedFenceClaimForm;
