import {
  ADB2C_CLIENT_ID,
  ADB2C_DOMAIN,
  ADB2C_RETURN_URL,
  ADB2C_TENANT,
  PRODUCT_LINKING_REDIRECT_URL,
  SILENT_LOGIN_REDIRECT_URL,
} from "@/shared/constants";
import useMyRacLogin from "@/shared/hooks/useMyRacLogin";
import { MyRacAuthProps } from "raci-react-library";

export const racwaAuthProps = {
  clientId: ADB2C_CLIENT_ID,
  tenant: ADB2C_TENANT,
  domain: ADB2C_DOMAIN,
};

export const getMyRacAuthProps = (): Omit<MyRacAuthProps, "children"> => ({
  clientId: ADB2C_CLIENT_ID,
  silentLoginRedirectUrl: SILENT_LOGIN_REDIRECT_URL,
  loginRedirectUrl: ADB2C_RETURN_URL,
  productLinkingRedirectUrl: PRODUCT_LINKING_REDIRECT_URL,
  useMyRacLogin: useMyRacLogin,
  requireLogin: false,
});
