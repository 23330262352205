import { CallToActionButton } from "@/shared/components/CallToActionButton";
import PageTitle from "@/shared/components/PageTitle";
import { useLookupApiClient, useMfaOtpServiceApiClient } from "@/shared/hooks/useApiClient";
import maskAccountNumber from "@/shared/utils/maskAccountNumber";
import { Grid } from "@mui/material";
import { OneTimePasswordDialog } from "@react-monorepo/react-library";
import { BankAccountSelection, EMPTY_URL, useBsbLookup, useSessionIdStorageKey } from "raci-react-library";
import { useState } from "react";
import { FormProvider } from "react-hook-form";
import BankDetailsNotice from "./components/BankDetailsNotice";
import NoBankDetailsInput from "./components/NoBankDetailsInput";
import SettlementSubTitle from "./components/SettlementSubTitle";
import { YourBankDetailsFormProps, YourBankDetailsFormValues } from "./types";

const YourBankDetailsForm = ({
  form,
  onSubmit,
  hasNoPaymentAccounts,
  activeAccounts,
  defaultSelection,
}: YourBankDetailsFormProps) => {
  const {
    handleSubmit,
    watch,
    formState: { isSubmitting },
  } = form;
  const hideDetailsForm = watch("dontHaveBankDetails");
  const isAddingNewAccount = watch("isAddingAccount");
  const lookupApiClient = useLookupApiClient();
  const { onBsbLookup } = useBsbLookup({ apiClient: lookupApiClient });
  const mfaOtpApiClient = useMfaOtpServiceApiClient();
  const sessionIdStorageKey = useSessionIdStorageKey();
  const sessionId = sessionStorage.getItem(sessionIdStorageKey);
  const [showMfa, setShowMfa] = useState<boolean>(false);

  const submitWrapper = (values: YourBankDetailsFormValues) => {
    if (values.isAddingAccount && !values.dontHaveBankDetails) {
      setShowMfa(true);
    } else {
      onSubmit(values);
    }
  };

  return (
    <FormProvider {...form}>
      <form action="#" onSubmit={handleSubmit(submitWrapper)} data-testid="yourBankDetailsForm" noValidate>
        <Grid container spacing={1} direction="column">
          <Grid item xs={12}>
            <PageTitle id="header" title="Your bank details" showClaimNumber />
          </Grid>
          <Grid item>
            <SettlementSubTitle />
          </Grid>
          {!hideDetailsForm && (
            <>
              <Grid item>
                <BankAccountSelection
                  title={hasNoPaymentAccounts ? "Add an account" : "Choose or add an account"}
                  defaultValue={defaultSelection}
                  onBsbLookup={onBsbLookup}
                  bankAccounts={activeAccounts?.map((x) => ({
                    ...x,
                    bsb: `${x.bsb.substring(0, 3)} ${x.bsb.substring(3, 6)}`,
                    accountNumberSuffix: maskAccountNumber(x.accountNumberSuffix),
                    externalNumber: x.id,
                  }))}
                />
              </Grid>
              {isAddingNewAccount && (
                <Grid item>
                  <BankDetailsNotice />
                </Grid>
              )}
            </>
          )}
          <Grid item>
            <NoBankDetailsInput />
          </Grid>
          <Grid item xs={12}>
            <CallToActionButton sx={{ margin: "25px 0 30px 0" }} disabled={isSubmitting}>
              {form.getValues().dontHaveBankDetails ? "Provide bank details later" : "Finalise settlement"}
            </CallToActionButton>
          </Grid>
        </Grid>
        {showMfa && (
          <OneTimePasswordDialog
            apiClient={mfaOtpApiClient}
            sessionKey={sessionId ?? ""}
            showDialog={showMfa}
            onClickClose={() => setShowMfa(false)}
            onError={async () => await onSubmit({ ...form.getValues(), mfaFailed: true })}
            onSuccess={async () => {
              await onSubmit(form.getValues());
              setShowMfa(false);
            }}
            faqUrl={process.env.REACT_APP_MY_RAC_HELP_PAGE ?? EMPTY_URL}
          />
        )}
      </form>
    </FormProvider>
  );
};

export default YourBankDetailsForm;
