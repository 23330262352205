import { errorStateAtom, sessionIdAtom } from "@/shared/atoms";
import { ErrorState } from "@/shared/types";
import {
  HTTP_STATUS_CODE_OK,
  LoadingStatus,
  useFileUploadSession,
  useIsLoggedInToMyRac,
  useSessionIdStorageKey,
  useSetBackdrop,
} from "raci-react-library";
import { useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import { useBffApiClient, useDocumentServiceApiClient } from "../useApiClient";

export const useCreateSession = () => {
  const client = useBffApiClient();
  const setBackdrop = useSetBackdrop();
  const sessionIdStorageKey = useSessionIdStorageKey();
  const isLoggedIn = useIsLoggedInToMyRac();
  const setSessionId = useSetRecoilState(sessionIdAtom);
  const setErrorState = useSetRecoilState(errorStateAtom);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const documentServiceApiClient = useDocumentServiceApiClient();
  const { getSession } = useFileUploadSession({ apiClient: documentServiceApiClient });

  useEffect(() => {
    let isCancelled = false;

    const createSession = async () => {
      setIsError(false);

      const hasSessionId = sessionStorage.getItem(sessionIdStorageKey);

      try {
        setBackdrop(true);
        if (!isCancelled && !hasSessionId) {
          if (!isLoggedIn) {
            setIsError(true);
          } else {
            const response = await client.createSession();

            sessionStorage.setItem(sessionIdStorageKey, response.result);
            setSessionId(response.result);
            setErrorState(ErrorState.None);
            if (response.status !== HTTP_STATUS_CODE_OK) {
              setIsError(true);
            }
          }
        }
      } catch {
        setIsError(true);
      } finally {
        setIsLoading(false);
        setBackdrop(false);
      }
    };

    createSession();
    getSession();

    return () => {
      isCancelled = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client, sessionIdStorageKey]);

  return isLoading ? LoadingStatus.Loading : isError ? LoadingStatus.Error : LoadingStatus.Successful;
};

export default useCreateSession;
