import { useBffApiClient } from "@/shared/hooks/useApiClient";
import useErrorHandling from "@/shared/hooks/useErrorHandling";
import { StateMachineContext, useFlowState } from "@/shared/stateMachine/hooks";
import { stateUrlLookup } from "@/shared/stateMachine/lookups";
import { WaterDamageRoute } from "@/views/building/WaterDamage";
import { WaterDamageState } from "@/views/building/WaterDamage/types";
import { ContentsDamageRoute } from "@/views/contents/ContentsDamage";
import { ContentsDamageState } from "@/views/contents/ContentsDamage/types";
import { event, gtm } from "@racwa/analytics";
import {
  ClaimsHomeGeneralDamageApiException,
  ContentsListRequest,
  WaterDamageProblems,
} from "raci-claims-home-general-damage-clientproxy";
import { useGetSessionState, useSessionState, useSetBackdrop } from "raci-react-library";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { ContentsListFormProps, ContentsListFormValues, ContentsListState } from "../../types";

export const useContentsList = (): ContentsListFormProps => {
  const actor = StateMachineContext.useActorRef();
  const navigate = useNavigate();
  const apiClient = useBffApiClient();
  const setBackdrop = useSetBackdrop();
  const handleError = useErrorHandling();
  const { waterDamageToCarpets } = useGetSessionState<ContentsDamageState>(ContentsDamageRoute.path);
  const [contentsListState, setContentsListState] = useSessionState<ContentsListState>({
    skipPageTrackingRecalculation: true,
  });
  const { waterDamageProblems } = useGetSessionState<WaterDamageState>(WaterDamageRoute.path);
  const buildingWaterDamage = waterDamageProblems?.some((problem) => problem.key !== WaterDamageProblems.NoWaterDamage);
  const waterDamageRestorer = waterDamageProblems?.some(
    (problem) =>
      problem.key === WaterDamageProblems.HouseIsFlooded ||
      problem.key === WaterDamageProblems.BadlySoakedCarpets ||
      problem.key === WaterDamageProblems.SewageOrDrainWaterInTheHouse,
  );
  const { isContentsOnly, isContentsAndFence, isBuildingAndContents, isBuildingContentsAndFence } = useFlowState();

  const getDefaultValues = () => {
    if (!contentsListState?.contentsList) {
      return {
        ...contentsListState,
        contentsList: [{ name: "", value: "" }],
      };
    }

    return contentsListState ?? { contentsList: [{ name: "", value: "" }] };
  };

  const form = useForm<ContentsListFormValues>({
    mode: "onSubmit",
    reValidateMode: "onChange",
    defaultValues: getDefaultValues(),
  });

  const onSubmit = async (values?: ContentsListFormValues) => {
    try {
      setBackdrop(true);

      const contentsList = values?.contentsList;
      const damageToContents = values?.anyOtherDamagedContents;

      const bypassContentsList = damageToContents === false || waterDamageRestorer === true;

      setContentsListState({
        ...contentsListState,
        ...values,
        contentsList: bypassContentsList ? undefined : contentsList,
        isCompleted: true,
      });

      if (bypassContentsList) {
        await apiClient.contentsList({ contentsList: [], damageToContents: damageToContents });
      } else if (!bypassContentsList && contentsList) {
        gtm(event(`contents list items submitted - ${contentsList.length}`));

        const request: ContentsListRequest = {
          contentsList: contentsList.map((item) => ({
            ...item,
            value: parseFloat(item.value),
          })),
          damageToContents: damageToContents,
        };

        await apiClient.contentsList(request);
      }

      actor.send({ type: "contentsList.next" });
      navigate(stateUrlLookup[actor.getSnapshot().value]);
    } catch (exception) {
      const error = exception as ClaimsHomeGeneralDamageApiException;
      handleError({
        message: "Error submitting contents list",
        shouldRedirect: true,
        customProperties: {
          request: "POST /step/contents-list",
          status: error.status,
          error: error.message,
        },
      });
    } finally {
      setBackdrop(false);
    }
  };

  return {
    form,
    onSubmit,
    waterDamageToCarpets: waterDamageToCarpets ?? false,
    buildingWaterDamage,
    waterDamageRestorer,
    isContentsOnly,
    isContentsAndFence,
    isBuildingAndContents,
    isBuildingContentsAndFence,
  };
};

export default useContentsList;
