import { event, gtm } from "@racwa/analytics";
import {
  DialogBoxWithButton,
  KEYBOARD_CODE_ENTER,
  KEYBOARD_CODE_ENTER_NUMPAD,
  KEYBOARD_CODE_SPACE,
} from "raci-react-library";
import { useState } from "react";
import { StyledLink } from "../../styled";

export const FixMyFenceDialog: React.FC = () => {
  const [open, setOpen] = useState(false);
  const title = "Why can't you just fix my fence?";

  const handleOnClick = () => {
    gtm(event(title));
    setOpen(true);
  };

  const handleKeyboard = (event: any) => {
    if (
      event.code === KEYBOARD_CODE_ENTER ||
      event.code === KEYBOARD_CODE_SPACE ||
      event.code === KEYBOARD_CODE_ENTER_NUMPAD
    ) {
      handleOnClick();
    }
  };

  return (
    <>
      <StyledLink onClick={handleOnClick} tabIndex={0} onKeyDown={handleKeyboard}>
        {title}
      </StyledLink>
      <DialogBoxWithButton
        showDialog={open}
        setShowDialog={setOpen}
        title={title}
        buttonText="Close"
        content="You and your neighbour share ownership of the fence. You're both responsible for the repairs so you'll need to talk to your neighbour to get your fence fixed."
      />
    </>
  );
};
