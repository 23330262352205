import { NotificationCard } from "raci-react-library";

export const YourSafetyNotificationCard = () => {
  return (
    <NotificationCard
      severity="info"
      message={{
        title: "Your safety is important",
        content: (
          <>
            Once you submit your claim, we'll:
            <ul>
              <li>Contact you to discuss how we can help.</li>
              <li>Get a repairer to contact you to assess your damage.</li>
            </ul>
          </>
        ),
      }}
    />
  );
};

export default YourSafetyNotificationCard;
