import { CallToActionButton } from "@/shared/components/CallToActionButton";
import ExcessCard from "@/shared/components/ExcessCard";
import PageTitle from "@/shared/components/PageTitle";
import { Grid } from "@mui/material";
import { FormProvider } from "react-hook-form";
import { YourExcessFormProps } from "./types";

const YourExcessForm = ({ form, onSubmit, excess }: YourExcessFormProps) => {
  const {
    handleSubmit,
    formState: { isSubmitting },
  } = form;
  return (
    <FormProvider {...form}>
      <form action="#" onSubmit={handleSubmit(onSubmit)} data-testid="your-excess-form" autoComplete="on" noValidate>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <PageTitle id="header" title="Your excess" showClaimNumber />
          </Grid>
          <Grid item xs={12}>
            <ExcessCard excess={excess} />
          </Grid>
          <Grid item xs={12}>
            <CallToActionButton disabled={isSubmitting}>Next</CallToActionButton>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default YourExcessForm;
