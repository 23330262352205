import { CallToActionButton } from "@/shared/components/CallToActionButton";
import PageTitle from "@/shared/components/PageTitle";
import { Grid, Link } from "@mui/material";
import { event, fieldTouched, gtm } from "@racwa/analytics";
import { ClaimDetailsLockedCard, PolicyPicker } from "raci-react-library";
import { useState } from "react";
import { FormProvider } from "react-hook-form";
import { PolicyNotListedDialog } from "./components/PolicyNotListedDialog";
import { StyledTypography } from "./styled";
import { YourPolicyFormProps } from "./types";

export const YourPolicyForm = ({
  form,
  onSubmit,
  showPolicyNotListedDialog,
  setShowPolicyNotListedDialog,
  claimNumber,
  submitError,
  initialPolicyNumber,
  policyOptions,
}: YourPolicyFormProps) => {
  const {
    handleSubmit,
    formState: { isSubmitting },
  } = form;

  const [policyNumber, setPolicyNumber] = useState(initialPolicyNumber);

  const onSubmitWrapper = () => {
    const values = {
      policyNumber: policyNumber,
    };
    onSubmit(values);
  };

  const whichHomeLabel = "Which home was damaged?";
  const policyNotListedLabel = "Home insurance policy not listed?";

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmitWrapper)} action="#">
        <Grid container spacing={3} direction="column">
          <Grid item xs={12}>
            <PageTitle id="header" title={whichHomeLabel} showClaimNumber />
            <StyledTypography variant="body1">You can only choose one policy at a time.</StyledTypography>
          </Grid>
          <Grid item xs>
            <PolicyPicker
              options={policyOptions}
              value={policyNumber}
              onChange={(value: string) => {
                !claimNumber && setPolicyNumber(value);
                gtm(fieldTouched(whichHomeLabel));
              }}
              helperText="Please select an option"
              error={submitError && !policyNumber}
            />
          </Grid>
          <Grid item xs>
            {claimNumber ? (
              <ClaimDetailsLockedCard />
            ) : (
              <Link
                style={{ cursor: "pointer" }}
                variant="h4"
                onClick={() => {
                  gtm(event(policyNotListedLabel));
                  setShowPolicyNotListedDialog(true);
                }}
              >
                {policyNotListedLabel}
              </Link>
            )}
          </Grid>
          <Grid item xs>
            <CallToActionButton sx={{ margin: 0 }} disabled={isSubmitting}>
              Next
            </CallToActionButton>
          </Grid>
        </Grid>
        <PolicyNotListedDialog showDialog={showPolicyNotListedDialog} setShowDialog={setShowPolicyNotListedDialog} />
      </form>
    </FormProvider>
  );
};

export default YourPolicyForm;
