import { useErrorHandling } from "@/shared/hooks/useErrorHandling";
import useStepperPage from "@/shared/hooks/useStepperPage";
import { createPageTitle } from "@/shared/routing/TitleGuard";
import { StateMachineContext } from "@/shared/stateMachine/hooks";
import CashSettlementFactSheet from "@/views/nonSharedFence/CashSettlementFactSheet";
import YourSettlementOptions from "@/views/sharedFence/YourSettlementOptions";
import { RacwaStepperTemplate } from "@racwa/react-components";
import { useEffect } from "react";

export const sharedFencePageName = "Your settlement options";
export const nonSharedFencePageName = "Fact sheet";
export const stepperName = "Your settlement options";

const Settlement = () => {
  const fenceFlow = StateMachineContext.useSelector((state) => state.context.fenceFlow);
  const flow = StateMachineContext.useSelector((state) => state.context.flow);
  const handleError = useErrorHandling();
  const stepperProps = useStepperPage();

  let content = null;
  switch (fenceFlow) {
    case "shared":
      content = <YourSettlementOptions />;
      break;
    case "nonShared":
      content = <CashSettlementFactSheet />;
      break;
    default:
      handleError({
        message: `The '${flow}' flow does not have a configuration for the 'Settlement' component.`,
        shouldRedirect: true,
      });
  }

  useEffect(() => {
    document.title = createPageTitle(fenceFlow === "shared" ? sharedFencePageName : nonSharedFencePageName);
  }, [fenceFlow]);

  return <RacwaStepperTemplate {...stepperProps}>{content}</RacwaStepperTemplate>;
};

export const SettlementRoute = {
  path: "settlement",
  element: <Settlement />,
} as const;

export default Settlement;
