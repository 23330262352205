import { CallToActionButton } from "@/shared/components/CallToActionButton";
import PageTitle from "@/shared/components/PageTitle";
import { Grid } from "@mui/material";
import { event, fieldTouched, gtm } from "@racwa/analytics";
import { SelectedSpecificDamageTypes } from "raci-claims-home-general-damage-clientproxy";
import { NotificationCard, SelectionGroup } from "raci-react-library";
import { FormProvider, useWatch } from "react-hook-form";
import { specificDamageOptions } from "./constants";
import { SpecificDamageFormProps } from "./types";

const SpecificDamageForm = ({ form, onSubmit }: SpecificDamageFormProps) => {
  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
  } = form;

  const damagedItemsName = "damagedItems";
  const pageTitle = "Tell us about any specific damage";

  const damagedItemsWatch = useWatch({ control, name: damagedItemsName });

  let cardMessage =
    damagedItemsWatch?.length > 1
      ? "Once you submit your claim, we'll get our repairers to contact you to assess your damage"
      : "Once you submit your claim, we'll get a repairer to contact you to assess your damage";

  if (damagedItemsWatch?.some((item) => item === SelectedSpecificDamageTypes.OtherItems)) {
    cardMessage = "Once you submit your claim, we'll contact you to assess your damage";
  }

  const listCards = specificDamageOptions.map((option) => (
    <SelectionGroup.ListCard
      key={option.key}
      value={option.key}
      label={option.label}
      icon={option.icon}
      onSelect={() => {
        gtm(fieldTouched(pageTitle));
        gtm(event(`${pageTitle} - ${option.label}`));
      }}
    />
  ));

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)} action="#">
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <PageTitle id="header" title={pageTitle} showClaimNumber />
          </Grid>
          <Grid item xs={12}>
            <SelectionGroup
              name={damagedItemsName}
              label={"Select all the items that have been damaged."}
              helperText="Please select all options that apply"
            >
              <SelectionGroup.Column>{listCards}</SelectionGroup.Column>
            </SelectionGroup>
            {damagedItemsWatch?.length > 0 && (
              <Grid sx={{ paddingTop: "24px" }}>
                <NotificationCard
                  severity="info"
                  message={{
                    title: cardMessage,
                  }}
                />
              </Grid>
            )}
          </Grid>
          <Grid item xs={12}>
            <CallToActionButton disabled={isSubmitting}>Next</CallToActionButton>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default SpecificDamageForm;
