import { useFlowState } from "@/shared/stateMachine/hooks";
import {
  IconDefinition,
  faClock,
  faHouseChimney,
  faPeopleArrowsLeftRight,
  faToolbox,
} from "@fortawesome/free-solid-svg-icons";
import { Divider, Grid, Typography } from "@mui/material";
import { colors } from "@racwa/styles";
import { CardWithIcon, EMPTY_URL, ProductDisclosureStatementLink } from "raci-react-library";
import { ReactNode } from "react";
import { TitleTypography } from "../styled";

export const ContentsAndFence: React.FC = () => {
  const { fenceFlow } = useFlowState();
  const cardInfo: { icon: IconDefinition; title: string; text?: ReactNode }[] = [
    { icon: faPeopleArrowsLeftRight, title: "Firstly, we hope you're okay." },
    {
      icon: faClock,
      title: "This may take around 10 minutes.",
    },
    {
      icon: faHouseChimney,
      title: "Your contents",
      text: (
        <>
          You can claim for items such as furniture, computers, carpets and above-ground pools. See the{" "}
          <ProductDisclosureStatementLink
            showAbbreviation={false}
            showInline={true}
            url={process.env.REACT_APP_RAC_PDS_PED_URL || EMPTY_URL}
          />{" "}
          for more information.
        </>
      ),
    },
  ];

  if (fenceFlow === "shared") {
    cardInfo.push({
      icon: faPeopleArrowsLeftRight,
      title: "Your shared fence",
      text: "Once your claim is accepted, you'll need to organise the repairs with your neighbour.",
    });
  } else if (fenceFlow === "nonShared") {
    cardInfo.push({
      icon: faToolbox,
      title: "Your non-shared fence",
      text: "You can organise your own fence repairs or we'll fix it for you.",
    });
  }

  return (
    <>
      {cardInfo.map((card, index) => (
        <Grid item xs={12} key={card.title}>
          <CardWithIcon
            border={false}
            spacing={2}
            sx={{ paddingLeft: "0px", paddingRight: "0px", color: colors.dieselDeep }}
            icon={card.icon}
            title={<TitleTypography>{card.title}</TitleTypography>}
            iconStyle={{ marginTop: "5px" }}
            paragraphs={card.text ? [<Typography sx={{ paddingLeft: "28px" }}>{card.text}</Typography>] : undefined}
          />
          {index !== cardInfo.length - 1 && <Divider />}
        </Grid>
      ))}
    </>
  );
};
