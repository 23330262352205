import { FenceTypeIdMapping } from "@/shared/constants";
import { Button, Container, Grid, Link } from "@mui/material";
import { event, gtm } from "@racwa/analytics";
import { theme } from "@racwa/react-components";
import React, { useEffect } from "react";
import NextStepsCard from "./components/Cards/NextStepsCard";
import SeriousStormCard from "./components/Cards/SeriousStormCard";
import TemporaryFenceCard from "./components/Cards/TemporaryFenceCard";
import WhatsNextCard from "./components/Cards/WhatsNextCard";
import YourContentsRestorerCard from "./components/Cards/YourContentsRestorerCard";
import YourSafetyAndSecurity from "./components/Cards/YourSafetyAndSecurity";
import YourSettlementCard from "./components/Cards/YourSettlementCard";
import ConfirmationHeader from "./components/ConfirmationHeader";
import { cardContainerStyles } from "./styles";
import { ConfirmationFormProps } from "./types";

declare global {
  type WidenLiteral<T> = T extends string
    ? string
    : T extends number
      ? number
      : T extends boolean
        ? boolean
        : T extends bigint
          ? bigint
          : T extends symbol
            ? symbol
            : T;

  interface ReadonlyArray<T> {
    includes(searchElement: T | (WidenLiteral<T> & {}), fromIndex?: number): boolean;
  }

  interface Array<T> {
    includes(searchElement: T | (WidenLiteral<T> & {}), fromIndex?: number): boolean;
  }
}

const capitaliseFirstLetter = (word: string) => word.charAt(0).toUpperCase() + word.slice(1);

const getFenceFlow = (isSharedFence: boolean) => (isSharedFence ? "shared" : "non-shared");

export const ConfirmationForm: React.FC<ConfirmationFormProps> = ({
  variant,
  claimNumber,
  firstName,
  fenceType,
  fenceLength,
}) => {
  useEffect(() => {
    if (variant.type === "AcceptCashSettlement" && variant.accountDetails === "error") {
      const fenceFlow = getFenceFlow(variant.isSharedFence);
      gtm(event(`Fence ${fenceFlow} - Path 10: Eligible for settlement - Accepted Settlement - Bank details failed`));
    }

    if (variant.type === "AcceptCashSettlement" && variant.accountDetails === "provided") {
      const fenceFlow = getFenceFlow(variant.isSharedFence);
      gtm(event(`Fence ${fenceFlow} - Path 1: Eligible for settlement - Accepted Settlement - Provided bank details`));
    }

    if (variant.type === "AcceptCashSettlement" && variant.accountDetails === "not-provided") {
      const fenceFlow = getFenceFlow(variant.isSharedFence);
      gtm(
        event(
          `Fence ${fenceFlow} - Path 2: Eligible for settlement - Accepted Settlement - Does not provide bank details`,
        ),
      );
    }

    if (variant.type === "CashSettlementDeclinedToGetRepairQuote") {
      gtm(event("Fence shared - Path 3: Eligible for settlement - Does not accept settlement"));
    }

    if (variant.type === "HasRepairQuote") {
      const fenceFlow = getFenceFlow(variant.isSharedFence);
      gtm(event(`Fence ${fenceFlow} - Path 4: Have a repair quote`));
    }

    if (variant.type === "HasRepairInvoice") {
      const fenceFlow = getFenceFlow(variant.isSharedFence);
      gtm(event(`Fence ${fenceFlow} - Path 5: Already made permanent repairs`));
    }

    if (variant.type === "ExcessHigherThanRepairCost") {
      const fenceFlow = getFenceFlow(variant.isSharedFence);
      gtm(event(`Fence ${fenceFlow} - Path 7: Not eligible for cash settlement: Excess higher than repair costs`));
    }
    if (variant.type === "ClearSomethingUp" || variant.type === "FenceLodgementFailed") {
      const fenceFlow = getFenceFlow(variant.isSharedFence);
      gtm(event(`Fence ${fenceFlow} - Path 8: Payment block, fence claim in last 12 months or allocation failure`));
    }
    if (variant.type === "NotEligibleCashSettlementDueToWoodOrBrick") {
      const fenceFlow = getFenceFlow(variant.isSharedFence);
      gtm(event(`Fence ${fenceFlow} - Path 9: Not eligible for cash settlement brick or wood`));
    }

    if (variant.type === "NotEligibleForCashSettlement") {
      const fenceFlow = getFenceFlow(variant.isSharedFence);
      gtm(event(`Fence ${fenceFlow} - Path 6: Not eligible for cash settlement`));
    }

    if (variant.type === "SettlementDeclinedForRacRepairs") {
      gtm(event("Fence non-shared - Path 11: Eligible for settlement - Choose RAC repairer"));
    }

    if (variant.type === "SettlementDeclinedForContactMe") {
      gtm(event("Fence non-shared - Path 12: Eligible for settlement - Choose RAC call"));
    }

    if (variant.type === "SettlementDeclinedForTakeTimeToDecide") {
      gtm(event("Fence non-shared - Path 13: Eligible for settlement - Take time to decide"));
    }

    if (variant.type === "FenceLodgementCompleted") {
      if (fenceType === FenceTypeIdMapping.Glass) {
        gtm(event("Fence non-shared - Path 15: Glass fence"));
      } else if (fenceLength && Number(fenceLength) > 30) {
        const fenceFlow = getFenceFlow(variant.isSharedFence);
        gtm(event(`Fence ${fenceFlow} - Path 14: Fence greater than 30m, assessor assigned`));
      }
    }

    if (variant.type === "ContentsRestorerMakeSafe") {
      gtm(event("Contents - Path 1: Complete lodgement - We'll send an email shortly"));
    }

    if (variant.type === "ContentsList") {
      gtm(event("Contents - Path 2: Fail lodgement - We'll contact you within one business day"));
    }

    if (variant.type === "ContentsPaymentBlockAllocationFailure") {
      gtm(
        event(
          "Contents - Path 3: Fail lodgement - We'll contact you within one business day - Payment block or allocation fail",
        ),
      );
    }

    if (variant.type === "LodgementCompleted" && variant.flow === "building") {
      gtm(event("Building - Path 1: Complete lodgement - We'll send an email shortly"));
    }

    if (variant.type === "LodgementFailed" && variant.flow === "building") {
      gtm(event("Building - Path 2: Fail lodgement - We'll contact you within one business day"));
    }

    if (variant.type === "LodgementCompleted" && Array.isArray(variant.flow)) {
      const flows = variant.flow.map(capitaliseFirstLetter).join(", ");

      gtm(event(`${flows} - Path 1: Complete lodgement - We'll send an email shortly`));
    }

    if (variant.type === "LodgementFailed" && Array.isArray(variant.flow)) {
      const flows = variant.flow.map(capitaliseFirstLetter).join(", ");

      gtm(event(`${flows} - Path 2: Fail lodgement - We'll contact you within one business day`));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container
      sx={{
        [theme.breakpoints.up("sm")]: {
          maxWidth: "600px",
          paddingLeft: 0,
          paddingRight: 0,
        },
        marginTop: "60px",
      }}
    >
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{
          textAlign: "center",
        }}
      >
        <ConfirmationHeader variant={variant} claimNumber={claimNumber} firstName={firstName} />
        {variant.type === "AcceptCashSettlement" && (
          <Grid item xs={12} sx={cardContainerStyles}>
            <YourSettlementCard variant={variant} />
          </Grid>
        )}

        <NextStepsCard variant={variant} />

        {(variant.type === "LodgementCompleted" || variant.type === "FenceLodgementCompleted") && <WhatsNextCard />}

        {(variant.flow === "building" || variant.flow.includes("building")) &&
          // @ts-expect-error TS IS TOO [REDACTED] TO INFER THE TYPES HERE
          variant.yourSafetyAndSecurity && <YourSafetyAndSecurity />}

        {variant.type === "ContentsRestorerMakeSafe" && (
          <YourContentsRestorerCard assignedContentsRestorer={variant.contentsRestorer} />
        )}

        {(variant.flow === "fence" || variant.flow.includes("fence")) &&
          variant.flow !== "contents" &&
          // @ts-expect-error TS IS TOO [REDACTED] TO INFER THE TYPES HERE
          variant.isTemporaryFenceRequired && (
            <Grid item xs={12} sx={cardContainerStyles}>
              <TemporaryFenceCard variant={variant} />
            </Grid>
          )}
        <Grid item xs={12} sx={{ ...cardContainerStyles, textAlign: "left" }}>
          <SeriousStormCard />
        </Grid>
        <Grid item xs={12} sx={cardContainerStyles}>
          <Link
            id="racHomePageLink"
            data-testid="racHomePageLink"
            variant="inherit"
            color="inherit"
            href={process.env.REACT_APP_RAC_HOME_PAGE}
            target="_blank"
            rel="noopener"
            underline="none"
            tabIndex={-1}
          >
            <Button
              id="racHomePageLinkButton"
              type="button"
              role="button"
              color="primary"
              variant="contained"
              fullWidth={true}
            >
              Go to RAC homepage
            </Button>
          </Link>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ConfirmationForm;
