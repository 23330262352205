import { Pages } from "@/App";
import { StateMachineContext } from "@/shared/stateMachine/hooks";
import { stateUrlLookup } from "@/shared/stateMachine/lookups";
import { gtm, virtualPageView } from "@racwa/analytics";
import { useNavigate } from "react-router-dom";

export const useOnExpiry = () => {
  const navigate = useNavigate();
  const actor = StateMachineContext.useActorRef();

  const navigateToExpirationPage = () => {
    gtm(
      virtualPageView({
        url: "/claims/home/general/408" satisfies Pages,
        title: `Session timeout - previous page: ${window.location.href}`,
      }),
    );
    actor.send({ type: "error.sessionTimeout" });
    navigate(stateUrlLookup[actor.getSnapshot().value], { replace: true });
  };

  return navigateToExpirationPage;
};

export default useOnExpiry;
