import { ClaimsHomeGeneralDamageApiException, FenceType } from "raci-claims-home-general-damage-clientproxy";
import { useIsLoggedInToMyRac } from "raci-react-library";
import { useEffect } from "react";
import { atom, useRecoilValue, useSetRecoilState } from "recoil";
import { useBffApiClient } from "../useApiClient";
import useErrorHandling from "../useErrorHandling";

const fenceTypesAtom = atom<FenceType[]>({
  key: "FenceTypeList",
  default: [],
});

export const useLoadReferenceData = () => {
  const apiClient = useBffApiClient();
  const setFenceTypes = useSetRecoilState(fenceTypesAtom);
  const isLoggedIn = useIsLoggedInToMyRac();
  const handleError = useErrorHandling();

  useEffect(() => {
    let isMounted = true;
    const loadFenceTypes = async () => {
      if (isLoggedIn) {
        let result: FenceType[];
        try {
          result = (await apiClient.getFenceTypes()).result;
          if (isMounted) {
            setFenceTypes(result);
          }
        } catch (ex) {
          const error = ex as ClaimsHomeGeneralDamageApiException;

          handleError({
            message: `Failed to load fence types`,
            shouldRedirect: true,
            customProperties: {
              request: "GET /reference-data/fence-types",
              status: error.status,
              error: error.message,
            },
          });
        }
      }
    };

    loadFenceTypes();

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export const useReferenceData = () => {
  const fenceTypes = useRecoilValue(fenceTypesAtom);

  return {
    fenceTypes,
  };
};

export default useReferenceData;
