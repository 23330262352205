import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { Button, Grid, Typography } from "@mui/material";
import { event, fieldTouched, gtm } from "@racwa/analytics";
import { colors } from "@racwa/styles";
import { IconWithText } from "raci-react-library";
import { StyledCard } from "./styled";

interface OtherOptionsProps {
  onCallFromRAC?: () => void;
  onTakeTimeToDecide?: () => void;
}

export const OtherOptions: React.FC<OtherOptionsProps> = ({ onCallFromRAC, onTakeTimeToDecide }) => {
  return (
    <>
      <Grid container item xs={12} spacing={1}>
        <IconWithText
          icon={faCircle}
          textLayer={
            <Typography sx={{ color: colors.white }} variant="h3">
              C
            </Typography>
          }
          text={<Typography variant="h3">Other options</Typography>}
          iconStyle={{ height: "28px", width: "28px" }}
        />
      </Grid>
      <StyledCard background="white">
        <Grid container direction="column" rowSpacing={4}>
          <Grid item xs>
            <Typography variant="body1" paragraph align="left" sx={{ fontWeight: 700 }}>
              Have someone phone you to discuss your options
            </Typography>

            <Typography variant="body1" paragraph align="left">
              We'll also send you an email.
            </Typography>
            <Button
              fullWidth
              color="primary"
              onClick={() => {
                gtm(fieldTouched("Choose your claim settlement option"));
                gtm(event("Choose your claim settlement option - Choose a call from RAC"));
                onCallFromRAC?.();
              }}
              data-testid="callFromRac"
            >
              Choose a call from RAC
            </Button>
          </Grid>

          <Grid item>
            <Typography variant="body1" paragraph align="left" sx={{ fontWeight: 700 }}>
              Take some more time to decide
            </Typography>

            <Typography variant="body1" paragraph align="left">
              We'll email you so you can consider your options.
            </Typography>
            <Button
              fullWidth
              color="primary"
              onClick={() => {
                gtm(fieldTouched("Choose your claim settlement option"));
                gtm(event("Choose your claim settlement option - Take time to decide"));
                onTakeTimeToDecide?.();
              }}
              data-testid="takeTimeToDecide"
            >
              Take time to decide
            </Button>
          </Grid>
        </Grid>
      </StyledCard>
    </>
  );
};
