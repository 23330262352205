import { FormControl, FormHelperText, FormLabel, Grid } from "@mui/material";
import { event, fieldTouched, gtm } from "@racwa/analytics";
import { RacwaCheckboxGroup, RacwaCheckboxListItem } from "@racwa/react-components";
import { MultiChoiceOption } from "raci-react-library";
import { Controller, useFormContext } from "react-hook-form";

const defaultId = "multi-choice-input";
const errorMessage = "Please select all options that apply";
const inputTitle = "Select all the problems your home has";

export interface ProblemInputProps {
  name: string;
  id?: string;
  options?: MultiChoiceOption[];
  deselectAllOption: string;
}

export const ProblemInput = ({ name, id, options, deselectAllOption }: ProblemInputProps) => {
  const { control } = useFormContext();
  const testId = id ? `${id}-${defaultId}` : defaultId;

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={[]}
      rules={{
        validate: (value) => {
          return value?.length > 0 ? undefined : errorMessage;
        },
      }}
      render={({ field: { onChange: onControlChange, value }, fieldState: { error } }) => {
        const onSelectionChanged = (option: MultiChoiceOption, checked: boolean) => {
          const typedValue = value as MultiChoiceOption[];

          if (checked && !typedValue.some((item) => item.key === option.key)) {
            gtm(event(`${inputTitle} - ${option.label}`));

            if (option.key === deselectAllOption) {
              onControlChange([option]);
            } else {
              onControlChange([...typedValue, option].filter((item) => item.key !== deselectAllOption));
            }
          } else if (!checked && typedValue.some((item) => item.key === option.key)) {
            onControlChange(typedValue.filter((item) => item.key !== option.key));
          }
        };

        return (
          <FormControl error={!!error} id={testId} data-testid={testId}>
            <FormLabel id={`${testId}-label`} data-testid={`${testId}-label`}>
              {inputTitle}.
            </FormLabel>
            <Grid container item xs={12} sm={12} direction="column">
              <RacwaCheckboxGroup border error={!!error?.message} onChange={() => gtm(fieldTouched(inputTitle))}>
                {options?.map((option) => (
                  <RacwaCheckboxListItem
                    key={option.key}
                    name={option.key}
                    id={`${testId}-${option.key}`}
                    data-testid={`${testId}-${option.key}`}
                    value={option.key}
                    checked={value.some((item: MultiChoiceOption) => item.key === option.key) ?? false}
                    label={option.label}
                    sublabel={option.sublabel}
                    onChange={(ev) => {
                      onSelectionChanged(option, ev.currentTarget.checked);
                      gtm(fieldTouched(inputTitle));
                    }}
                  />
                ))}
              </RacwaCheckboxGroup>
            </Grid>
            {error?.message && (
              <FormHelperText sx={{ fontSize: "0.75rem" }} id={`${testId}-error`} data-testid={`${testId}-error`}>
                {error.message}
              </FormHelperText>
            )}
          </FormControl>
        );
      }}
    />
  );
};

export default ProblemInput;
