import { flowCompletedAtom } from "@/shared/atoms";
import { useBffApiClient } from "@/shared/hooks/useApiClient";
import useErrorHandling from "@/shared/hooks/useErrorHandling";
import { StateMachineContext } from "@/shared/stateMachine/hooks";
import { stateUrlLookup } from "@/shared/stateMachine/lookups";
import { SettlementState } from "@/views/common/Settlement/types";
import {
  ClaimsHomeGeneralDamageApiException,
  SettlementRequest,
  SettlementRequestDecision,
} from "raci-claims-home-general-damage-clientproxy";
import { useSessionState, useSetBackdrop } from "raci-react-library";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { YourSettlementOptionsFormProps } from "../../types";

const useYourSettlementOptions = (): YourSettlementOptionsFormProps => {
  const actor = StateMachineContext.useActorRef();
  const navigate = useNavigate();
  const setBackdrop = useSetBackdrop();
  const handleError = useErrorHandling();
  const [yourSettlementOptionsState, setYourSettlementOptionsState] = useSessionState<SettlementState>({
    skipPageTrackingRecalculation: true,
  });
  const apiClient = useBffApiClient();
  const setFlowCompleted = useSetRecoilState(flowCompletedAtom);

  const onAcceptSettlement = async () => {
    try {
      setBackdrop(true);
      const decision = SettlementRequestDecision.Accepted;
      await apiClient.settlement({
        decision,
        wasSettlementOffered: true,
      } as SettlementRequest);
      setYourSettlementOptionsState({
        ...yourSettlementOptionsState,
        isCompleted: true,
        isSettlementAccepted: true,
        decision,
      });
      actor.send({
        type: "cashSettlement.next.acceptCash",
      });
      navigate(stateUrlLookup[actor.getSnapshot().value]);
      return true;
    } catch (exception) {
      const error = exception as ClaimsHomeGeneralDamageApiException;
      handleError({
        message: "Error on accept settlement",
        shouldRedirect: true,
        customProperties: {
          request: "POST /claims/settlement",
          status: error.status,
          error: error.message,
        },
      });
    } finally {
      setBackdrop(false);
    }

    return false;
  };

  const onRepairQuote = async () => {
    try {
      setBackdrop(true);
      const decision = SettlementRequestDecision.DeclinedToGetRepairQuote;
      await apiClient.settlement({
        decision,
        wasSettlementOffered: true,
      } as SettlementRequest);
      setYourSettlementOptionsState({
        ...yourSettlementOptionsState,
        isSettlementAccepted: false,
        isCompleted: true,
        decision,
      });
      setFlowCompleted(true);
      actor.send({
        type: "cashSettlement.next.getQuote",
      });
      navigate(stateUrlLookup[actor.getSnapshot().value]);
      return true;
    } catch (exception) {
      const error = exception as ClaimsHomeGeneralDamageApiException;
      handleError({
        message: "Error on repair quote",
        shouldRedirect: true,
        customProperties: {
          request: "POST /claims/settlement",
          status: error.status,
          error: error.message,
        },
      });
    } finally {
      setBackdrop(false);
    }

    return false;
  };

  return {
    onRepairQuote,
    onAcceptSettlement,
  };
};

export default useYourSettlementOptions;
