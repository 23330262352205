import { faClock, faList, faPeopleArrowsLeftRight } from "@fortawesome/free-solid-svg-icons";
import { Divider, Grid } from "@mui/material";
import { colors } from "@racwa/styles";
import { CardWithIcon } from "raci-react-library";
import { TitleTypography } from "../styled";

const cardInfo = [
  { icon: faPeopleArrowsLeftRight, title: "Firstly, we hope you're okay." },
  {
    icon: faList,
    title: "Fill in the form to the best of your knowledge.",
  },
  {
    icon: faClock,
    title: "This may take around 10 minutes.",
  },
];

export const BuildingAndContents: React.FC = () => {
  return (
    <>
      {cardInfo.map((card, index) => (
        <Grid item xs={12} key={card.title}>
          <CardWithIcon
            border={false}
            spacing={2}
            sx={{ paddingLeft: "0px", paddingRight: "0px", color: colors.dieselDeep }}
            icon={card.icon}
            title={<TitleTypography>{card.title}</TitleTypography>}
            iconStyle={{ marginTop: "5px" }}
          />
          {index !== cardInfo.length - 1 && <Divider />}
        </Grid>
      ))}
    </>
  );
};
