import { SelectedProblems } from "raci-claims-home-general-damage-clientproxy";
import { MultiChoiceOption } from "raci-react-library";

export const selectedProblemsName = "selectedProblems";

export const problemOptions = [
  {
    key: SelectedProblems.CantSecureMyHome,
    label: "Can't secure my home",
    sublabel: "e.g. because of smashed windows, broken locks, doors or windows won't close",
  },
  {
    key: SelectedProblems.DangerousOrLooseItems,
    label: "Dangerous or loose items that could cause injury",
    sublabel: "e.g. trampoline stuck on roof, gutters hanging off",
  },
  {
    key: SelectedProblems.NoPower,
    label: "No power to the property",
  },
  {
    key: SelectedProblems.NoWaterSupply,
    label: "No water supply to the property",
  },
  {
    key: SelectedProblems.NoAccessToKitchenOrBathroom,
    label: "No access to kitchen or bathroom",
    sublabel: "These rooms are badly damaged or can't be reached",
  },
  {
    key: SelectedProblems.NoneOfThese,
    label: "None of these",
  },
] satisfies MultiChoiceOption[];
