import { NotificationCard } from "raci-react-library";

const SubmitInfoCard = () => {
  return (
    <NotificationCard
      severity="info"
      message={{
        title: "Once you submit your claim, your repairer will assess your damaged items",
      }}
    />
  );
};

export default SubmitInfoCard;
