import Form from "./Form";
import useCashSettlementFactSheet from "./hooks/useCashSettlementFactsheet";

export const stepperName = "Fact sheet";

export const CashSettlementFactsheet = () => {
  const props = useCashSettlementFactSheet();
  return <Form {...props} />;
};

export default CashSettlementFactsheet;
