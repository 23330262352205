import { WaterDamageProblems } from "raci-claims-home-general-damage-clientproxy";
import { MultiChoiceOption } from "raci-react-library";

export const waterDamageName = "waterDamageProblems";

export const waterDamageOptions = [
  {
    key: WaterDamageProblems.NoWaterDamage,
    label: "No water damage",
  },
  {
    key: WaterDamageProblems.DampPatchesOrDripping,
    label: "Damp patches or dripping",
  },
  {
    key: WaterDamageProblems.SolidTimberFloorIsWet,
    label: "Solid timber flooring is wet",
    sublabel:
      "Includes glued-down hardwoods and parquetry (excludes laminate or floating floorboards, vinyl and tiles)",
  },
  {
    key: WaterDamageProblems.BadlySoakedCarpets,
    label: "Carpet is so badly soaked that you can't dry it",
  },
  {
    key: WaterDamageProblems.HouseIsFlooded,
    label: "House is flooded",
  },
  {
    key: WaterDamageProblems.SewageOrDrainWaterInTheHouse,
    label: "There's sewage or drain water in the house",
  },
  {
    key: WaterDamageProblems.WaterInTheElectrics,
    label: "Water in the electrics",
    sublabel: "Water leaking into electrical switches or light fittings, some sparking",
  },
  {
    key: WaterDamageProblems.OtherWaterDamage,
    label: "Other water damage",
  },
] satisfies MultiChoiceOption[];
