import { FontAwesomeIconProps } from "@fortawesome/react-fontawesome";
import { theme } from "@racwa/react-components";

export const iconStyles = {
  height: "101px",
  width: "100%",
  color: theme.palette.secondary.main,
} satisfies FontAwesomeIconProps["style"];

export const cardContainerStyles = {
  padding: "10px",
};
