import useErrorHandling from "@/shared/hooks/useErrorHandling";

import { useSessionState, useSetBackdrop } from "raci-react-library";
import { BuildingDamageFormProps, BuildingDamageFormValues, BuildingDamageState } from "../../types";

import { useBffApiClient } from "@/shared/hooks/useApiClient";
import { StateMachineContext } from "@/shared/stateMachine/hooks";
import { stateUrlLookup } from "@/shared/stateMachine/lookups";
import { SpecificDamageRoute } from "@/views/building/SpecificDamage";
import { SpecificDamageState } from "@/views/building/SpecificDamage/types";
import {
  ClaimsHomeGeneralDamageApiException,
  SelectedBuildingDamageLevels,
} from "raci-claims-home-general-damage-clientproxy";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

export const useBuildingDamage = (): BuildingDamageFormProps => {
  const actor = StateMachineContext.useActorRef();
  const navigate = useNavigate();
  const apiClient = useBffApiClient();
  const setBackdrop = useSetBackdrop();
  const handleError = useErrorHandling();
  const [buildingDamageState, setBuildingDamageState] = useSessionState<BuildingDamageState>({
    skipPageTrackingRecalculation: true,
  });
  const [specificDamageState, setSpecificDamageState] = useSessionState<SpecificDamageState>({
    specificKey: SpecificDamageRoute.path,
  });

  const form = useForm<BuildingDamageFormValues>({
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues: buildingDamageState,
  });

  const onSubmit: BuildingDamageFormProps["onSubmit"] = async (values?: BuildingDamageFormValues) => {
    try {
      setBackdrop(true);

      if (values?.damageLevels !== undefined) {
        await apiClient.buildingDamage({
          selectedBuildingDamageLevels: values?.damageLevels,
        });

        setBuildingDamageState({
          damageLevels: values?.damageLevels,
          isCompleted: true,
        });

        if (values?.damageLevels.length === 1 && values?.damageLevels[0] === SelectedBuildingDamageLevels.Level1) {
          actor.send({
            type: "buildingDamage.next.toSpecificDamage",
          });
        } else {
          setSpecificDamageState({ ...specificDamageState, isCompleted: false, damagedItems: [] });

          actor.send({
            type: "buildingDamage.next.toOther",
          });
        }
      }

      navigate(stateUrlLookup[actor.getSnapshot().value]);
    } catch (exception) {
      const error = exception as ClaimsHomeGeneralDamageApiException;
      handleError({
        message: "Error submitting building damage",
        shouldRedirect: true,
        customProperties: {
          request: "POST /step/building-damage",
          status: error.status,
          error: error.message,
        },
      });
    } finally {
      setBackdrop(false);
    }
  };

  return {
    form,
    onSubmit,
  };
};

export default useBuildingDamage;
