import useStepperPage from "@/shared/hooks/useStepperPage";
import { createPageTitle } from "@/shared/routing/TitleGuard";
import { RacwaStepperTemplate } from "@racwa/react-components";
import { RouteObject } from "react-router-dom";
import { ContactDetailsForm } from "./Form";
import { useContactDetails } from "./hooks/useContactDetails";

export const pageName = "Contact details";

export const ContactDetails = () => {
  const props = useContactDetails();
  const stepperProps = useStepperPage();

  return (
    <RacwaStepperTemplate {...stepperProps}>
      <ContactDetailsForm {...props} />
    </RacwaStepperTemplate>
  );
};

export const ContactDetailsRoute = {
  path: "contact-details",
  element: <ContactDetails />,
  loader: () => (document.title = createPageTitle(pageName)),
} as const satisfies RouteObject;

export default ContactDetails;
