import { faInfoCircle, faThumbsUp } from "@fortawesome/free-solid-svg-icons";
import { Grid, Typography } from "@mui/material";
import { FadeWiggleIcon } from "@racwa/react-components";
import { ClaimNumberDisplay, DefaultInsuranceNumberDisplayContent, currencyFormatter } from "raci-react-library";
import { ConfirmationVariant } from "../../hooks/useConfirmation/types";
import { cardContainerStyles, iconStyles } from "./styles";
import { ConfirmationHeaderProps } from "./types";

export const ConfirmationHeader: React.FC<ConfirmationHeaderProps> = ({ firstName, variant, claimNumber }) => {
  const getIcon = () => {
    if (variant.type === "ExcessHigherThanRepairCost") {
      return faInfoCircle;
    }

    if (variant.type === "AcceptCashSettlement" && variant.accountDetails === "error") {
      return faInfoCircle;
    }

    return faThumbsUp;
  };

  const getClaimNumberPrefix = () => {
    if (variant.type === "ExcessHigherThanRepairCost") {
      return "Your closed claim number is";
    }

    return DefaultInsuranceNumberDisplayContent.ClaimNumber.prefix;
  };

  return (
    <>
      <Grid item xs={12} sx={cardContainerStyles}>
        <FadeWiggleIcon icon={getIcon()} style={iconStyles} size="7x" />
      </Grid>
      <Grid item xs={12} sx={cardContainerStyles}>
        <Typography variant="h2" id="header" data-testid="header" data-hj-suppress>
          {titleText(variant, firstName ?? "")}
        </Typography>
      </Grid>
      <Grid item xs={12} sx={cardContainerStyles}>
        <Typography variant="body1" id="subHeader" data-testid="subHeader" data-hj-suppress>
          <b>{subtitleText(variant)}</b>
        </Typography>
      </Grid>
      <Grid item xs={12} sx={cardContainerStyles}>
        <ClaimNumberDisplay number={claimNumber ?? ""} prefix={getClaimNumberPrefix()} />
      </Grid>
    </>
  );
};

const titleText = (variant: ConfirmationVariant, firstName: string) => {
  switch (variant.type) {
    case "ExcessHigherThanRepairCost":
      return "Sorry, you can't claim";

    case "HasRepairQuote":
    case "HasRepairInvoice":
    case "NotEligibleCashSettlementDueToWoodOrBrick":
    case "SettlementDeclinedForRacRepairs":
    case "SettlementDeclinedForContactMe":
    case "SettlementDeclinedForTakeTimeToDecide":
    case "LodgementCompleted":
    case "LodgementFailed":
    case "ContentsList":
    case "ContentsRestorerMakeSafe":
    case "ContentsPaymentBlockAllocationFailure":
    case "FenceLodgementFailed":
    case "FenceLodgementCompleted":
    case "ClearSomethingUp":
      return `Claim received - thanks, ${firstName}`;

    case "CashSettlementDeclinedToGetRepairQuote":
    case "NotEligibleForCashSettlement":
      return `Claim received - over to you, ${firstName}!`;

    case "AcceptCashSettlement":
      switch (variant.accountDetails) {
        case "provided":
          return `Your claim has been settled, ${firstName}`;
        case "not-provided":
          return `Thanks for submitting your claim, ${firstName}`;
        case "error":
          return "Sorry, there was an error but we've received your claim";
      }
  }
};

const subtitleText = (variant: ConfirmationVariant) => {
  if (variant.type === "ExcessHigherThanRepairCost") {
    return (
      <>
        <p>
          Your excess of {currencyFormatter(variant.excess, { withDecimals: true, stripZeroCents: true })} is more than
          your estimated repairs of{" "}
          {currencyFormatter(variant.repairCost, { withDecimals: true, stripZeroCents: true })}.
        </p>
        <p>If you think there's been a mistake, we'll provide next steps in an email.</p>
        {variant.isTemporaryFenceRequired && (
          <p>But we'd like to make sure you're safe, so we'll still provide a temporary fence.</p>
        )}
      </>
    );
  }

  if (variant.type === "ContentsRestorerMakeSafe") {
    return "We'll send an email shortly.";
  }

  if (variant.type === "HasRepairInvoice") {
    return "You'll receive an email shortly.";
  }

  if (variant.type === "LodgementCompleted" || variant.type === "FenceLodgementCompleted") {
    return "We'll send an email shortly with the next steps.";
  }

  if (variant.type === "CashSettlementDeclinedToGetRepairQuote") {
    return "We'll send an email shortly with your next steps.";
  }

  if (variant.type === "NotEligibleCashSettlementDueToWoodOrBrick") {
    return variant.isSharedFence
      ? "We'll send you an email within one business day."
      : "We'll send you an email shortly.";
  }

  if (variant.type === "HasRepairQuote" || variant.type === "NotEligibleForCashSettlement") {
    return "We'll send you an email within one business day.";
  }

  if (
    variant.type === "ContentsPaymentBlockAllocationFailure" ||
    variant.type === "ClearSomethingUp" ||
    variant.type === "LodgementFailed" ||
    variant.type === "FenceLodgementFailed"
  ) {
    return "We'll contact you within one business day.";
  }

  if (
    variant.type === "SettlementDeclinedForRacRepairs" ||
    variant.type === "SettlementDeclinedForContactMe" ||
    variant.type === "SettlementDeclinedForTakeTimeToDecide"
  ) {
    return "We'll send you an email shortly.";
  }

  if (variant.type === "AcceptCashSettlement") {
    if (variant.accountDetails === "provided" || variant.accountDetails === "not-provided") {
      return "You'll receive a confirmation email shortly.";
    } else {
      return (
        <>
          <p>We still need your bank details.</p>
          <p>
            You'll receive a confirmation email shortly.
            <br /> This will have more information.
          </p>
        </>
      );
    }
  }

  if (variant.type === "ContentsList") {
    return undefined;
  }
};

export default ConfirmationHeader;
