import { useBffApiClient, useDocumentServiceApiClient } from "@/shared/hooks/useApiClient";
import useErrorHandling from "@/shared/hooks/useErrorHandling";
import { StateMachineContext } from "@/shared/stateMachine/hooks";
import { stateUrlLookup } from "@/shared/stateMachine/lookups";
import { SafetyAndSecurityRoute } from "@/views/common/SafetyAndSecurity";
import { StartYourClaimRoute } from "@/views/common/StartYourClaim";
import { StartYourClaimState } from "@/views/common/StartYourClaim/types";
import { YourDamagedFenceRoute } from "@/views/common/YourDamagedFence";
import { InvoiceQuoteRequest } from "raci-claims-home-general-damage-clientproxy";
import { useFileUpload, useGetSessionState, useSessionState, useSetBackdrop } from "raci-react-library";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { InvoiceQuoteFormProps, InvoiceQuoteFormValues, InvoiceQuoteState } from "../../types";

const createRequest = (state: InvoiceQuoteState): InvoiceQuoteRequest => {
  return {
    isRepairCompleted: state.isRepairCompleted ?? false,
    hasRepairQuote: state.hasRepairQuote ?? false,
  };
};

export const useInvoiceQuote = (): InvoiceQuoteFormProps => {
  const actor = StateMachineContext.useActorRef();
  const apiClient = useBffApiClient();
  const setBackdrop = useSetBackdrop();
  const handleError = useErrorHandling();
  const { excess } = useGetSessionState<StartYourClaimState>(StartYourClaimRoute.path);
  const [invoiceQuoteState, setInvoiceQuoteState] = useSessionState<InvoiceQuoteState>({
    skipPageTrackingRecalculation: true,
  });
  const navigate = useNavigate();
  const redirectToSystemUnavailable = () => handleError({ message: "File upload error", shouldRedirect: true });
  const documentServiceApiClient = useDocumentServiceApiClient();
  const { fetchConfiguration } = useFileUpload(documentServiceApiClient, undefined, redirectToSystemUnavailable);

  const form = useForm<InvoiceQuoteFormValues>({
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues: invoiceQuoteState,
  });

  const [yourFenceState, setYourFenceState] = useSessionState({
    specificKey: YourDamagedFenceRoute.path,
    skipPageTrackingRecalculation: true,
  });

  const [safetyAndSecurityState, setSafetyAndSecurityState] = useSessionState({
    specificKey: SafetyAndSecurityRoute.path,
    skipPageTrackingRecalculation: true,
  });

  const skipYourFencePage = () => setYourFenceState({ ...yourFenceState, isCompleted: true });

  const skipSafetyAndSecurityPage = () => setSafetyAndSecurityState({ ...safetyAndSecurityState, isCompleted: true });

  // need to be able to pass in state here as setDamagedDetailsState in submit is async
  const redirectToNextPage = ({ isRepairCompleted, hasRepairQuote }: InvoiceQuoteState) => {
    if (isRepairCompleted) {
      actor.send({
        type: "invoiceQuote.next.repairsCompleted",
      });
    } else if (hasRepairQuote) {
      actor.send({
        type: "invoiceQuote.next.haveRepairQuote",
      });
    } else {
      actor.send({
        type: "invoiceQuote.next.noHaveRepairQuote",
      });
    }
    navigate(stateUrlLookup[actor.getSnapshot().value]);
  };

  const onSubmit: InvoiceQuoteFormProps["onSubmit"] = async (newValues) => {
    try {
      setBackdrop(true);
      const request = createRequest(newValues);
      const response = await apiClient.invoiceQuote(request);
      if (response.result.token) {
        await fetchConfiguration(response.result.token);
      }

      const newState = { ...response.result, ...newValues, isCompleted: true };
      setInvoiceQuoteState(newState);

      if (newState.hasRepairQuote) {
        skipYourFencePage();
      }

      if (newState.isRepairCompleted) {
        skipYourFencePage();
        skipSafetyAndSecurityPage();
      }

      redirectToNextPage(newState);
    } catch (exception) {
      redirectToSystemUnavailable();
    } finally {
      setBackdrop(false);
    }
  };

  return {
    form,
    onSubmit,
    excess,
  };
};

export default useInvoiceQuote;
