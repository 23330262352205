import { NotificationCard } from "raci-react-library";

const SeriousStormCard = () => {
  return (
    <NotificationCard
      severity="info"
      message={{
        title: "When there's been a serious storm, the timeframes can be a bit longer than indicated",
      }}
    />
  );
};

export default SeriousStormCard;
