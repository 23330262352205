import { useBffApiClient } from "@/shared/hooks/useApiClient";
import useErrorHandling from "@/shared/hooks/useErrorHandling";
import { useReferenceData } from "@/shared/hooks/useReferenceData";
import { StateMachineContext, useFlowState } from "@/shared/stateMachine/hooks";
import { stateUrlLookup } from "@/shared/stateMachine/lookups";
import { InvoiceQuoteRoute } from "@/views/common/InvoiceQuote";
import { InvoiceQuoteState } from "@/views/common/InvoiceQuote/types";
import { SafetyAndSecurityRoute } from "@/views/common/SafetyAndSecurity";
import { SafetyAndSecurityState } from "@/views/common/SafetyAndSecurity/types";
import { UploadInvoiceQuoteRoute } from "@/views/common/UploadInvoiceQuote";
import { UploadInvoiceQuoteState } from "@/views/common/UploadInvoiceQuote/types";
import { event, gtm } from "@racwa/analytics";
import {
  ClaimsHomeGeneralDamageApiException,
  FenceDamagedSides,
  FenceType,
  RelatedDamages,
  YourDamagedFenceRequest,
} from "raci-claims-home-general-damage-clientproxy";
import { MultiChoiceOption, useGetSessionState, useSessionState, useSetBackdrop } from "raci-react-library";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { YourFenceFormProps, YourFenceFormValues, YourFenceState } from "../../types";

const createRequest = (values: YourFenceFormValues, fenceTypes: FenceType[]): YourDamagedFenceRequest => {
  const fenceLengthValue = values.fenceLength;
  const requiresMeasurement = fenceTypes?.find((f) => f.fenceTypeId === values.fenceType)?.requiresMeasurement;

  return {
    fenceDamagedSides: values.fenceDamagedSides.map((opt) => opt.label) as FenceDamagedSides[],
    fenceType: values.fenceType,
    fenceLength: !requiresMeasurement
      ? 0
      : !fenceLengthValue?.hasTroubleMeasuring && fenceLengthValue.fenceLength
        ? parseFloat(fenceLengthValue.fenceLength)
        : undefined,
    fencePaintedLength:
      !fenceLengthValue?.hasTroubleMeasuring && values.fencePaintedLength
        ? parseFloat(values.fencePaintedLength)
        : undefined,
  };
};

export const fenceSideOptions: MultiChoiceOption[] = Object.values(FenceDamagedSides).map((side) => ({
  key: side,
  label: side,
}));

export const useYourFence = (): YourFenceFormProps => {
  const actor = StateMachineContext.useActorRef();
  const apiClient = useBffApiClient();
  const setBackdrop = useSetBackdrop();
  const handleError = useErrorHandling();
  const { fenceTypes: allFenceTypes } = useReferenceData();
  const navigate = useNavigate();
  const [yourFenceState, setYourFenceState] = useSessionState<YourFenceState>({
    skipPageTrackingRecalculation: true,
  });
  const { isRepairCompleted, hasRepairQuote } = useGetSessionState<InvoiceQuoteState>(InvoiceQuoteRoute.path);
  const [safetyAndSecurityState, setSafetyAndSecurityState] = useSessionState<SafetyAndSecurityState>({
    specificKey: SafetyAndSecurityRoute.path,
    skipPageTrackingRecalculation: true,
  });
  const [uploadInvoiceQuoteState, setUploadInvoiceQuoteState] = useSessionState<UploadInvoiceQuoteState>({
    specificKey: UploadInvoiceQuoteRoute.path,
    skipPageTrackingRecalculation: true,
  });

  const { fenceFlow } = useFlowState();
  const selectedDamageType = fenceFlow === "shared" ? RelatedDamages.SharedFence : RelatedDamages.NonSharedFence;
  const fenceTypes = allFenceTypes.filter((f) => f?.relatedDamages?.includes(selectedDamageType));

  const form = useForm<YourFenceFormValues>({
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues: yourFenceState,
  });

  const skipUploadInvoiceQuoteState = () =>
    setUploadInvoiceQuoteState({ ...uploadInvoiceQuoteState, isCompleted: true });

  const onSubmit: YourFenceFormProps["onSubmit"] = async (newValues) => {
    try {
      setBackdrop(true);
      gtm(event(fenceTypes.find((f) => f.fenceTypeId === newValues.fenceType)?.description ?? ""));
      const request = createRequest(newValues, fenceTypes);
      await apiClient.yourDamagedFence(request);
      setYourFenceState({ ...newValues, isCompleted: true });
      if (hasRepairQuote === false && isRepairCompleted === false) {
        skipUploadInvoiceQuoteState();
      }

      if (isRepairCompleted) {
        setSafetyAndSecurityState({ ...safetyAndSecurityState, isCompleted: true });
      }

      actor.send({
        type: "yourDamagedFence.next",
      });

      navigate(stateUrlLookup[actor.getSnapshot().value]);
    } catch (exception) {
      const error = exception as ClaimsHomeGeneralDamageApiException;
      handleError({
        message: "Error submitting your fence details",
        shouldRedirect: true,
        customProperties: { request: "POST step/your-damaged-fence", status: error.status, error: error.message },
      });
    } finally {
      setBackdrop(false);
    }
  };

  return {
    form,
    onSubmit,
    fenceTypes,
    fenceSideOptions,
    defaultFenceDamagedSideOptions: yourFenceState.fenceDamagedSides,
  };
};

export default useYourFence;
