import { ContentsDamageRoute } from "@/views/contents/ContentsDamage";
import { ContentsDamageQuestions } from "@/views/contents/ContentsDamage/constants";
import { ContentsDamageFormValues, ContentsDamageState } from "@/views/contents/ContentsDamage/types";
import { useGetSessionState } from "raci-react-library";
import { FormSummary } from "../../types";

export const useGetContentsDamageSummary = (): FormSummary<ContentsDamageFormValues> => {
  const { cantDryCarpets, waterDamageToCarpets } = useGetSessionState<ContentsDamageState>(ContentsDamageRoute.path);
  const cantDryCarpetContent = () => {
    if (waterDamageToCarpets === false) {
      return undefined;
    }

    return cantDryCarpets;
  };

  return {
    waterDamageToCarpets: {
      label: ContentsDamageQuestions.waterDamageToCarpets.label,
      content: waterDamageToCarpets ? "Yes" : "No",
    },
    cantDryCarpets: {
      label: ContentsDamageQuestions.cantDryCarpets.label,
      content: cantDryCarpetContent(),
    },
  };
};
