import { useDocumentServiceApiClient } from "@/shared/hooks/useApiClient";

import { getInvoiceQuoteText } from "@/shared/utils/getInvoiceQuoteText";
import { InvoiceQuoteRoute } from "@/views/common/InvoiceQuote";
import { InvoiceQuoteState } from "@/views/common/InvoiceQuote/types";
import { UploadInvoiceQuote } from "@/views/common/UploadInvoiceQuote/constants";
import { UploadInvoiceQuoteSummary } from "@/views/common/UploadInvoiceQuote/types";
import { useFileUpload, useGetSessionState } from "raci-react-library";
import { FormSummary } from "../../types";

export const useGetUploadInvoiceQuoteSummary = (): FormSummary<UploadInvoiceQuoteSummary> => {
  const invoiceQuote = useGetSessionState<InvoiceQuoteState>(InvoiceQuoteRoute.path);
  const documentServiceApiClient = useDocumentServiceApiClient();
  const { previouslyUploadedFiles } = useFileUpload(documentServiceApiClient, undefined, undefined);
  const fileOrFiles = previouslyUploadedFiles.length > 1 ? "files" : "file";

  return {
    filesUploaded: {
      label: `${UploadInvoiceQuote.uploadedFiles.label} ${getInvoiceQuoteText(invoiceQuote)}`,
      content:
        previouslyUploadedFiles.length === 0
          ? "No files uploaded"
          : `${previouslyUploadedFiles.length} ${fileOrFiles} uploaded`,
    },
  };
};
