import { useBffApiClient } from "@/shared/hooks/useApiClient";
import useErrorHandling from "@/shared/hooks/useErrorHandling";
import { StateMachineContext } from "@/shared/stateMachine/hooks";
import { stateUrlLookup } from "@/shared/stateMachine/lookups";
import {
  ClaimsHomeGeneralDamageApiException,
  ContactDetails,
  ContactDetailsPhoneNumberType,
} from "raci-claims-home-general-damage-clientproxy";
import { HTTP_STATUS_CODE_CONTACT_SYNC_FAILURE, useSessionState, useSetBackdrop } from "raci-react-library";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { ContactDetailsFormProps, ContactDetailsFormValues, ContactDetailsState } from "../../types";

export const useContactDetails = (): ContactDetailsFormProps => {
  const actor = StateMachineContext.useActorRef();
  const navigate = useNavigate();
  const handleError = useErrorHandling();
  const setBackdrop = useSetBackdrop();
  const [, setContactDetailsState] = useSessionState<ContactDetailsState>({
    skipPageTrackingRecalculation: true,
  });
  const apiClient = useBffApiClient();
  const [contactDetails, setContactDetails] = useState<ContactDetails>();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getContactDetails = async () => {
      try {
        setBackdrop(true);
        const response = (await apiClient.getContactDetails()).result;
        setContactDetails(response);

        if (response.phoneNumberType === ContactDetailsPhoneNumberType.NoPhoneNumber) {
          handleError({ message: "Contact does not have a phone number", shouldRedirect: true });
        }
      } catch (exception) {
        const error = exception as ClaimsHomeGeneralDamageApiException;
        const errorHandlingProps = {
          message: "Error fetching contact details",
          shouldRedirect: true,
          customProperties: {
            request: "GET /contact-details",
            status: error.status,
            error: error.message,
          },
        };

        handleError({
          ...errorHandlingProps,
          isMemberContactError: error.status === HTTP_STATUS_CODE_CONTACT_SYNC_FAILURE,
        });
      } finally {
        setIsLoading(false);
        setBackdrop(false);
      }
    };

    getContactDetails();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const form = useForm<ContactDetailsFormValues>({
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues: {},
  });

  const onSubmit = async (state?: ContactDetailsFormValues) => {
    setContactDetailsState({ ...state, isCompleted: true });
    actor.send({
      type: "contactDetails.next",
    });
    navigate(stateUrlLookup[actor.getSnapshot().value]);
  };

  return {
    form,
    isLoading,
    firstName: contactDetails?.firstName,
    phoneType: `${contactDetails?.phoneNumberType}` as "Mobile" | "Home" | "Work",
    maskedPhoneNumber: contactDetails?.phoneNumber,
    maskedEmail: contactDetails?.email,
    onSubmit,
  };
};

export default useContactDetails;
