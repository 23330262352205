import { StateMachineContext } from "@/shared/stateMachine/hooks";
import { stateUrlLookup } from "@/shared/stateMachine/lookups";
import { StartYourClaimRoute } from "@/views/common/StartYourClaim";
import { StartYourClaimState } from "@/views/common/StartYourClaim/types";
import { useGetSessionState, useSessionState } from "raci-react-library";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { YourExcessFormProps, YourExcessFormValues, YourExcessState } from "../../types";

export const useYourExcess = (): YourExcessFormProps => {
  const actor = StateMachineContext.useActorRef();
  const navigate = useNavigate();
  const { excess } = useGetSessionState<StartYourClaimState>(StartYourClaimRoute.path);
  const [, setYourExcessState] = useSessionState<YourExcessState>({
    skipPageTrackingRecalculation: true,
  });

  const form = useForm<YourExcessFormValues>({
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues: {},
  });

  const onSubmit = async (state?: YourExcessFormValues) => {
    setYourExcessState({ ...state, isCompleted: true });
    actor.send({
      type: "yourExcess.next",
    });
    navigate(stateUrlLookup[actor.getSnapshot().value]);
  };

  return {
    form,
    onSubmit,
    excess,
  };
};

export default useYourExcess;
