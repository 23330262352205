import { flowCompletedAtom } from "@/shared/atoms";
import { useBffApiClient } from "@/shared/hooks/useApiClient";
import useErrorHandling from "@/shared/hooks/useErrorHandling";
import { StateMachineContext } from "@/shared/stateMachine/hooks";
import { stateUrlLookup } from "@/shared/stateMachine/lookups";
import { SettlementState } from "@/views/common/Settlement/types";
import {
  ClaimsHomeGeneralDamageApiException,
  SettlementRequest,
  SettlementRequestDecision,
} from "raci-claims-home-general-damage-clientproxy";
import { useSessionState, useSetBackdrop } from "raci-react-library";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { CashSettlementFactSheetFormProps } from "../../types";

export type NonSharedSettlementDecision = Exclude<
  SettlementRequestDecision,
  SettlementRequestDecision.DeclinedToGetRepairQuote
>;

const useCashSettlementFactSheet = (): CashSettlementFactSheetFormProps => {
  const actor = StateMachineContext.useActorRef();
  const navigate = useNavigate();
  const setBackdrop = useSetBackdrop();
  const handleError = useErrorHandling();
  const [settlementState, setSettlementState] = useSessionState<SettlementState>({
    skipPageTrackingRecalculation: true,
  });
  const apiClient = useBffApiClient();
  const setFlowCompleted = useSetRecoilState(flowCompletedAtom);
  const [disabled, setDisabled] = useState(false);

  const onAcceptSettlement = async () => {
    try {
      setBackdrop(true);
      setDisabled(true);
      const decision = SettlementRequestDecision.Accepted;
      await apiClient.settlement({
        decision,
        wasSettlementOffered: true,
      } as SettlementRequest);

      setSettlementState({ ...settlementState, decision, isCompleted: true, isSettlementAccepted: true });
      actor.send({
        type: "cashSettlementFactSheet.next.acceptCash",
      });
      navigate(stateUrlLookup[actor.getSnapshot().value]);
    } catch (exception) {
      const error = exception as ClaimsHomeGeneralDamageApiException;
      handleError({
        message: "Error on accept settlement",
        shouldRedirect: true,
        customProperties: {
          request: "POST /claims/settlement",
          status: error.status,
          error: error.message,
        },
      });
    } finally {
      setBackdrop(false);
      setDisabled(false);
    }
  };

  const onOtherOptions = async (decision: NonSharedSettlementDecision) => {
    try {
      setBackdrop(true);
      setDisabled(true);
      const response = await apiClient.settlement({
        decision,
        wasSettlementOffered: true,
      } as SettlementRequest);

      setSettlementState({
        ...settlementState,
        decision,
        isSettlementAccepted: false,
        assignedServiceProviders: response.result.assignedServiceProviders,
        isCompleted: true,
      });
      setFlowCompleted(true);
      actor.send({
        type: "cashSettlementFactSheet.next.confirmation",
      });
      navigate(stateUrlLookup[actor.getSnapshot().value]);
    } catch (exception) {
      const error = exception as ClaimsHomeGeneralDamageApiException;
      handleError({
        message: `Error on other options - ${decision}`,
        shouldRedirect: true,
        customProperties: {
          request: "POST /claims/settlement",
          status: error.status,
          error: error.message,
        },
      });
    } finally {
      setBackdrop(false);
      setDisabled(false);
    }
  };

  return {
    onOtherOptions,
    onAcceptSettlement,
    disabled,
  };
};

export default useCashSettlementFactSheet;
