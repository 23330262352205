import { InvoiceQuoteRoute } from "@/views/common/InvoiceQuote";
import { InvoiceQuoteQuestions } from "@/views/common/InvoiceQuote/constants";
import { InvoiceQuoteFormValues, InvoiceQuoteState } from "@/views/common/InvoiceQuote/types";
import { useGetSessionState } from "raci-react-library";
import { FormSummary } from "../../types";

export const useGetInvoiceQuoteSummary = (): FormSummary<InvoiceQuoteFormValues> => {
  const { hasRepairQuote, isRepairCompleted } = useGetSessionState<InvoiceQuoteState>(InvoiceQuoteRoute.path);
  return {
    hasRepairQuote: {
      label: InvoiceQuoteQuestions.hasRepairQuote.label,
      content: isRepairCompleted ? undefined : hasRepairQuote ? "Yes" : "No",
    },
    isRepairCompleted: {
      label: InvoiceQuoteQuestions.isRepairCompleted.label,
      content: isRepairCompleted ? "Yes" : "No",
    },
  };
};
