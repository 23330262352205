import useStepperPage from "@/shared/hooks/useStepperPage";
import { createPageTitle } from "@/shared/routing/TitleGuard";
import { RacwaStepperTemplate } from "@racwa/react-components";
import { RouteObject } from "react-router-dom";
import { ReviewYourClaimForm } from "./Form";
import useReviewYourClaim from "./hooks/useReviewYourClaim";

export const pageName = "Review your claim";
export const stepperName = "Review your claim";

export const ReviewYourClaim = () => {
  const props = useReviewYourClaim();
  const stepperProps = useStepperPage();

  return (
    <RacwaStepperTemplate {...stepperProps}>
      <ReviewYourClaimForm {...props} />
    </RacwaStepperTemplate>
  );
};

export const ReviewYourClaimRoute = {
  path: "review-your-claim",
  element: <ReviewYourClaim />,
  loader: () => (document.title = createPageTitle(pageName)),
} as const satisfies RouteObject;

export default ReviewYourClaim;
