import { CallToActionButton } from "@/shared/components/CallToActionButton";
import ExcessCard from "@/shared/components/ExcessCard";
import PageTitle from "@/shared/components/PageTitle";
import { useFlowState } from "@/shared/stateMachine/hooks";
import { Grid } from "@mui/material";
import { NotificationCard, YesNoButtonGroup } from "raci-react-library";
import { FormProvider, useWatch } from "react-hook-form";
import { InvoiceQuoteQuestions, hasRepairQuoteName, isRepairCompletedName } from "./constants";
import { InvoiceQuoteFormProps } from "./types";

const { isRepairCompleted } = InvoiceQuoteQuestions;
const { hasRepairQuote } = InvoiceQuoteQuestions;

export const InvoiceQuoteForm = ({ form, onSubmit, excess }: InvoiceQuoteFormProps) => {
  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
  } = form;

  const { isFenceOnly } = useFlowState();

  const isRepairCompletedWatch = useWatch({ control, name: isRepairCompletedName });
  const hasRepairQuoteNameWatch = useWatch({ control, name: hasRepairQuoteName });
  const title = isFenceOnly ? "Before we look at your damage" : "Now, let's look at your fence";

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)} action="#">
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <PageTitle id="header" title={title} showClaimNumber />
          </Grid>
          {isFenceOnly && (
            <Grid item xs={12}>
              <ExcessCard excess={excess} />
            </Grid>
          )}
          <Grid item xs={12}>
            <YesNoButtonGroup
              name={isRepairCompleted.name}
              label={isRepairCompleted.label}
              data-testid={isRepairCompleted.id}
            />
          </Grid>
          {isRepairCompletedWatch != null && !isRepairCompletedWatch && (
            <Grid item xs={12}>
              <YesNoButtonGroup
                name={hasRepairQuote.name}
                label={hasRepairQuote.label}
                data-testid={hasRepairQuote.id}
              />
            </Grid>
          )}
          {(isRepairCompletedWatch || hasRepairQuoteNameWatch) && (
            <Grid item xs={12} sx={{ paddingTop: "24px" }}>
              <NotificationCard
                severity="info"
                message={{
                  title: `We'll ask you to upload your ${isRepairCompletedWatch ? "invoice" : "quote"} shortly`,
                }}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <CallToActionButton disabled={isSubmitting}>Next</CallToActionButton>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default InvoiceQuoteForm;
