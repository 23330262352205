import { useBffApiClient } from "@/shared/hooks/useApiClient";
import useErrorHandling from "@/shared/hooks/useErrorHandling";
import { StateMachineContext } from "@/shared/stateMachine/hooks";
import { stateUrlLookup } from "@/shared/stateMachine/lookups";
import { ClaimsHomeGeneralDamageApiException } from "raci-claims-home-general-damage-clientproxy";
import { useSessionState, useSetBackdrop } from "raci-react-library";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { FixturesAndFittingsFormProps, FixturesAndFittingsFormValues, FixturesAndFittingsState } from "../../types";

export const useFixturesAndFittings = (): FixturesAndFittingsFormProps => {
  const actor = StateMachineContext.useActorRef();
  const navigate = useNavigate();
  const apiClient = useBffApiClient();
  const setBackdrop = useSetBackdrop();
  const handleError = useErrorHandling();
  const [fixturesAndFittingsState, setFixturesAndFittingsState] = useSessionState<FixturesAndFittingsState>({
    skipPageTrackingRecalculation: true,
  });

  const form = useForm<FixturesAndFittingsFormValues>({
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues: fixturesAndFittingsState,
  });

  const onSubmit: FixturesAndFittingsFormProps["onSubmit"] = async (values?: FixturesAndFittingsFormValues) => {
    try {
      setBackdrop(true);
      await apiClient.fixturesAndFittings({
        damageToFixturesOrFittings: values?.damageToFixturesOrFittings,
      });

      setFixturesAndFittingsState({
        ...fixturesAndFittingsState,
        damageToFixturesOrFittings: values?.damageToFixturesOrFittings,
        isCompleted: true,
      });

      actor.send({
        type: "fixturesAndFittings.next",
      });

      navigate(stateUrlLookup[actor.getSnapshot().value]);
    } catch (exception) {
      const error = exception as ClaimsHomeGeneralDamageApiException;
      handleError({
        message: "Error submitting building damage",
        shouldRedirect: true,
        customProperties: {
          request: "POST /step/building-damage",
          status: error.status,
          error: error.message,
        },
      });
    } finally {
      setBackdrop(false);
    }
  };

  return {
    form,
    onSubmit,
  };
};

export default useFixturesAndFittings;
