import { CallToActionButton } from "@/shared/components/CallToActionButton";
import PageTitle from "@/shared/components/PageTitle";
import { StateMachineContext, useFlowState } from "@/shared/stateMachine/hooks";
import { faExclamation, faHouseChimney, faSwimmingPool } from "@fortawesome/free-solid-svg-icons";
import { FormLabel, Grid, Typography } from "@mui/material";
import { event, fieldTouched, gtm } from "@racwa/analytics";
import { CardMessage, IconWithText, NotificationCard, YesNoButtonGroup } from "raci-react-library";
import { useState } from "react";
import { FormProvider, useWatch } from "react-hook-form";
import { SafetyAndSecurityQuestions, isSafetyIssueName } from "./constants";
import { SafetyAndSecurityFormProps, SafetyAndSecurityFormValues } from "./types";

const { isSafetyIssue } = SafetyAndSecurityQuestions;

export const SafetyAndSecurityForm = ({ form, onSubmit }: SafetyAndSecurityFormProps) => {
  const {
    handleSubmit,
    trigger,
    control,
    formState: { isSubmitting },
  } = form;
  const [, setIsSubmitError] = useState(false);
  const onSubmitWrapper = async (newValues: SafetyAndSecurityFormValues) => {
    onSubmit && setIsSubmitError(!(await onSubmit(newValues)));
  };

  const fenceFlow = StateMachineContext.useSelector((state) => state.context.fenceFlow);
  const { isBuildingContentsAndFence } = useFlowState();

  const isSafetyIssueWatch = useWatch({ control, name: isSafetyIssueName });
  const showTemporaryFenceDisclaimer = isSafetyIssueWatch === true;

  const showSharedFenceDisclaimer =
    isSafetyIssueWatch === true && fenceFlow === "shared" && !isBuildingContentsAndFence;

  const safetyIssueDisclaimerContentOne: CardMessage = {
    title: "Good news - your temporary fence",
    paragraphs: [
      "Generally, one of our allocated builders will call you within one to two business days to arrange a temporary fence. When there's a serious storm, this may take a bit longer.",
      "In most cases, we'll cover a temporary fence for up to 12 weeks.",
    ],
  };

  const safetyIssueDisclaimerSharedFence: CardMessage = {
    title: "You'll still need to organise the permanent fence repairs with your neighbour.",
  };

  return (
    <FormProvider {...form}>
      <form action="#" onSubmit={handleSubmit(onSubmitWrapper)} data-testid="safetyAndSecurityForm" noValidate>
        <Grid container spacing={1} direction="column">
          <Grid item xs={12}>
            <PageTitle id="header" title="Your property's security" showClaimNumber />
          </Grid>
          <Grid item xs={12} sx={{ marginTop: "24px", marginBottom: "24px" }}>
            <Typography variant="body1">
              <b>We need to check if there's a serious safety issue due to this fence damage.</b>
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <FormLabel>{isSafetyIssue.label}:</FormLabel>
          </Grid>

          <IconWithText
            iconStyle={{ marginTop: "2px" }}
            invert
            icon={faSwimmingPool}
            text="I have a pool, spa or pond that's exposed."
          />
          <IconWithText
            iconStyle={{ marginTop: "2px" }}
            invert
            icon={faHouseChimney}
            text="My property is exposed or accessible to the public (it backs onto an open street, lane way or council land)."
          />
          <IconWithText
            iconStyle={{ marginTop: "2px" }}
            invert
            icon={faExclamation}
            text="I have another safety or security concern."
          />

          <Grid container item spacing={3}>
            <Grid item xs={12}>
              <YesNoButtonGroup
                name={isSafetyIssue.name}
                id={isSafetyIssue.id}
                data-testid={isSafetyIssue.id}
                onChange={(_, value) => {
                  const message = (value as boolean) ? "Yes" : "No";
                  trigger();
                  gtm(fieldTouched(`${isSafetyIssue.label}:`));
                  gtm(event(`${isSafetyIssue.label} - ${message}`));
                }}
              />
            </Grid>
            {showTemporaryFenceDisclaimer && (
              <Grid item xs={12}>
                <NotificationCard severity="info" message={safetyIssueDisclaimerContentOne} />
              </Grid>
            )}
            {showSharedFenceDisclaimer && (
              <Grid item xs={12}>
                <NotificationCard severity="info" message={safetyIssueDisclaimerSharedFence} />
              </Grid>
            )}
          </Grid>
          <Grid item xs={12}>
            <CallToActionButton disabled={isSubmitting}>Next</CallToActionButton>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default SafetyAndSecurityForm;
