import { CallToActionButton } from "@/shared/components/CallToActionButton";
import PageTitle from "@/shared/components/PageTitle";
import { Grid } from "@mui/material";
import { SelectedProblems } from "raci-claims-home-general-damage-clientproxy";
import { MultiChoiceOption } from "raci-react-library";
import { FormProvider, useWatch } from "react-hook-form";
import { ProblemInput } from "../../../shared/components/ProblemInput";
import YourSafetyNotificationCard from "../../../shared/components/YourSafetyNotificationCard";
import { problemOptions, selectedProblemsName } from "./constants";
import { SafetyChecksFormProps } from "./types";

const SafetyChecksForm = ({ form, onSubmit }: SafetyChecksFormProps) => {
  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
  } = form;

  const watchSelectedProblems = useWatch({ control, name: selectedProblemsName });

  const showYourSafetyCard =
    watchSelectedProblems?.length > 0 &&
    !watchSelectedProblems.some((problem: MultiChoiceOption) => problem.key === SelectedProblems.NoneOfThese);

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)} action="#">
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <PageTitle id="header" title="We need to do some more safety checks" showClaimNumber />
          </Grid>
          <Grid item>
            <ProblemInput
              name={selectedProblemsName}
              id="problems"
              options={problemOptions}
              deselectAllOption={SelectedProblems.NoneOfThese}
            />
            <Grid sx={{ paddingTop: "24px" }}>{showYourSafetyCard && <YourSafetyNotificationCard />}</Grid>
          </Grid>
          <Grid item xs={12}>
            <CallToActionButton disabled={isSubmitting}>Next</CallToActionButton>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default SafetyChecksForm;
