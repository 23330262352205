import { faCircleQuestion, faClock, faHome, faList, faPeopleArrowsLeftRight } from "@fortawesome/free-solid-svg-icons";
import { Divider, Grid } from "@mui/material";
import { event, gtm } from "@racwa/analytics";
import { colors } from "@racwa/styles";
import { AccordionCard, CardWithIcon, EMPTY_URL, ProductDisclosureStatementLink } from "raci-react-library";
import { AccordionContentTypography, TitleTypography } from "../styled";

const cardInfo = [
  { icon: faPeopleArrowsLeftRight, title: "Firstly, we hope you're okay." },
  {
    icon: faList,
    title: "Fill in the form to the best of your knowledge.",
  },
  {
    icon: faClock,
    title: "This may take up to 10 minutes.",
  },
  { icon: faHome, title: "This form is for both owner-occupied homes and investment properties." },
];

export const buildingAccordionCardInfo = [
  {
    icon: faCircleQuestion,
    title: "What's covered by owner-occupied policies?",
    content: <>Things like the building, flooring (except carpets), built-in furniture and in-ground pools.</>,
    onClick: () => gtm(event("What's covered by owner-occupied policies?")),
  },
  {
    icon: faCircleQuestion,
    title: "What's covered by investment property building policies?",
    content: (
      <>
        <p>Things like the building, flooring, built-in furniture and in-ground pools.</p>
        <p>
          Also stoves, built-in aircon, light fittings, window furnishings, carpets and floor rugs. Limits apply. See
          the{" "}
          <ProductDisclosureStatementLink
            showAbbreviation={false}
            showInline={true}
            url={process.env.REACT_APP_RAC_PDS_PED_URL || EMPTY_URL}
          />{" "}
          for more information.
        </p>
      </>
    ),
    onClick: () => gtm(event("What's covered by investment property building policies?")),
  },
];

export const Building: React.FC = () => {
  return (
    <>
      {cardInfo.map((card, index) => (
        <Grid item xs={12} key={card.title}>
          <CardWithIcon
            border={false}
            spacing={2}
            sx={{ paddingLeft: "0px", paddingRight: "0px", color: colors.dieselDeep }}
            icon={card.icon}
            title={<TitleTypography>{card.title}</TitleTypography>}
            iconStyle={{ marginTop: "5px" }}
          />
          <Divider />
        </Grid>
      ))}
      {buildingAccordionCardInfo.map((card, index) => (
        <Grid item xs={12} key={card.title}>
          <AccordionCard
            border={false}
            spacing={2}
            sx={{ paddingLeft: "0px", paddingRight: "0px", color: colors.dieselDeep }}
            icon={card.icon}
            title={<TitleTypography>{card.title}</TitleTypography>}
            iconStyle={{ marginTop: "5px" }}
            onClick={card.onClick}
          >
            <AccordionContentTypography>{card.content}</AccordionContentTypography>
          </AccordionCard>
          {index !== buildingAccordionCardInfo.length - 1 && <Divider />}
        </Grid>
      ))}
    </>
  );
};
