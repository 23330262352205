import Form from "./Form";
import useYourSettlementOptions from "./hooks/useYourSettlementOptions";

export const pageName = "Your settlement options";

export const YourSettlementOptions = () => {
  const props = useYourSettlementOptions();

  return <Form {...props} />;
};

export default YourSettlementOptions;
