import { event, gtm } from "@racwa/analytics";
import {
  DialogBoxWithButton,
  KEYBOARD_CODE_ENTER,
  KEYBOARD_CODE_ENTER_NUMPAD,
  KEYBOARD_CODE_SPACE,
} from "raci-react-library";
import { useState } from "react";
import { StyledLink } from "../../../views/sharedFence/YourSettlementOptions/styled";

export interface UpgradeMyFenceDialogProps {
  children: string;
}

const UpgradeMyFenceDialog = ({ children }: UpgradeMyFenceDialogProps) => {
  const [open, setOpen] = useState(false);
  const title = "What if I want to upgrade the fence?";

  const handleOnClick = () => {
    gtm(event(title));
    setOpen(true);
  };

  const handleKeyboard = (event: any) => {
    if (
      event.code === KEYBOARD_CODE_ENTER ||
      event.code === KEYBOARD_CODE_SPACE ||
      event.code === KEYBOARD_CODE_ENTER_NUMPAD
    ) {
      handleOnClick();
    }
  };

  return (
    <>
      <StyledLink onClick={handleOnClick} tabIndex={0} onKeyDown={handleKeyboard}>
        {title}
      </StyledLink>
      <DialogBoxWithButton
        showDialog={open}
        setShowDialog={setOpen}
        title={title}
        buttonText="Close"
        content={children}
      />
    </>
  );
};

export default UpgradeMyFenceDialog;
