import { FormQuestions } from "@/shared/types";
import { YourSettlementOptionsFormValues } from "@/views/nonSharedFence/YourSettlementOptions/types";

export const settlementOptionName = "settlementOption";

export const YourSettlementOptionsQuestions: FormQuestions<YourSettlementOptionsFormValues> = {
  settlementOption: {
    id: settlementOptionName,
    label: "Settlement Option",
    name: settlementOptionName,
  },
};
