import { SettlementRoute } from "@/views/common/Settlement";
import { SettlementState } from "@/views/common/Settlement/types";
import { Grid, Typography } from "@mui/material";
import { currencyFormatter, useGetSessionState } from "raci-react-library";

const SettlementSubTitle = () => {
  const { totalRepairCost } = useGetSessionState<SettlementState>(SettlementRoute.path);

  const formattedTotal = currencyFormatter(totalRepairCost || 0, { withDecimals: true });
  const message = !totalRepairCost
    ? "Please confirm your bank details to receive your settlement."
    : `Please confirm your bank details to receive your settlement amount of ${formattedTotal}.`;

  return (
    <Grid item xs={12}>
      <Typography
        variant="body1"
        id="settlementSubTitle"
        data-testid="settlementSubTitle"
        sx={{ fontWeight: "bolder", fontFamily: "'Stag Sans Web', Helvetica, Arial, sans-serif", marginTop: "1em" }}
      >
        {message}
      </Typography>
    </Grid>
  );
};

export default SettlementSubTitle;
