import useStandardPage from "@/shared/hooks/useStandardPage";
import { createPageTitle } from "@/shared/routing/TitleGuard";
import { RacwaStandardPageTemplate } from "@racwa/react-components";
import { PolicyNumberPrefix, YouCantMakeAClaimContent } from "raci-react-library";
import { RouteObject } from "react-router-dom";

export const heading = "Uh oh!";
export const pageName = "You can't make a claim";

export const YouCantMakeAClaim: React.FC = () => {
  const standardPageProps = useStandardPage();

  return (
    <RacwaStandardPageTemplate {...standardPageProps} heading={heading}>
      <YouCantMakeAClaimContent policyNumberPrefix={PolicyNumberPrefix.Home} subtitle={undefined} />
    </RacwaStandardPageTemplate>
  );
};

export const YouCantMakeAClaimRoute = {
  path: "dont-have-a-policy",
  element: <YouCantMakeAClaim />,
  loader: () => (document.title = createPageTitle(pageName)),
} as const satisfies RouteObject;

export default YouCantMakeAClaim;
