import { StartYourClaimRoute } from "@/views/common/StartYourClaim";
import { StartYourClaimState } from "@/views/common/StartYourClaim/types";
import { RacwaStepperPageTitle, RacwaStepperPageTitleProps } from "@racwa/react-components";
import { ClaimNumberDisplay, useGetSessionState } from "raci-react-library";

interface PageTitleProps extends Pick<RacwaStepperPageTitleProps, "title" | "id"> {
  showClaimNumber?: boolean;
}

const PageTitle = ({ title, id, showClaimNumber }: PageTitleProps) => {
  const { claimNumber } = useGetSessionState<StartYourClaimState>(StartYourClaimRoute.path);

  return (
    <RacwaStepperPageTitle
      id={id}
      data-testid={id}
      title={title}
      subtitle={showClaimNumber && claimNumber ? <ClaimNumberDisplay number={claimNumber} /> : undefined}
    />
  );
};

export default PageTitle;
