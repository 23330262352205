import {
  ADB2C_CLIENT_ID,
  BFF_API_BASE_URL,
  DOCUMENT_SERVICE_API_BASE_URL,
  LOOKUP_SERVICE_URL,
  MFA_OTP_SERVICE_API_BASE_URL,
} from "@/shared/constants";
import { ClaimsHomeGeneralDamageApiClient } from "raci-claims-home-general-damage-clientproxy";
import { DocumentServiceApiClient } from "raci-document-service-clientproxy";
import { LookupServiceApiClient } from "raci-lookup-service-clientproxy";
import { MfaOtpApiClient } from "raci-otp-service-clientproxy";
import { useFileUploadSessionKey, useSessionIdStorageKey } from "raci-react-library";
import { NpeEnvironment, useSsoApiClient } from "raci-react-library-npe";

const ENVIRONMENT = (process.env.REACT_APP_ENVIRONMENT ?? "") as NpeEnvironment;
const NPE_CLIENT_ID = process.env.REACT_APP_NPE_OAUTH_CLIENT_ID ?? "";
const NPE_AUTHORITY = process.env.REACT_APP_NPE_OAUTH_AUTHORITY ?? "";

export type ClientOptions = { fetch: (url: RequestInfo, init?: RequestInit) => Promise<Response> };

export interface UseApiClientProps<T> {
  Client: { new (baseUrl: string, http: ClientOptions): T };
  apiBaseUrl: string;
}

const useApi = <T>({ Client, apiBaseUrl }: UseApiClientProps<T>) => {
  const sessionIdStorageKey = useSessionIdStorageKey();
  return useSsoApiClient({
    Client,
    clientId: ADB2C_CLIENT_ID,
    apiBaseUrl,
    sessionIdStorageKey,
    environment: ENVIRONMENT,
    npeClientId: NPE_CLIENT_ID,
    npeAuthority: NPE_AUTHORITY,
  });
};

const useNpeBffApiClient = () => {
  return useApi({ Client: ClaimsHomeGeneralDamageApiClient, apiBaseUrl: BFF_API_BASE_URL });
};

const useNpeLookupApiClient = () => {
  return useApi({ Client: LookupServiceApiClient, apiBaseUrl: LOOKUP_SERVICE_URL });
};

const useNpeMfaOtpServiceApiClient = () => {
  return useApi({ Client: MfaOtpApiClient, apiBaseUrl: MFA_OTP_SERVICE_API_BASE_URL });
};

const useNpeDocumentServiceApiClient = () => {
  const documentServiceSessionKey = useFileUploadSessionKey();

  return useSsoApiClient({
    Client: DocumentServiceApiClient,
    clientId: ADB2C_CLIENT_ID,
    apiBaseUrl: DOCUMENT_SERVICE_API_BASE_URL,
    sessionIdStorageKey: documentServiceSessionKey,
    environment: ENVIRONMENT,
    npeClientId: NPE_CLIENT_ID,
    npeAuthority: NPE_AUTHORITY,
  });
};

export { useNpeBffApiClient, useNpeDocumentServiceApiClient, useNpeLookupApiClient, useNpeMfaOtpServiceApiClient };
