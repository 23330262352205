import styled from "@emotion/styled";
import { Card } from "@racwa/react-components";

export const StyledCard = styled(Card)({
  marginLeft: "0px",
  padding: "25px",
  flex: "1",
  "& p": {
    padding: "0px 0 8px",
    margin: "0",
  },
});
