import { Grid, Link, Typography } from "@mui/material";
import { event, gtm } from "@racwa/analytics";
import { InsurancePhoneNumber, NotificationCard } from "raci-react-library";

const notificationCardTitle = "Sorry, you can't claim online";

export const KnockoutNotificationCard = () => {
  gtm(event(notificationCardTitle));
  return (
    <Grid item sx={{ paddingTop: "16px" }}>
      <NotificationCard
        id="fence-knockout-notification-card"
        severity="error"
        message={{
          title: notificationCardTitle,
          content: (
            <Typography variant="body2">
              Please call us on <Link href={InsurancePhoneNumber.href}>{InsurancePhoneNumber.display}</Link> so we can
              help you.
            </Typography>
          ),
        }}
      />
    </Grid>
  );
};
