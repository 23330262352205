import CashSettlementDialog from "@/shared/components/CashSettlementDialog";
import CashSettlementOption from "@/shared/components/CashSettlementOption";
import CashSettlementOptionItems from "@/shared/components/CashSettlementOptionItems";
import PageTitle from "@/shared/components/PageTitle";
import UpgradeMyFenceDialog from "@/shared/components/UpgradeMyFenceDialog";
import { SettlementRoute } from "@/views/common/Settlement";
import { SettlementState } from "@/views/common/Settlement/types";
import { faMoneyBillWave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid, Typography } from "@mui/material";
import { NotificationCard, useGetSessionState } from "raci-react-library";
import { useState } from "react";
import { FixMyFenceDialog } from "./components/FixMyFenceDialog";
import { RepairQuoteOption } from "./components/RepairQuoteOption";
import { StyledGridChooseOptions } from "./styled";
import { YourSettlementOptionsFormProps } from "./types";

export const Form: React.FC<YourSettlementOptionsFormProps> = ({ onRepairQuote, onAcceptSettlement }) => {
  const [settlementOptionOpen, setSettlementOptionOpen] = useState(false);
  const [settlementDialogOpen, setSettlementDialogOpen] = useState(false);
  const settlementOptions = useGetSessionState<SettlementState>(SettlementRoute.path);

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <PageTitle id="header" title="Choose your claim settlement option" showClaimNumber />
        </Grid>
        <StyledGridChooseOptions item xs={12}>
          <Typography variant="h3">
            You can accept the cash offer now or get a repair quote and send it to us to review.
          </Typography>
        </StyledGridChooseOptions>
        <Grid sx={{ marginTop: "16px" }} item xs={12}>
          <CashSettlementOption
            onClick={() => setSettlementDialogOpen(true)}
            open={settlementOptionOpen}
            onToggleOpen={() => setSettlementOptionOpen((wasOpen) => !wasOpen)}
            cashSettlementAmount={settlementOptions.totalRepairCost ?? 0}
            title="Accept cash settlement now"
          >
            <CashSettlementOptionItems />
            <NotificationCard
              icon={
                <div style={{ marginTop: "4px" }}>
                  <FontAwesomeIcon icon={faMoneyBillWave} />
                </div>
              }
              message={{
                title:
                  "If the cash amount doesn't cover your costs, please send us your quote. We'll review the amount and discuss your options.",
              }}
            />
          </CashSettlementOption>
        </Grid>
        <Grid sx={{ marginTop: "16px" }} item xs={12}>
          <RepairQuoteOption onClick={onRepairQuote} />
        </Grid>
        <Grid item xs={12}>
          <FixMyFenceDialog />
          <UpgradeMyFenceDialog>
            If you'd like to upgrade your fence, you can accept the cash and pay the difference.
          </UpgradeMyFenceDialog>
        </Grid>
        <CashSettlementDialog
          onConfirm={onAcceptSettlement}
          onClickBreakdownLink={() => setSettlementOptionOpen(true)}
          showDialog={settlementDialogOpen}
          setShowDialog={setSettlementDialogOpen}
        />
      </Grid>
    </>
  );
};

export default Form;
