import { CallToActionButton } from "@/shared/components/CallToActionButton";
import PageTitle from "@/shared/components/PageTitle";
import { Grid } from "@mui/material";
import { YesNoButtonGroup } from "raci-react-library";
import { FormProvider, useWatch } from "react-hook-form";
import DiscussNotificationCard from "./components/DiscussNotificationCard";
import ExamplesNotificationCard from "./components/ExamplesNotificationCard";
import { FixturesAndFittingsQuestions } from "./constants";
import { FixturesAndFittingsFormProps } from "./types";

const { damageToFixturesOrFittings } = FixturesAndFittingsQuestions;

export const FixturesAndFittingsForm = ({ form, onSubmit }: FixturesAndFittingsFormProps) => {
  const {
    handleSubmit,
    formState: { isSubmitting },
    control,
  } = form;

  const damageToFixturesOrFittingsWatch = useWatch({ control, name: damageToFixturesOrFittings.name });

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)} action="#">
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <PageTitle id="header" title="Because this is an investment property..." showClaimNumber />
          </Grid>
          <Grid item xs={12}>
            <YesNoButtonGroup
              name={damageToFixturesOrFittings.name}
              label={damageToFixturesOrFittings.label}
              data-testid={damageToFixturesOrFittings.id}
            />
          </Grid>
          <Grid item xs={12} sx={{ marginTop: "8px" }}>
            <ExamplesNotificationCard />
          </Grid>
          {damageToFixturesOrFittingsWatch && (
            <Grid item xs={12} sx={{ marginTop: "16px" }}>
              <DiscussNotificationCard />
            </Grid>
          )}
          <Grid item xs={12}>
            <CallToActionButton disabled={isSubmitting}>Next</CallToActionButton>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default FixturesAndFittingsForm;
