import { Pages } from "@/App";
import { errorStateAtom } from "@/shared/atoms";
import { ClaimsHomeGeneralDamageApiException } from "raci-claims-home-general-damage-clientproxy";
import { useHasSession, useIsLoggedInToMyRac } from "raci-react-library";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { useBffApiClient } from "../useApiClient";
import useErrorHandling from "../useErrorHandling";
import useOnExpiry from "../useOnExpiry";

export const useGetWebChatInformation = () => {
  const client = useBffApiClient();
  const location = useLocation();
  const isAuthenticated = useIsLoggedInToMyRac();
  const hasSession = useHasSession();
  const onExpiry = useOnExpiry();
  const handleError = useErrorHandling();
  const [getPointOfContactId, setPointOfContactId] = useState("");
  const [getShouldRenderWebChat, setShouldRenderWebChat] = useState(false);
  const [getCrmId, setCrmId] = useState("");
  const currentLocation = location.pathname.toLowerCase();

  const disallowPages: Array<string> = [
    "/claims/home/general/storm-damage-claim",
    "/claims/home/general/storm-damaged-fence-claim",
    "/claims/home/general/before-you-start",
    "/claims/home/general/dont-have-a-policy",
    "/claims/home/general/408",
    "/claims/home/general/error",
    "/claims/home/general/redirect",
    "/claims/home/general/confirmation",
  ] satisfies Array<Pages>;

  const shouldGetWebChatInformation = isAuthenticated && hasSession && !disallowPages.includes(currentLocation);
  const errorState = useRecoilValue(errorStateAtom);

  useEffect(() => {
    let isCancelled = false;

    const getWebChatId = async () => {
      try {
        if (!isCancelled) {
          if (shouldGetWebChatInformation) {
            const { result } = await client.getWebChatContactId();
            setPointOfContactId(result.pointOfContactId ?? "");
            setCrmId(result.crmId ?? "");
            setShouldRenderWebChat(true);
          } else {
            setShouldRenderWebChat(false);
          }
        }
      } catch (ex) {
        const { status, message } = ex as ClaimsHomeGeneralDamageApiException;
        if (status === 401) {
          onExpiry();
        } else if (status === 500) {
          handleError({
            message: `Webchat error - ${message}`,
            shouldRedirect: false,
          });
        }
        setPointOfContactId("");
        setShouldRenderWebChat(false);
      }
    };

    getWebChatId();

    return () => {
      isCancelled = true;
    };
  }, [client, currentLocation, errorState, handleError, onExpiry, shouldGetWebChatInformation]);

  return { pointOfContactId: getPointOfContactId, crmId: getCrmId, shouldRender: getShouldRenderWebChat };
};

export default useGetWebChatInformation;
