import { PropsWithChildren, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { StateMachineContext } from "@/shared/stateMachine/hooks";
import { stateUrlLookup, urlBackEventLookup } from "@/shared/stateMachine/lookups";
import { Action, BrowserHistory, createBrowserHistory } from "history";

export const RouteGuard: React.FC<PropsWithChildren> = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const actor = StateMachineContext.useActorRef();

  const historyRef = useRef<BrowserHistory>();
  if (historyRef.current == null) {
    historyRef.current = createBrowserHistory({ window });
  }
  const history = historyRef.current;

  useEffect(() => {
    const unlisten = history.listen(({ action, location: loc }) => {
      if (action !== Action.Pop) return;

      const event = urlBackEventLookup(loc.pathname);
      if (event && actor.getSnapshot().can(event)) {
        actor.send(event);
      } else {
        navigate(stateUrlLookup[actor.getSnapshot().value], { replace: true });
      }
    });

    if (stateUrlLookup[actor.getSnapshot().value] !== location.pathname) {
      navigate(stateUrlLookup[actor.getSnapshot().value], { replace: true });
    }

    return unlisten;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return children;
};
