import { CallToActionButton } from "@/shared/components/CallToActionButton";
import PageTitle from "@/shared/components/PageTitle";
import { Grid } from "@mui/material";
import { YesNoButtonGroup } from "raci-react-library";
import { useEffect } from "react";
import { FormProvider, useWatch } from "react-hook-form";
import ContentsDynamicList from "./components/ContentsDynamicList";
import NoContentsInfoCard from "./components/NoContentInfoCard";
import NoDamageNotificationCard from "./components/NoDamageNotificationCard";
import SubmitInfoCard from "./components/SubmitInfoCard";
import { ContentsListQuestions, anyOtherDamagedContentsName } from "./constants";
import { ContentsListFormProps } from "./types";

const { anyOtherDamagedContents } = ContentsListQuestions;
const { contentsList } = ContentsListQuestions;

export const ContentsListForm = ({
  form,
  onSubmit,
  waterDamageToCarpets,
  buildingWaterDamage,
  waterDamageRestorer,
  isContentsOnly,
  isContentsAndFence,
  isBuildingAndContents,
  isBuildingContentsAndFence,
}: ContentsListFormProps) => {
  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
    setValue,
  } = form;

  const anyOtherDamagedContentsWatch = useWatch({ control, name: anyOtherDamagedContentsName });
  const noDamageToClaimFor = anyOtherDamagedContentsWatch === false && waterDamageToCarpets === false;

  useEffect(() => {
    if (!anyOtherDamagedContentsWatch) {
      setValue("contentsList", [{ name: "", value: "" }]);
    }
  }, [anyOtherDamagedContentsWatch, setValue]);

  const showItemsList = anyOtherDamagedContentsWatch && !waterDamageRestorer;
  const showSubmitClaimCard =
    anyOtherDamagedContentsWatch && buildingWaterDamage === true && waterDamageRestorer === true && !isContentsOnly;
  const showNoContentsInfoCard =
    anyOtherDamagedContentsWatch === false &&
    buildingWaterDamage === false &&
    (isBuildingAndContents || isBuildingContentsAndFence);
  const showNoDamageNotificationCard = noDamageToClaimFor && (isContentsOnly || isContentsAndFence);

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)} action="#">
        <Grid container spacing={1} direction="column">
          <Grid item xs={12}>
            <PageTitle id="header" title="Tell us about your damaged contents" showClaimNumber />
          </Grid>
          <Grid item xs={12}>
            <YesNoButtonGroup
              name={anyOtherDamagedContents.name}
              label={anyOtherDamagedContents.label.replace(
                isBuildingAndContents || isBuildingContentsAndFence ? "other " : "",
                "",
              )}
              id={anyOtherDamagedContents.id}
              data-testid={anyOtherDamagedContents.id}
            />
          </Grid>
          {showItemsList && (
            <Grid item xs={12}>
              <ContentsDynamicList id={contentsList.id} label={contentsList.label} />
            </Grid>
          )}
          {showSubmitClaimCard && (
            <Grid item xs={12}>
              <SubmitInfoCard />
            </Grid>
          )}
          {showNoContentsInfoCard && (
            <Grid item xs={12}>
              <NoContentsInfoCard />
            </Grid>
          )}
          {showNoDamageNotificationCard && (
            <Grid item xs={12}>
              <NoDamageNotificationCard />
            </Grid>
          )}
          <Grid item xs={12}>
            <CallToActionButton disabled={isSubmitting || showNoDamageNotificationCard}>Next</CallToActionButton>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default ContentsListForm;
