import { Button, Divider, Grid, styled, Typography } from "@mui/material";

export const StyledGrid = styled(Grid)({
  width: "min(100%, 600px)",
  "& svg[data-icon='money-bill-wave']": {
    marginTop: "3px",
  },
});

export const SubHeadingContainer = styled(Grid)(({ theme }) => ({
  marginBottom: "24px",
  [theme.breakpoints.up("sm")]: {
    marginBottom: "32px",
  },
}));

export const SubHeadingTypography = styled(Typography)(({ theme }) => ({
  fontsize: "26px",
  textAlign: "center",
  [theme.breakpoints.up("sm")]: {
    fontSize: "40px",
  },
}));

export const TitleTypography = styled(Typography)({
  fontWeight: 400,
  fontSize: "18px",
});

export const AccordionContentTypography = styled(Typography)({
  marginTop: "8px",
  marginLeft: "32px",
  fontSize: "18px",
  "& ol": {
    paddingLeft: "20px",
    marginTop: "0",
    marginBottom: "0",
  },
  textAlign: "start",
});

export const StyledDivider = styled(Divider)({
  marginLeft: "16px",
  marginRight: "16px",
});

export const StyledButton = styled(Button)({
  margin: "24px 0 0 0",
});

export const RegisterNowTypography = styled(Typography)({
  paddingTop: "8px",
  textAlign: "center",
  fontWeight: 400,
});
