import { Typography } from "@mui/material";
import { NotificationCard } from "raci-react-library";

const title = "Please check your date and time";

export const PleaseCheckYourDateTime = () => {
  return (
    <NotificationCard
      severity="info"
      message={{
        title,
        content: <Typography>You won't be able to change this later.</Typography>,
      }}
    />
  );
};

export default PleaseCheckYourDateTime;
