import useStepperPage from "@/shared/hooks/useStepperPage";
import { createPageTitle } from "@/shared/routing/TitleGuard";
import { RacwaStepperTemplate } from "@racwa/react-components";
import { RouteObject } from "react-router-dom";
import SafetyChecksForm from "./Form";
import useSafetyChecks from "./hooks/useSafetyChecks";

export const pageName = "Safety checks";

export const SafetyChecks = () => {
  const props = useSafetyChecks();
  const stepperProps = useStepperPage();

  return (
    <RacwaStepperTemplate {...stepperProps}>
      <SafetyChecksForm {...props} />
    </RacwaStepperTemplate>
  );
};

export const SafetyChecksRoute = {
  path: "safety-checks",
  element: <SafetyChecks />,
  loader: () => (document.title = createPageTitle(pageName)),
} as const satisfies RouteObject;

export default SafetyChecks;
