import useReferenceData from "@/shared/hooks/useReferenceData";
import { YourDamagedFenceRoute } from "@/views/common/YourDamagedFence";
import { fenceLengthDefaultCheckboxLabel } from "@/views/common/YourDamagedFence/components/FenceLengthInput/FenceLengthInput";
import { YourFenceQuestions } from "@/views/common/YourDamagedFence/constants";
import { YourFenceFormValues, YourFenceState } from "@/views/common/YourDamagedFence/types";
import { useGetSessionState } from "raci-react-library";
import { FormSummary } from "../../types";

export const useGetYourFenceSummary = (): FormSummary<YourFenceFormValues> => {
  const { fenceTypes } = useReferenceData();
  const { fenceDamagedSides, fenceLength, fencePaintedLength, fenceType } = useGetSessionState<YourFenceState>(
    YourDamagedFenceRoute.path,
  );

  const canBePainted =
    (fenceTypes.find((obj) => obj.fenceTypeId === fenceType)?.canBePainted ?? false) &&
    !(fenceLength?.hasTroubleMeasuring ?? false);

  return {
    fenceType: {
      label: YourFenceQuestions.fenceType.label,
      content: fenceTypes.find((obj) => obj.fenceTypeId === fenceType)?.description,
    },
    fenceLength: {
      label: YourFenceQuestions.fenceLength.label,
      content: fenceLength?.hasTroubleMeasuring ? fenceLengthDefaultCheckboxLabel : fenceLength?.fenceLength,
    },
    fencePaintedLength: {
      label: YourFenceQuestions.fencePaintedLength.label,
      content: canBePainted ? fencePaintedLength?.toString() : undefined,
    },
    fenceDamagedSides: {
      label: YourFenceQuestions.fenceDamagedSides.label,
      content: fenceDamagedSides?.map((obj) => obj.label).join(", "),
    },
  };
};
