import useStepperPage from "@/shared/hooks/useStepperPage";
import { createPageTitle } from "@/shared/routing/TitleGuard";
import { RacwaStepperTemplate } from "@racwa/react-components";
import { RouteObject } from "react-router-dom";
import YourBankDetailsForm from "./Form";
import useYourBankDetails from "./hooks/useYourBankDetails";

export const pageName = "Your bank details";

const YourBankDetails = () => {
  const props = useYourBankDetails();
  const stepperProps = useStepperPage();

  return (
    <RacwaStepperTemplate {...stepperProps}>
      <YourBankDetailsForm {...props} />
    </RacwaStepperTemplate>
  );
};

export const YourBankDetailsRoute = {
  path: "your-bank-details",
  element: <YourBankDetails />,
  loader: () => (document.title = createPageTitle(pageName)),
} as const satisfies RouteObject;

export default YourBankDetails;
