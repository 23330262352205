import { errorStateAtom, flowCompletedAtom } from "@/shared/atoms";
import useStandardPage from "@/shared/hooks/useStandardPage";
import { createPageTitle } from "@/shared/routing/TitleGuard";

import { ErrorState } from "@/shared/types";
import { StartYourClaimState } from "@/views/common/StartYourClaim/types";
import { RacwaStandardPageTemplate } from "@racwa/react-components";
import {
  ErrorContentPhoneButton,
  SystemUnavailableContent,
  trackSystemUnavailablePage,
  useGetSessionState,
} from "raci-react-library";
import { useEffect } from "react";
import { RouteObject, useLocation } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { StartYourClaimRoute } from "../StartYourClaim";

export const heading = "Uh oh!";
export const pageName = "System unavailable";

export const SystemUnavailable = () => {
  const location = useLocation();
  const setErrorState = useSetRecoilState(errorStateAtom);
  const setFlowCompleted = useSetRecoilState(flowCompletedAtom);
  const { claimNumber } = useGetSessionState<StartYourClaimState>(StartYourClaimRoute.path);

  const standardPageProps = useStandardPage();

  const isMemberContactError = location.state?.isMemberContactError ?? false;

  useEffect(() => {
    setErrorState(ErrorState.SystemUnavailable);
    trackSystemUnavailablePage(
      location.pathname.toLocaleLowerCase(),
      location.state?.referrer ?? document.referrer,
      location.state?.exception ?? null,
    );
  }, [location, setErrorState]);

  return (
    <RacwaStandardPageTemplate {...standardPageProps} heading={heading}>
      {isMemberContactError ? (
        <SystemUnavailableContent
          subtitle={"We couldn't complete your request online. Please call us."}
          buttons={[ErrorContentPhoneButton]}
        />
      ) : (
        <SystemUnavailableContent
          variant={"claims"}
          claimNumber={claimNumber}
          startClaimAgainUrl={process.env.REACT_APP_TRIAGE_APP_BUILDING_AND_CONTENTS_URL ?? "#"}
          onStartClaimAgain={() => {
            setErrorState(ErrorState.None);
            setFlowCompleted(false);
            sessionStorage.clear();
          }}
        />
      )}
    </RacwaStandardPageTemplate>
  );
};

export const SystemUnavailableRoute = {
  path: "error",
  element: <SystemUnavailable />,
  loader: () => (document.title = createPageTitle(pageName)),
} as const satisfies RouteObject;

export default SystemUnavailable;
