import { ClickAwayListener } from "@mui/material";
import { event, fieldTouched, gtm } from "@racwa/analytics";
import { RacwaNumericInput } from "@racwa/react-components";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { YourFenceQuestions, fenceLengthId, fencePaintId } from "../../constants";

export const fencePaintRequiredError = "Please enter a valid amount";
export const fencePaintLongerThanLengthError = "This amount can't be higher than the number of metres damaged";
export const fencePaintTooltipTitle = "Metres painted on your side";
export const fencePaintTooltipMessage = "This figure helps us estimate your fence repair cost.";

const { fencePaintedLength } = YourFenceQuestions;

export const FencePaintInput = () => {
  const [open, setOpen] = React.useState(false);
  const { setValue, control, getValues } = useFormContext();

  return (
    <Controller
      control={control}
      name={fencePaintId}
      rules={{
        validate: {
          valueEntered: (value) => {
            const fenceLength = getValues(fenceLengthId)?.fenceLength ?? "";
            if (Number(value) > Number(fenceLength)) {
              return fencePaintLongerThanLengthError;
            }

            return (value !== "" && value >= 0) || fencePaintRequiredError;
          },
        },
      }}
      render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => {
        const onTextBlur = (text: string) => {
          const number = parseFloat(text);
          if (!Number.isNaN(number) && number > 0) {
            setValue(fencePaintId, (Math.round(number * 100) / 100).toFixed(0));
          }
          onBlur();
        };

        return (
          <ClickAwayListener onClickAway={() => setOpen(false)}>
            <div>
              <RacwaNumericInput
                color="primary"
                id={`${fencePaintId}-textbox`}
                data-testid={fencePaintId}
                label={fencePaintedLength.label}
                sublabel={fencePaintedLength.subLabel}
                placeholder="e.g. 11"
                value={value}
                fullWidth
                error={!!error}
                helperText={error?.message || ""}
                onChange={(e: any) => {
                  onChange && onChange(e);
                }}
                tooltipProps={{
                  id: `${fencePaintId}-tooltip`,
                  open: open,
                  title: fencePaintTooltipTitle,
                  message: fencePaintTooltipMessage,
                  onClickClose: () => setOpen(false),
                  onClick: () => {
                    gtm(event(`Tooltip: ${fencePaintedLength.label}`));
                    setOpen(true);
                  },
                }}
                slotProps={{
                  input: {
                    type: "number",
                    onWheel: (event) => event.currentTarget.blur(),
                  },
                }}
                onBlur={(e) => {
                  onTextBlur && onTextBlur(e.target.value);
                  gtm(fieldTouched(fencePaintedLength.label));
                }}
              />
            </div>
          </ClickAwayListener>
        );
      }}
    />
  );
};
