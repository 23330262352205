import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { Button, Grid, Typography } from "@mui/material";
import { event, fieldTouched, gtm } from "@racwa/analytics";
import { colors } from "@racwa/styles";
import { IconWithText } from "raci-react-library";
import { StyledCard } from "./styled";

interface RepairQuoteOptionProps {
  onClick?: () => void;
}

export const RepairQuoteOption: React.FC<RepairQuoteOptionProps> = ({ onClick }) => {
  return (
    <>
      <Grid container item xs={12} spacing={1}>
        <IconWithText
          icon={faCircle}
          textLayer={
            <Typography sx={{ color: colors.white }} variant="h3">
              B
            </Typography>
          }
          text={<Typography variant="h3">Get a repair quote and email it to us</Typography>}
          iconStyle={{ height: "28px", width: "28px" }}
        />
      </Grid>
      <StyledCard background="white">
        <Typography variant="body1" paragraph align="left">
          We'll review your quote within five business days. Once we've approved the quote, we'll give you a cash
          settlement. If there's been a serious storm, it may take us longer to get back to you.
        </Typography>
        <Button
          fullWidth
          color="primary"
          onClick={() => {
            gtm(fieldTouched("Choose your claim settlement option"));
            gtm(event("Choose your claim settlement option - I'll get a repair quote"));
            onClick?.();
          }}
          data-testid="repairQuoteButton"
        >
          I'll get a repair quote
        </Button>
      </StyledCard>
    </>
  );
};
