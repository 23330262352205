import { StateMachineContext } from "@/shared/stateMachine/hooks";
import { flowLookup, stateUrlLookup } from "@/shared/stateMachine/lookups";
import { event, fieldTouched, gtm } from "@racwa/analytics";
import { MultiChoiceOption } from "raci-react-library";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { HomeClaimSubheading } from "../../constants";
import { StormDamagedFenceClaimFormProps, StormDamagedFenceClaimState } from "../types";

export enum FenceType {
  SharedFence = "SharedFence",
  NonSharedFence = "NonSharedFence",
}

export const options: MultiChoiceOption[] = [
  {
    key: FenceType.SharedFence,
    label: flowLookup["sharedFence"].name,
    sublabel: "A fence shared with your neighbour, a private business or shopping centre",
  },
  {
    key: FenceType.NonSharedFence,
    label: flowLookup["nonSharedFence"].name,
    sublabel:
      "Your fence borders an empty block, school or public property. Also includes a fence that separates part of your own property",
  },
];

export const shouldNavigateToSpark = (state: StormDamagedFenceClaimState) =>
  state.selectedFenceType.map((op) => op.key).every((op) => op === FenceType.SharedFence);

export const useStormDamagedFenceClaim = (): StormDamagedFenceClaimFormProps => {
  const navigate = useNavigate();

  const form = useForm<StormDamagedFenceClaimState>({
    mode: "onTouched",
    reValidateMode: "onChange",
  });

  const subheading = HomeClaimSubheading.StormDamagedFenceClaim;

  const actor = StateMachineContext.useActorRef();

  const onSubmit = (state: StormDamagedFenceClaimState) => {
    if (state.selectedFenceType.length === 1) {
      actor.send({
        type: "stormDamagedFenceClaim.next",
        fenceFlow: state.selectedFenceType[0].key === FenceType.SharedFence ? "shared" : "nonShared",
      });
      navigate(stateUrlLookup[actor.getSnapshot().value]);
    }
  };

  const onSelectionChange = (option: MultiChoiceOption) => {
    gtm(event(`${subheading} - ${option.label}`));
    gtm(fieldTouched(subheading));
  };

  return { form, subheading, options, onSubmit, onSelectionChange };
};

export default useStormDamagedFenceClaim;
