import { SessionState } from "raci-react-library";
import { UseFormReturn } from "react-hook-form";

export interface YourSettlementOptionsFormValues {
  settlementOption?: SettlementOption | undefined;
}

export interface YourSettlementOptionsFormProps {
  form: UseFormReturn<YourSettlementOptionsFormValues>;
  onSeeMyOptions: () => void;
  onAcceptRacRepairer: () => Promise<void>;
}

export enum SettlementOption {
  SeeMyOptions = "SeeMyOptions",
  FixMyFence = "FixMyFence",
}

export type YourSettlementOptionsState = SessionState<YourSettlementOptionsFormValues>;
