import { FormQuestions } from "@/shared/types";
import { YourFenceFormValues } from "./types";

export const fenceSideDamagedSideOptionsId = "fenceDamagedSides";
export const fenceLengthId = "fenceLength";
export const fencePaintId = "fencePaintedLength";
export const fenceTypeId = "fenceType";
const roundUpSubLabel = "Please round up to the nearest metre.";

export const YourFenceQuestions: FormQuestions<YourFenceFormValues> = {
  fenceDamagedSides: {
    id: fenceSideDamagedSideOptionsId,
    label: "If you're outside your property facing the front, which fence has been damaged?",
    subLabel: "You can choose more than one option.",
    name: fenceSideDamagedSideOptionsId,
  },
  fenceLength: {
    id: fenceLengthId,
    label: "How many metres have been damaged?",
    subLabel: roundUpSubLabel,
    name: fenceLengthId,
  },
  fencePaintedLength: {
    id: fencePaintId,
    label: "How many metres of the damaged fence are painted on your side?",
    subLabel: roundUpSubLabel,
    name: fencePaintId,
  },
  fenceType: {
    id: fenceTypeId,
    label: "What type of fence was damaged?",
    name: fenceTypeId,
  },
};
