import { CallToActionButton } from "@/shared/components/CallToActionButton";
import PageTitle from "@/shared/components/PageTitle";
import { faCheckDouble } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid, Typography } from "@mui/material";
import { event, fieldTouched, gtm } from "@racwa/analytics";
import { DamagedRoof, LightningStruckRoof, RoofWithAerialNoPadding } from "@racwa/react-components";
import { SelectedBuildingDamageLevels } from "raci-claims-home-general-damage-clientproxy";
import { NotificationCard, SelectionGroup } from "raci-react-library";
import { FormProvider, useWatch } from "react-hook-form";
import { BuildingDamageLevelsTitles, BuildingDamageOptionsQuestions, damageLevelsName } from "./constants";
import { StyledUl } from "./styled";
import { BuildingDamageFormProps } from "./types";

export const BuildingDamageForm = ({ form, onSubmit }: BuildingDamageFormProps) => {
  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
  } = form;

  const { damageLevels } = BuildingDamageOptionsQuestions;

  const selectedBuildingDamageLevels = useWatch({ control, name: damageLevelsName });

  const gtmFieldTouchedLabel = "Please select all the levels that describe your building damage";

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)} action="#">
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <PageTitle id="header" title="First, let's take care of your building damage" showClaimNumber />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              <b>We need to check the level of damage and whether your home is safe.</b>
            </Typography>
          </Grid>
          <Grid item xs={12} pt={1} pb={2}>
            <NotificationCard
              icon={<FontAwesomeIcon icon={faCheckDouble} />}
              message={{
                title: "Please select all the levels that describe your building damage",
              }}
              severity="info"
            />
          </Grid>
          <Grid item xs={12}>
            <SelectionGroup
              id={damageLevels.id}
              name={damageLevels.name}
              helperText={"Please select all options that apply"}
              exclusive={false}
            >
              <SelectionGroup.Column>
                <SelectionGroup.SelectionCard
                  id="1"
                  key="1"
                  cardIcon={<RoofWithAerialNoPadding />}
                  value={SelectedBuildingDamageLevels.Level1}
                  title={BuildingDamageLevelsTitles[SelectedBuildingDamageLevels.Level1]}
                  onSelect={() => {
                    gtm(fieldTouched(gtmFieldTouchedLabel));
                    gtm(event("Please select all the levels that describe your building damage - Level 1"));
                  }}
                >
                  <Typography variant="body1">
                    Typical examples include:
                    <StyledUl>
                      <li>Flooring</li>
                      <li>Solar panels</li>
                      <li>Garage doors and motors</li>
                      <li>TV aerial</li>
                      <li>Clothesline</li>
                      <li>Security system</li>
                      <li>Glass or leadlight</li>
                      <li>Other items</li>
                    </StyledUl>
                  </Typography>
                </SelectionGroup.SelectionCard>
                <SelectionGroup.SelectionCard
                  id="2"
                  key="2"
                  cardIcon={<LightningStruckRoof />}
                  value={SelectedBuildingDamageLevels.Level2}
                  title={BuildingDamageLevelsTitles[SelectedBuildingDamageLevels.Level2]}
                  onSelect={() => {
                    gtm(fieldTouched(gtmFieldTouchedLabel));
                    gtm(event("Please select all the levels that describe your building damage - Level 2"));
                  }}
                >
                  <Typography variant="body1">
                    Typical examples include:
                    <StyledUl>
                      <li>Some broken roof tiles</li>
                      <li>Damage to gutters and eaves</li>
                      <li>External walls damaged</li>
                      <li>Staining on internal walls or ceiling</li>
                      <li>Electrical failure to permanent fixtures such as aircon, pool pump, or power points</li>
                    </StyledUl>
                  </Typography>
                </SelectionGroup.SelectionCard>
                <SelectionGroup.SelectionCard
                  id="3"
                  key="3"
                  cardIcon={<DamagedRoof />}
                  value={SelectedBuildingDamageLevels.Level3}
                  title={BuildingDamageLevelsTitles[SelectedBuildingDamageLevels.Level3]}
                  onSelect={() => {
                    gtm(fieldTouched(gtmFieldTouchedLabel));
                    gtm(event("Please select all the levels that describe your building damage - Level 3"));
                  }}
                >
                  <Typography variant="body1">
                    Typical examples include:
                    <StyledUl>
                      <li>Part of the roof has been crushed, come off or collapsed (not just a few roof tiles)</li>
                      <li>Large cracks are spreading in the walls or ceilings</li>
                      <li>The ceiling has caved in or is sagging</li>
                    </StyledUl>
                  </Typography>
                </SelectionGroup.SelectionCard>
              </SelectionGroup.Column>
            </SelectionGroup>
            {selectedBuildingDamageLevels?.some((level) => level === SelectedBuildingDamageLevels.Level2) &&
              !selectedBuildingDamageLevels.some((level) => level === SelectedBuildingDamageLevels.Level3) && (
                <Grid sx={{ paddingTop: "24px" }}>
                  <NotificationCard
                    severity="info"
                    message={{
                      title: "Once you submit your claim, we'll get a repairer to contact you to assess your damage",
                    }}
                  />
                </Grid>
              )}

            {selectedBuildingDamageLevels?.some((level) => level === SelectedBuildingDamageLevels.Level3) && (
              <Grid sx={{ paddingTop: "24px" }}>
                <NotificationCard
                  severity="info"
                  message={{
                    title: "Your safety is our priority",
                    content: (
                      <>
                        Once you submit your claim, we'll:
                        <ul style={{ lineHeight: "24px" }}>
                          <li>Arrange temporary repairs if needed.</li>
                          <li> Get a builder to contact you to assess your damage.</li>
                        </ul>
                      </>
                    ),
                  }}
                />
              </Grid>
            )}
          </Grid>

          <Grid item xs={12}>
            <CallToActionButton disabled={isSubmitting}>Next</CallToActionButton>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default BuildingDamageForm;
