import { FenceTypeIdMapping } from "@/shared/constants";
import { useFlowState } from "@/shared/stateMachine/hooks";
import { BuildingDamageRoute } from "@/views/building/BuildingDamage";
import { BuildingDamageState } from "@/views/building/BuildingDamage/types";
import { SafetyChecksRoute } from "@/views/building/SafetyChecks";
import { SafetyChecksState } from "@/views/building/SafetyChecks/types";
import { WaterDamageRoute } from "@/views/building/WaterDamage";
import { WaterDamageState } from "@/views/building/WaterDamage/types";
import { InvoiceQuoteRoute } from "@/views/common/InvoiceQuote";
import { InvoiceQuoteState } from "@/views/common/InvoiceQuote/types";
import { SafetyAndSecurityRoute } from "@/views/common/SafetyAndSecurity";
import { SafetyAndSecurityState } from "@/views/common/SafetyAndSecurity/types";
import { SettlementRoute } from "@/views/common/Settlement";
import { SettlementState } from "@/views/common/Settlement/types";
import { StartYourClaimRoute } from "@/views/common/StartYourClaim";
import { StartYourClaimState } from "@/views/common/StartYourClaim/types";
import { YourBankDetailsRoute } from "@/views/common/YourBankDetails";
import { YourBankDetailsState } from "@/views/common/YourBankDetails/types";
import { YourDamagedFenceRoute } from "@/views/common/YourDamagedFence";
import { YourFenceState } from "@/views/common/YourDamagedFence/types";
import { ContentsListRoute } from "@/views/contents/ContentsList";
import { ContentsListState } from "@/views/contents/ContentsList/types";
import {
  AssignedServiceProviderServiceType,
  ReviewYourClaimResponseClaimCondition,
  SelectedBuildingDamageLevels,
  SelectedProblems,
  SettlementRequestDecision,
  WaterDamageProblems,
} from "raci-claims-home-general-damage-clientproxy";
import { AddNewAccountValue, useGetSessionState } from "raci-react-library";
import { ConfirmationRoute } from "../..";
import { ConfirmationFormProps, ConfirmationState } from "../../types";
import { AccountDetails, ConfirmationVariant } from "./types";

export const useConfirmation = (): ConfirmationFormProps => {
  const confirmation = useGetSessionState<ConfirmationState>(ConfirmationRoute.path);
  const buildingDamage = useGetSessionState<BuildingDamageState>(BuildingDamageRoute.path);
  const buildingWaterDamage = useGetSessionState<WaterDamageState>(WaterDamageRoute.path);
  const contentsList = useGetSessionState<ContentsListState>(ContentsListRoute.path);
  const safetyAndSecurity = useGetSessionState<SafetyAndSecurityState>(SafetyAndSecurityRoute.path);
  const safetyChecks = useGetSessionState<SafetyChecksState>(SafetyChecksRoute.path);
  const settlement = useGetSessionState<SettlementState>(SettlementRoute.path);
  const startYourClaim = useGetSessionState<StartYourClaimState>(StartYourClaimRoute.path);
  const invoiceQuote = useGetSessionState<InvoiceQuoteState>(InvoiceQuoteRoute.path);
  const bankDetails = useGetSessionState<YourBankDetailsState>(YourBankDetailsRoute.path);
  const yourFence = useGetSessionState<YourFenceState>(YourDamagedFenceRoute.path);
  const {
    fenceFlow,
    isBuildingAndFence,
    isBuildingAndContents,
    isBuildingContentsAndFence,
    isContentsAndFence,
    isFenceOnly,
    isContentsOnly,
    isBuildingOnly,
  } = useFlowState();
  const isSharedFence = fenceFlow === "shared";
  const waterDamageMakeSafe =
    buildingWaterDamage.waterDamageProblems?.some(
      (p) =>
        p.key === WaterDamageProblems.BadlySoakedCarpets ||
        p.key === WaterDamageProblems.HouseIsFlooded ||
        p.key === WaterDamageProblems.SewageOrDrainWaterInTheHouse ||
        p.key === WaterDamageProblems.WaterInTheElectrics,
    ) ?? false;

  const getVariant = (): ConfirmationVariant | undefined => {
    const isTemporaryFenceRequired = safetyAndSecurity.isSafetyIssue ?? false;

    const yourSafetyAndSecurity =
      !safetyChecks.selectedProblems?.find((p) => p.key === SelectedProblems.NoneOfThese) ||
      buildingDamage.damageLevels?.includes(SelectedBuildingDamageLevels.Level3) ||
      waterDamageMakeSafe;

    if (isContentsAndFence) {
      if (confirmation.claimCondition === ReviewYourClaimResponseClaimCondition.LodgementCompleted) {
        return {
          type: "LodgementCompleted",
          flow: ["contents", "fence"],
          isTemporaryFenceRequired,
        };
      }

      if (confirmation.claimCondition === ReviewYourClaimResponseClaimCondition.LodgementFailed) {
        return {
          type: "LodgementFailed",
          flow: ["contents", "fence"],
          isTemporaryFenceRequired,
        };
      }
    }

    if (isBuildingAndContents) {
      if (confirmation.claimCondition === ReviewYourClaimResponseClaimCondition.LodgementCompleted) {
        return {
          type: "LodgementCompleted",
          flow: ["building", "contents"],
          yourSafetyAndSecurity,
        };
      }

      if (confirmation.claimCondition === ReviewYourClaimResponseClaimCondition.LodgementFailed) {
        return {
          type: "LodgementFailed",
          flow: ["building", "contents"],
          yourSafetyAndSecurity,
        };
      }
    }

    if (isBuildingAndFence) {
      if (confirmation.claimCondition === ReviewYourClaimResponseClaimCondition.LodgementCompleted) {
        return {
          type: "LodgementCompleted",
          flow: ["building", "fence"],
          yourSafetyAndSecurity,
          isTemporaryFenceRequired,
        };
      }

      if (confirmation.claimCondition === ReviewYourClaimResponseClaimCondition.LodgementFailed) {
        return {
          type: "LodgementFailed",
          flow: ["building", "fence"],
          yourSafetyAndSecurity,
          isTemporaryFenceRequired,
        };
      }
    }

    if (isBuildingContentsAndFence) {
      if (confirmation.claimCondition === ReviewYourClaimResponseClaimCondition.LodgementCompleted) {
        return {
          type: "LodgementCompleted",
          flow: ["building", "contents", "fence"],
          yourSafetyAndSecurity,
          isTemporaryFenceRequired,
        };
      }

      if (confirmation.claimCondition === ReviewYourClaimResponseClaimCondition.LodgementFailed) {
        return {
          type: "LodgementFailed",
          flow: ["building", "contents", "fence"],
          yourSafetyAndSecurity,
          isTemporaryFenceRequired,
        };
      }
    }

    if (isBuildingOnly) {
      if (confirmation.claimCondition === ReviewYourClaimResponseClaimCondition.LodgementFailed) {
        return {
          type: "LodgementFailed",
          flow: "building",
          yourSafetyAndSecurity,
        };
      }
      return {
        type: "LodgementCompleted",
        flow: "building",
        yourSafetyAndSecurity,
      };
    }

    if (isContentsOnly) {
      const contentsRestorer = confirmation.assignedServiceProviders?.find(
        (x) => x.serviceType === AssignedServiceProviderServiceType.Restoration,
      );

      if (confirmation.paymentBlock) {
        return {
          type: "ContentsPaymentBlockAllocationFailure",
          flow: "contents",
          paymentBlock: true,
        };
      }

      if (contentsRestorer) {
        return {
          type: "ContentsRestorerMakeSafe",
          flow: "contents",
          contentsRestorer: contentsRestorer,
        };
      } else {
        if (contentsList.contentsList && contentsList.contentsList.length > 0) {
          return {
            type: "ContentsList",
            flow: "contents",
          };
        } else {
          return {
            type: "ContentsPaymentBlockAllocationFailure",
            flow: "contents",
            paymentBlock: false,
          };
        }
      }
    }

    if (isFenceOnly) {
      if (confirmation.claimCondition === ReviewYourClaimResponseClaimCondition.LodgementCompleted) {
        return {
          type: "FenceLodgementCompleted",
          flow: "fence",
          isTemporaryFenceRequired,
          isSharedFence,
        };
      }

      if (confirmation.claimCondition === ReviewYourClaimResponseClaimCondition.LodgementFailed) {
        return {
          type: "FenceLodgementFailed",
          flow: "fence",
          isSharedFence,
          isTemporaryFenceRequired,
        };
      }

      if (
        invoiceQuote.isRepairCompleted &&
        settlement.claimCondition === ReviewYourClaimResponseClaimCondition.GetAQuote
      ) {
        return {
          type: "HasRepairInvoice",
          flow: "fence",
          isTemporaryFenceRequired: false,
          isSharedFence,
        };
      }

      if (
        invoiceQuote.hasRepairQuote &&
        settlement.claimCondition === ReviewYourClaimResponseClaimCondition.GetAQuote
      ) {
        return {
          type: "HasRepairQuote",
          flow: "fence",
          isTemporaryFenceRequired,
          isSharedFence,
        };
      }

      if (settlement.decision === SettlementRequestDecision.Accepted) {
        const getAccountDetails = (): AccountDetails => {
          if (!bankDetails.dontHaveBankDetails && bankDetails.wasBankAccountCaptured && !bankDetails.mfaFailed) {
            return "provided";
          }

          if ((!bankDetails.dontHaveBankDetails && !bankDetails.wasBankAccountCaptured) || bankDetails.mfaFailed) {
            return "error";
          }

          return "not-provided";
        };

        return {
          type: "AcceptCashSettlement",
          flow: "fence",
          accountNumber:
            bankDetails.bankAccountSelection !== AddNewAccountValue && !bankDetails.isAddingAccount
              ? bankDetails.activeAccounts?.find((x) => x.id === bankDetails.bankAccountSelection)?.accountNumberSuffix
              : bankDetails.accountNumber,
          settlementAmount: settlement.totalRepairCost!,
          accountDetails: getAccountDetails(),
          isSharedFence,
          isTemporaryFenceRequired,
        };
      }

      if (settlement.decision === SettlementRequestDecision.DeclinedToGetRepairQuote) {
        return {
          type: "CashSettlementDeclinedToGetRepairQuote",
          flow: "fence",
          isTemporaryFenceRequired,
          isSharedFence: true,
        };
      }

      if (settlement.decision === SettlementRequestDecision.ContactMe) {
        return {
          type: "SettlementDeclinedForContactMe",
          flow: "fence",
          isTemporaryFenceRequired,
          isSharedFence: false,
        };
      }

      if (settlement.decision === SettlementRequestDecision.DeclinedForRacRepairs) {
        return {
          type: "SettlementDeclinedForRacRepairs",
          flow: "fence",
          isTemporaryFenceRequired,
          isSharedFence: false,
        };
      }

      if (settlement.decision === SettlementRequestDecision.TakeTimeToDecide) {
        return {
          type: "SettlementDeclinedForTakeTimeToDecide",
          flow: "fence",
          isTemporaryFenceRequired,
          isSharedFence: false,
        };
      }

      if (settlement.claimCondition === ReviewYourClaimResponseClaimCondition.GetAQuote) {
        if (yourFence.fenceType === FenceTypeIdMapping.Brick || yourFence.fenceType === FenceTypeIdMapping.Wooden) {
          return {
            type: "NotEligibleCashSettlementDueToWoodOrBrick",
            flow: "fence",
            isTemporaryFenceRequired,
            isSharedFence,
          };
        } else {
          return {
            type: "NotEligibleForCashSettlement",
            flow: "fence",
            isTemporaryFenceRequired,
            isSharedFence,
          };
        }
      } else if (settlement.claimCondition === ReviewYourClaimResponseClaimCondition.HigherExcess) {
        return {
          type: "ExcessHigherThanRepairCost",
          flow: "fence",
          isTemporaryFenceRequired,
          isSharedFence,
          repairCost: settlement.subTotalBeforeExcess!,
          excess: settlement.currentExcess!,
        };
      } else if (settlement.claimCondition === ReviewYourClaimResponseClaimCondition.PaymentBlock) {
        return {
          type: "ClearSomethingUp",
          flow: "fence",
          isTemporaryFenceRequired,
          isSharedFence,
        };
      }
    }
  };

  let fenceType = "";
  let fenceLength = "";
  if (isFenceOnly) {
    fenceType = yourFence.fenceType ?? "";
    fenceLength = yourFence?.fenceLength?.fenceLength ?? "";
  }

  return {
    variant: getVariant()!,
    firstName: startYourClaim.firstName,
    claimNumber: startYourClaim.claimNumber,
    fenceType,
    fenceLength,
  };
};

export default useConfirmation;
