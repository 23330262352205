import maskAccountNumber from "@/shared/utils/maskAccountNumber";
import { faMoneyBillWave } from "@fortawesome/free-solid-svg-icons";
import { Typography } from "@mui/material";
import { CardWithIcon, currencyFormatter } from "raci-react-library";
import { YourSettlementCardProps } from "./types";

export const YourSettlementCard: React.FC<YourSettlementCardProps> = ({ variant }) => {
  const amount = variant.type === "AcceptCashSettlement" ? variant.settlementAmount : undefined;

  const getParagraphs = () => {
    switch (variant.type) {
      case "AcceptCashSettlement":
        switch (variant.accountDetails) {
          case "provided":
            return [
              <Typography variant="body1">To account: {maskAccountNumber(variant.accountNumber)}</Typography>,
              <Typography variant="body1">Processing time: up to seven business days</Typography>,
            ];
          case "not-provided":
          case "error":
            return [
              <Typography variant="body1">
                Simply follow the link in the email to enter your bank details and get your cash settlement.
              </Typography>,
              <Typography variant="body1">
                Once we've received your bank details, it may take up to seven business days to process.
              </Typography>,
            ];
        }
    }
  };

  return (
    <CardWithIcon
      icon={faMoneyBillWave}
      title={
        <Typography variant="h3">
          Your settlement {currencyFormatter(amount ?? 0, { withDecimals: true, stripZeroCents: true })}
        </Typography>
      }
      iconStyle={{ height: "24px", width: "24px" }}
      paragraphs={getParagraphs()}
      data-testid="your-settlement"
    />
  );
};

export default YourSettlementCard;
