import { CallToActionButton } from "@/shared/components/CallToActionButton";
import getPolicyOption from "@/shared/utils/getPolicyOption";
import { Grid } from "@mui/material";
import { BASE_PDS_URL, ClaimDetailsLockedCard, StartClaim } from "raci-react-library";
import { FormProvider } from "react-hook-form";
import IneligibleForClaimError from "./components/IneligibleForClaimError";
import PleaseCheckYourDateTime from "./components/PleaseCheckYourDateTime";
import SimilarClaimDialog from "./components/SimilarClaimDialog";
import { StartYourClaimFormProps } from "./types";

export const StartYourClaimForm = ({
  form,
  policy,
  onSubmit,
  disabled,
  claimNumber,
  showSimilarClaimDialog,
  showClaimDetailsLockedCard,
  error: createClaimErrorDetails,
  onSimilarClaimAllowed,
}: StartYourClaimFormProps) => {
  const {
    handleSubmit,
    formState: { isSubmitting, isSubmitted },
  } = form;

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)} action="#">
        {policy && (
          <Grid container spacing={1}>
            <StartClaim
              policy={getPolicyOption(policy, false)}
              pds={process.env.REACT_APP_RAC_PDS_PED_URL ?? BASE_PDS_URL}
              disabled={disabled}
              claimNumber={claimNumber}
              timeEnabled
            >
              <>
                {createClaimErrorDetails?.ineligibleReason && (
                  <IneligibleForClaimError
                    reason={createClaimErrorDetails.ineligibleReason}
                    missingDamages={createClaimErrorDetails.missingDamages}
                    duplicateClaimNumber={createClaimErrorDetails.duplicateOrSimilarClaimNumber}
                  />
                )}
                {showClaimDetailsLockedCard && !(isSubmitting || isSubmitted) && <ClaimDetailsLockedCard />}
                {!showClaimDetailsLockedCard &&
                  !disabled &&
                  !claimNumber &&
                  !createClaimErrorDetails?.ineligibleReason && <PleaseCheckYourDateTime />}
                <CallToActionButton disabled={(disabled && !claimNumber) || isSubmitting}>Next</CallToActionButton>
              </>
            </StartClaim>
          </Grid>
        )}

        <SimilarClaimDialog
          showDialog={showSimilarClaimDialog ?? false}
          eventDate={createClaimErrorDetails?.similarClaimEventDate ?? new Date().toISOString()}
          claimNumber={createClaimErrorDetails?.duplicateOrSimilarClaimNumber}
          onButtonClick={handleSubmit(onSimilarClaimAllowed)}
        />
      </form>
    </FormProvider>
  );
};

export default StartYourClaimForm;
