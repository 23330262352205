import { Link, Typography } from "@mui/material";
import { event, gtm, phoneCall } from "@racwa/analytics";
import { parseISO } from "date-fns";
import format from "date-fns/format";
import { DialogBoxWithButton, InsurancePhoneNumber } from "raci-react-library";
import { useEffect } from "react";

export interface SimilarClaimDialogProps {
  showDialog: boolean;
  eventDate: string;
  claimNumber?: string;
  onButtonClick?: () => void;
}

const SimilarClaimDialog: React.FC<SimilarClaimDialogProps> = ({
  showDialog,
  claimNumber,
  eventDate,
  onButtonClick,
}: SimilarClaimDialogProps) => {
  const alreadyMadeClaim = "You've already made a claim for a similar date";

  useEffect(() => {
    if (showDialog) {
      gtm(event(alreadyMadeClaim));
    }
  }, [showDialog]);

  return (
    <DialogBoxWithButton
      onButtonClick={onButtonClick}
      buttonText="Or make a new claim"
      showDialog={showDialog}
      id="similar-claim-confirmation-dialog"
      data-testid="similar-claim-confirmation-dialog"
      title={alreadyMadeClaim}
      content={
        <>
          <p>
            <Typography>That claim was for storm damage on {format(parseISO(eventDate), "dd/MM/yyyy")}.</Typography>
          </p>
          <Typography>
            To continue that claim or ask about it, please call{" "}
            <Link
              href={InsurancePhoneNumber.href}
              onClick={() => gtm(phoneCall(InsurancePhoneNumber.displayWithoutSpaces))}
            >
              {InsurancePhoneNumber.display}
            </Link>{" "}
            and quote the <b>claim number {claimNumber}</b>.
          </Typography>
        </>
      }
      setShowDialog={() => {}}
      hideCloseButton
    />
  );
};

export default SimilarClaimDialog;
