import { faFlag } from "@fortawesome/free-solid-svg-icons";
import { Grid, Typography } from "@mui/material";
import { CardWithIcon } from "raci-react-library";
import { cardContainerStyles } from "../../../styles";

export const YourSafetyAndSecurityCard: React.FC = () => {
  return (
    <Grid item xs={12} sx={cardContainerStyles}>
      <CardWithIcon
        icon={faFlag}
        title={<Typography variant="h3">Your safety and security</Typography>}
        iconStyle={{ height: "24px", width: "24px" }}
        paragraphs={[
          <Typography variant="body1">
            We'll contact you within two business hours to discuss how we can help.
          </Typography>,
        ]}
        data-testid="your-safety-and-security"
      />
    </Grid>
  );
};

export default YourSafetyAndSecurityCard;
