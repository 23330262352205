import { useFlowState } from "@/shared/stateMachine/hooks";
import { gtm, virtualPageView } from "@racwa/analytics";
import * as React from "react";
import { useLocation } from "react-router-dom";

export interface TrackPageChangesProps {
  /** List of pages that should not be tracked */
  untrackedPages?: string[];
}

export const TrackPageChanges: React.FC<TrackPageChangesProps> = ({ untrackedPages }) => {
  const location = useLocation();
  const currentPage = location.pathname.toLocaleLowerCase();
  const shouldTrackCurrentPage = !untrackedPages?.includes(currentPage);
  const { getFlowUrl } = useFlowState();

  React.useEffect(() => {
    if (shouldTrackCurrentPage) {
      const virtualPageViewUrl = `${currentPage}${getFlowUrl()}`;
      gtm(virtualPageView({ url: virtualPageViewUrl }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return null;
};
