import CashSettlementOption from "@/shared/components/CashSettlementOption";
import CashSettlementOptionItems from "@/shared/components/CashSettlementOptionItems";
import ConfirmRacRepairerDialog from "@/shared/components/ConfirmRacRepairerDialog";
import PageTitle from "@/shared/components/PageTitle";
import UpgradeMyFenceDialog from "@/shared/components/UpgradeMyFenceDialog";
import { SettlementRoute } from "@/views/common/Settlement";
import { SettlementState } from "@/views/common/Settlement/types";
import { StartYourClaimRoute } from "@/views/common/StartYourClaim";
import { StartYourClaimState } from "@/views/common/StartYourClaim/types";
import { Grid, Typography } from "@mui/material";
import { FinePrint } from "@racwa/react-components";
import { SettlementRequestDecision } from "raci-claims-home-general-damage-clientproxy";
import { NotificationCard, currencyFormatter, useGetSessionState } from "raci-react-library";
import { useState } from "react";
import CashSettlementDialog from "../../../shared/components/CashSettlementDialog";
import { OtherOptions } from "./components/OtherOptions";
import { RepairsArranged } from "./components/RepairsArranged";
import { StyledGridChooseOptions } from "./styled";
import { CashSettlementFactSheetFormProps } from "./types";

export const Form: React.FC<CashSettlementFactSheetFormProps> = ({ onOtherOptions, onAcceptSettlement, disabled }) => {
  const [settlementOptionOpen, setSettlementOptionOpen] = useState(false);
  const [repairerDialogOpen, setRepairerDialogOpen] = useState(false);
  const [settlementDialogOpen, setSettlementDialogOpen] = useState(false);
  const settlementOptions = useGetSessionState<SettlementState>(SettlementRoute.path);
  const { date } = useGetSessionState<StartYourClaimState>(StartYourClaimRoute.path);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <PageTitle id="header" title="Cash settlement fact sheet" showClaimNumber />
      </Grid>
      <StyledGridChooseOptions item xs={12}>
        <Typography variant="h3">Choose your claim settlement option.</Typography>
        <Typography>
          <p>You can either take a cash settlement or we'll arrange the repairs for you.</p>
          <p>Or you can think about it or get us to call you.</p>
        </Typography>
        <NotificationCard
          id="legal-advice-notification"
          severity="info"
          message={{ title: "Before deciding, you may consider getting independent legal or financial advice." }}
        />
        <Grid item pt={4}>
          <FinePrint>
            Incident date:{" "}
            <b>{new Date(date!).toLocaleDateString("en-AU", { day: "numeric", month: "long", year: "numeric" })}</b>
          </FinePrint>
          <FinePrint>
            Cash Settlement Fact Sheet created:{" "}
            <b>{new Date().toLocaleDateString("en-AU", { day: "numeric", month: "long", year: "numeric" })}</b>
          </FinePrint>
          <FinePrint>
            Sum Insured of building at the time of the incident:{" "}
            <b>{currencyFormatter(settlementOptions.buildingSumInsured ?? 0, { withDecimals: true })}</b>
          </FinePrint>
        </Grid>
      </StyledGridChooseOptions>
      <Grid sx={{ marginTop: "16px" }} item xs={12}>
        <CashSettlementOption
          onClick={() => setSettlementDialogOpen(true)}
          open={settlementOptionOpen}
          onToggleOpen={() => setSettlementOptionOpen((wasOpen) => !wasOpen)}
          cashSettlementAmount={settlementOptions.totalRepairCost ?? 0}
          title={`Cash settlement of ${currencyFormatter(settlementOptions.totalRepairCost ?? 0, {
            withDecimals: true,
          })}`}
        >
          <CashSettlementOptionItems />
        </CashSettlementOption>
      </Grid>
      <Grid sx={{ marginTop: "16px" }} item xs={12}>
        <RepairsArranged onClick={() => setRepairerDialogOpen(true)} />
      </Grid>
      <Grid sx={{ marginTop: "16px" }} item xs={12}>
        <OtherOptions
          onTakeTimeToDecide={() => onOtherOptions(SettlementRequestDecision.TakeTimeToDecide)}
          onCallFromRAC={() => onOtherOptions(SettlementRequestDecision.ContactMe)}
        />
      </Grid>
      <Grid item xs={12}>
        <UpgradeMyFenceDialog>
          If you'd like to upgrade your fence, you can accept the cash and pay the difference. Or if you're getting your
          fence repaired, discuss your upgrades with the repairer.
        </UpgradeMyFenceDialog>
      </Grid>
      <CashSettlementDialog
        onConfirm={onAcceptSettlement}
        onClickBreakdownLink={() => setSettlementOptionOpen(true)}
        showDialog={settlementDialogOpen && !disabled}
        setShowDialog={setSettlementDialogOpen}
      />
      <ConfirmRacRepairerDialog
        showDialog={repairerDialogOpen && !disabled}
        setShowDialog={setRepairerDialogOpen}
        onConfirm={() => onOtherOptions(SettlementRequestDecision.DeclinedForRacRepairs)}
      />
    </Grid>
  );
};

export default Form;
