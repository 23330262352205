import { useDocumentServiceApiClient } from "@/shared/hooks/useApiClient";
import useErrorHandling from "@/shared/hooks/useErrorHandling";
import { StateMachineContext } from "@/shared/stateMachine/hooks";
import { stateUrlLookup } from "@/shared/stateMachine/lookups";
import { event, gtm } from "@racwa/analytics";
import { useFileUpload, useSessionState } from "raci-react-library";
import { useNavigate } from "react-router-dom";
import { UploadInvoiceQuoteFormProps, UploadInvoiceQuoteState } from "../../types";

export const useUploadInvoiceQuote = (): UploadInvoiceQuoteFormProps => {
  const actor = StateMachineContext.useActorRef();
  const navigate = useNavigate();
  const handleError = useErrorHandling();
  const [uploadInvoiceQuoteState, setUploadInvoiceQuoteState] = useSessionState<UploadInvoiceQuoteState>({
    skipPageTrackingRecalculation: true,
  });
  const documentServiceApiClient = useDocumentServiceApiClient();
  const redirectToSystemUnavailable = () => handleError({ message: "File upload error", shouldRedirect: true });

  const { fetchConfiguration, ...props } = useFileUpload(
    documentServiceApiClient,
    undefined,
    redirectToSystemUnavailable,
  );

  const onSubmit = () => {
    setUploadInvoiceQuoteState({
      ...uploadInvoiceQuoteState,
      isCompleted: true,
    });

    const filesUploaded = props.previouslyUploadedFiles.length;
    gtm(event(`Documents uploaded - ${filesUploaded}`));

    actor.send({
      type: "upload.next",
    });

    navigate(stateUrlLookup[actor.getSnapshot().value]);
  };

  return {
    onSubmit,
    onError: redirectToSystemUnavailable,
    ...props,
  };
};

export default useUploadInvoiceQuote;
