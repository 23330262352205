import { Pages } from "@/App";
import { Context, States } from "@/shared/stateMachine";
import { StateMachineContext } from "@/shared/stateMachine/hooks";
import { urlBackEventLookup } from "@/shared/stateMachine/lookups";
import { pageName as buildingDamagePageName } from "@/views/building/BuildingDamage";
import { pageName as fixturesAndFittingsPageName } from "@/views/building/FixturesAndFittings";
import { pageName as safetyChecksPageName } from "@/views/building/SafetyChecks";
import { pageName as specificDamagePageName } from "@/views/building/SpecificDamage";
import { pageName as waterDamagePageName } from "@/views/building/WaterDamage";
import { pageName as confirmationPageName } from "@/views/common/Confirmation";
import { pageName as contactDetailsPageName } from "@/views/common/ContactDetails";
import {
  pageName as invoiceQuotePageName,
  pageNameFenceOnly as invoiceQuotePageNameFenceOnly,
} from "@/views/common/InvoiceQuote";
import { stepperName as reviewYourClaimStepperName } from "@/views/common/ReviewYourClaim";
import { useGetBuildingDamageSummary } from "@/views/common/ReviewYourClaim/hooks/useGetBuildingDamageSummary";
import { pageName as safetyAndSecurityPageName } from "@/views/common/SafetyAndSecurity";
import { stepperName as settlementStepperName } from "@/views/common/Settlement";
import { pageName as startYourClaimPageName } from "@/views/common/StartYourClaim";
import { pageName as uploadPageName } from "@/views/common/UploadInvoiceQuote";
import { pageName as yourBankDetailsPageName } from "@/views/common/YourBankDetails";
import { pageName as yourDamagedFencePageName } from "@/views/common/YourDamagedFence";
import { pageName as yourExcessPageName } from "@/views/common/YourExcess";
import { pageName as yourPolicyPageName } from "@/views/common/YourPolicy";
import { pageName as contentsDamagePageName } from "@/views/contents/ContentsDamage";
import { pageName as contentsListPageName } from "@/views/contents/ContentsList";
import { stepperName as factSheetStepperName } from "@/views/nonSharedFence/CashSettlementFactSheet";
import { type MobileStepperProps, type RacwaStepperTemplateProps } from "@racwa/react-components";
import { type StepInformation } from "@racwa/react-components/lib/RacwaStepperTemplate/SidebarStepper";
import { SelectedBuildingDamageLevels } from "raci-claims-home-general-damage-clientproxy";
import { EMPTY_URL, SidebarContent } from "raci-react-library";
import { useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import usePage from "../usePage";

export const sidebarTitle = "Home storm claim";

type StepsContext = Context & { damageLevels?: SelectedBuildingDamageLevels[] };

export const getSteps = (currentState: States, context: StepsContext) => {
  const fenceOnly = context.flow?.length === 1 && context.flow.includes("fence");
  const contentsOnly = context.flow?.length === 1 && context.flow.includes("contents");
  const containsContents = context.flow?.includes("contents");
  const containsBuilding = context.flow?.includes("building");

  const steps: (StepInformation & { path: Pages })[] = [];

  switch (currentState) {
    case States.StormDamageClaim:
    case States.StormDamageFenceClaim:
    case States.BeforeYouStart:
      return steps;
  }

  if (!context.flow) return steps;

  steps.push({ name: contactDetailsPageName, path: "/claims/home/general/contact-details" });

  if (context.multiplePolicies === true) {
    steps.push({ name: yourPolicyPageName, path: "/claims/home/general/your-policy" });
  }

  steps.push({ name: startYourClaimPageName, path: "/claims/home/general/start-your-claim" });

  if (!context.claimCreated) {
    return steps;
  }

  if (context.flow.includes("building") || context.flow.includes("contents")) {
    steps.push({ name: yourExcessPageName, path: "/claims/home/general/your-excess" });
  }

  if (context.flow.includes("building")) {
    steps.push({ name: buildingDamagePageName, path: "/claims/home/general/building-damage" });

    if (context.damageLevels === undefined || context.damageLevels?.length === 0) {
      return steps;
    }

    if (context.damageLevels?.length === 1 && context.damageLevels[0] === SelectedBuildingDamageLevels.Level1) {
      steps.push({ name: specificDamagePageName, path: "/claims/home/general/specific-damage" });
    }

    if (context.occupancyType === "Investment property") {
      steps.push({ name: fixturesAndFittingsPageName, path: "/claims/home/general/fixtures-and-fittings" });
    }
    steps.push({ name: waterDamagePageName, path: "/claims/home/general/water-damage" });
    steps.push({ name: safetyChecksPageName, path: "/claims/home/general/safety-checks" });
  }

  if (contentsOnly || (containsContents && !containsBuilding)) {
    steps.push({ name: contentsDamagePageName, path: "/claims/home/general/contents-damage" });

    if (!context.contentsWaterDamageState) {
      return steps;
    }

    if (context.contentsWaterDamageState) {
      const showContentsList =
        (context.contentsWaterDamageState.waterDamage && !context.contentsWaterDamageState.cantDry) ||
        context.contentsWaterDamageState.waterDamage === false;

      if (showContentsList) {
        steps.push({
          name: contentsListPageName,
          path: "/claims/home/general/contents-list",
        });
      }
    }
  }

  if (containsContents && containsBuilding) {
    steps.push({
      name: contentsDamagePageName,
      path: "/claims/home/general/contents-list",
    });
  }

  if (context.flow.includes("fence")) {
    const selectedInvoiceQuotePageName = fenceOnly ? invoiceQuotePageNameFenceOnly : invoiceQuotePageName;

    steps.push({
      name: selectedInvoiceQuotePageName,
      path: "/claims/home/general/invoice-quote",
    });

    if (context.repairType === undefined) {
      return steps;
    } else {
      if (!steps.find((s) => s.name === selectedInvoiceQuotePageName)) {
        steps.push({
          name: selectedInvoiceQuotePageName,
          path: "/claims/home/general/invoice-quote",
        });
      }
    }

    switch (context.repairType) {
      case "noHaveRepairQuote":
        steps.push({ name: yourDamagedFencePageName, path: "/claims/home/general/your-damaged-fence" });
        steps.push({ name: safetyAndSecurityPageName, path: "/claims/home/general/safety-and-security" });
        break;
      case "haveRepairQuote":
        steps.push({ name: safetyAndSecurityPageName, path: "/claims/home/general/safety-and-security" });
        steps.push({ name: uploadPageName + " quote", path: "/claims/home/general/upload-your-invoice-quote" });
        break;
      case "repairsCompleted":
        steps.push({ name: uploadPageName + " invoice", path: "/claims/home/general/upload-your-invoice-quote" });
        break;
    }
  }

  steps.push({ name: reviewYourClaimStepperName, path: "/claims/home/general/review-your-claim" });

  if (context.cashSettlementState !== undefined) {
    steps.push({
      name: settlementStepperName,
      path:
        context.fenceFlow === "nonShared"
          ? "/claims/home/general/settlement-options"
          : "/claims/home/general/settlement",
    });

    if (context.cashSettlementState.seeFactSheet && context.fenceFlow === "nonShared") {
      steps.push({ name: factSheetStepperName, path: "/claims/home/general/settlement" });
    }

    if (
      (context.cashSettlementState.seeFactSheet && context.cashSettlementState.acceptCash) ||
      (context.fenceFlow === "shared" && context.cashSettlementState.acceptCash)
    ) {
      steps.push({ name: yourBankDetailsPageName, path: "/claims/home/general/your-bank-details" });
    }
  }

  if (fenceOnly && (currentState === States.Confirmation || currentState === States.BankDetails)) {
    steps.push({ name: confirmationPageName, path: "/claims/home/general/confirmation" });
  }

  if (!fenceOnly) {
    steps.push({ name: confirmationPageName, path: "/claims/home/general/confirmation" });
  }

  return steps;
};

export const useStepperPage = (): RacwaStepperTemplateProps => {
  const commonPageProps = usePage();
  const navigate = useNavigate();
  const location = useLocation();

  const actor = StateMachineContext.useActorRef();
  const state = StateMachineContext.useSelector((s) => s);
  const damageLevels = useGetBuildingDamageSummary();

  const steps = getSteps(state.value, { ...state.context, damageLevels });
  const currentStepIndex = steps.findIndex((item) => item.path === location.pathname);

  const sidebarContent = useMemo(
    () => (
      <SidebarContent
        pdsUrl={process.env.REACT_APP_RAC_PDS_PED_URL || EMPTY_URL}
        feedbackUrl={process.env.REACT_APP_RAC_FEEDBACK_URL || EMPTY_URL}
      />
    ),
    [],
  );

  const canNavigateToPreviousStep =
    state.value !== States.Confirmation &&
    state.value !== States.SettlementOptions &&
    state.value !== States.CashSettlement &&
    state.value !== States.BankDetails &&
    state.value !== States.CashSettlementFactSheet;

  const mobileStepperProps: MobileStepperProps = {
    hideBack: !canNavigateToPreviousStep || currentStepIndex === 0,
    onClickBack: () => {
      const newIndex = currentStepIndex - 1;
      if (newIndex >= 0) {
        const url = steps[`${newIndex}`].path;
        const event = urlBackEventLookup(url);

        if (event && actor.getSnapshot().can(event)) {
          actor.send(event);
          navigate(url);
        }
      }
    },
  };

  const onStepClick = (_: string, stepIndex: number) => {
    const url = steps[`${stepIndex}`].path;
    const event = urlBackEventLookup(url);

    if (event && actor.getSnapshot().can(event)) {
      actor.send(event);
      navigate(url);
    }
  };

  return {
    sidebarTitle,
    sidebarContent,
    steps,
    activeStepIndex: currentStepIndex,
    mobileStepperProps,
    onStepClick,
    ...commonPageProps,
  };
};

export default useStepperPage;
