import { createPageTitle } from "@/shared/routing/TitleGuard";
import { Grid, Typography } from "@mui/material";
import { gtm, virtualPageView } from "@racwa/analytics";
import { EMPTY_URL, MultiChoiceInput, ProductDisclosureStatementLink } from "raci-react-library";
import { useEffect } from "react";
import { FormProvider } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { heading } from ".";
import {
  StyledMultiChoiceInputContainer,
  StyledSubheadingContainer,
  StyledSubheadingText,
  StyledSubmitButton,
} from "../styled";
import { StormDamageClaimFormProps } from "./types";

const testId = "storm-damage-claim";

const StormDamageClaimForm = ({
  form,
  subheading,
  options,
  onSubmit,
  onSelectionChange,
}: StormDamageClaimFormProps) => {
  const {
    formState: { isSubmitting },
  } = form;
  const location = useLocation();

  useEffect(() => {
    gtm(
      virtualPageView({
        url: location.pathname,
        title: createPageTitle(heading),
      }),
    );
  }, [location.pathname]);

  return (
    <FormProvider {...form}>
      <form action="#" onSubmit={form.handleSubmit(onSubmit)} data-testid={testId} noValidate>
        <Grid container item xs={12} direction="column" alignItems="center">
          <StyledSubheadingContainer item xs={12}>
            <StyledSubheadingText variant="h2">{subheading}</StyledSubheadingText>
          </StyledSubheadingContainer>
          <Grid item>
            <Typography>You can choose more than one option.</Typography>
          </Grid>
          <StyledMultiChoiceInputContainer item>
            <MultiChoiceInput
              optionsFullWidth
              name="selectedDamage"
              data-testid={testId}
              options={options}
              onSelectionChange={onSelectionChange}
              errorLabel="Please select all options that apply"
            />
            <Grid item>
              <strong>
                <ProductDisclosureStatementLink
                  showAbbreviation={false}
                  showInline={true}
                  url={process.env.REACT_APP_RAC_PDS_PED_URL || EMPTY_URL}
                  preamble="See the"
                />{" "}
                for more information.
              </strong>
            </Grid>
            <Grid item>
              <StyledSubmitButton
                variant="contained"
                color="primary"
                type="submit"
                name="submit"
                data-testid={`${testId}-button`}
                fullWidth
                disabled={isSubmitting}
              >
                Next
              </StyledSubmitButton>
            </Grid>
          </StyledMultiChoiceInputContainer>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default StormDamageClaimForm;
