import { RacwaStandardPageTemplateProps } from "@racwa/react-components";
import usePage from "../usePage";

export const useStandardPage = (): RacwaStandardPageTemplateProps => {
  const commonPageProps = usePage();

  return {
    navBreadcrumbProps: commonPageProps.responsiveHeaderProps?.NavBreadcrumbsProps,
    ...commonPageProps,
  };
};

export default useStandardPage;
