import { YesNoImNotSure } from "raci-react-library";
import { ContentsDamageFormValues } from "./types";

export const getNotificationFlags = (values: ContentsDamageFormValues) => {
  const isTakeItFromHere = values?.waterDamageToCarpets && values?.cantDryCarpets === YesNoImNotSure.Yes;
  const isThanksForInformation =
    !isTakeItFromHere &&
    ((values?.waterDamageToCarpets != null && !values?.waterDamageToCarpets) ||
      (values?.waterDamageToCarpets &&
        (values?.cantDryCarpets === YesNoImNotSure.No || values?.cantDryCarpets === YesNoImNotSure.ImNotSure)));

  return { isTakeItFromHere, isThanksForInformation };
};
