import { Covers, PolicyDetailsResponse } from "raci-claims-home-general-damage-clientproxy";
import { PolicyOption } from "raci-react-library";

export const getPolicyOption = (
  policyDetails: PolicyDetailsResponse,
  showCoverAndOccupancy: boolean = true,
): PolicyOption => {
  const hasBuildingCover = policyDetails.policySummary?.covers?.includes(Covers.Building);
  const hasContentsCover = policyDetails.policySummary?.covers?.includes(Covers.Contents);

  let coverType = "";
  if (hasBuildingCover && hasContentsCover) {
    coverType = "Building and contents";
  } else if (hasBuildingCover) {
    coverType = "Building";
  } else if (hasContentsCover) {
    coverType = "Contents";
  }

  const policyTitle = policyDetails.policySummary?.houseNumber
    ? `${policyDetails.policySummary?.houseNumber} ${policyDetails.policySummary?.streetName}`
    : policyDetails.policySummary?.streetName ?? "";

  return {
    policyTitle,
    policySubtitle: showCoverAndOccupancy ? coverType : "",
    policyType: showCoverAndOccupancy ? policyDetails.occupancyType : "",
    policyNumber: policyDetails?.policySummary?.policyNumber ?? "",
  };
};

export default getPolicyOption;
