import { FormQuestions } from "@/shared/types";
import { InvoiceQuoteFormValues } from "./types";

export const isRepairCompletedName = "isRepairCompleted";
export const hasRepairQuoteName = "hasRepairQuote";

export const InvoiceQuoteQuestions: FormQuestions<InvoiceQuoteFormValues> = {
  hasRepairQuote: {
    id: "repairQuoteQuestion",
    label: "Do you have a repair quote?",
    name: hasRepairQuoteName,
  },
  isRepairCompleted: {
    id: "repairsCompletedQuestion",
    label: "Have you already completed the repairs?",
    name: isRepairCompletedName,
  },
};
