import { Button, Grid, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

export const StyledSubheadingContainer = styled(Grid)({
  marginBottom: "40px",
});

export const StyledSubheadingText = styled(Typography)(({ theme }) => ({
  fontSize: "26px",
  textAlign: "center",
  [theme.breakpoints.up("sm")]: {
    fontSize: "40px",
  },
}));

export const StyledMultiChoiceInputContainer = styled(Grid)({
  width: "min(100%, 420px)",
});

export const StyledSubmitButton = styled(Button)({
  margin: "40px 0 0 0",
});
