import { faToolbox } from "@fortawesome/free-solid-svg-icons";
import { Grid, Link, Typography } from "@mui/material";
import { gtm, phoneCall } from "@racwa/analytics";
import { AssignedServiceProvider } from "raci-claims-home-general-damage-clientproxy";
import { CardWithIcon, formatPhoneNumber } from "raci-react-library";
import { cardContainerStyles } from "../../ConfirmationHeader/styles";

export interface YourContentsRestorerCardProps {
  assignedContentsRestorer: AssignedServiceProvider;
}

export const YourContentsRestorerCard: React.FC<YourContentsRestorerCardProps> = ({ assignedContentsRestorer }) => {
  return (
    <Grid item xs={12} sx={cardContainerStyles}>
      <CardWithIcon
        icon={faToolbox}
        title={<Typography variant="h3">Your restorer</Typography>}
        iconStyle={{ height: "24px", width: "24px" }}
        paragraphs={[
          <Typography variant="body1">
            {assignedContentsRestorer.name} will contact you within two business hours.
          </Typography>,
          <Typography variant="body1">They'll assess your damaged items.</Typography>,
          <Typography variant="body1">
            Or you can call them on{" "}
            <Link
              href={`tel:${assignedContentsRestorer.phoneNumber}`}
              onClick={() => gtm(phoneCall(assignedContentsRestorer.phoneNumber ?? ""))}
            >
              {formatPhoneNumber(assignedContentsRestorer.phoneNumber)}
            </Link>
            .
          </Typography>,
        ]}
        data-testid="contents-restorer-card"
      />
    </Grid>
  );
};

export default YourContentsRestorerCard;
