import useStepperPage from "@/shared/hooks/useStepperPage";
import { createPageTitle } from "@/shared/routing/TitleGuard";
import { RacwaStepperTemplate } from "@racwa/react-components";
import { RouteObject } from "react-router-dom";
import FixturesAndFittingsForm from "./Form";
import useFixturesAndFittings from "./hooks/useFixturesAndFittings";

export const pageName = "Fixtures and fittings";

export const FixturesAndFittings = () => {
  const props = useFixturesAndFittings();
  const stepperProps = useStepperPage();

  return (
    <RacwaStepperTemplate {...stepperProps}>
      <FixturesAndFittingsForm {...props} />
    </RacwaStepperTemplate>
  );
};

export const FixturesAndFittingsRoute = {
  path: "fixtures-and-fittings",
  element: <FixturesAndFittings />,
  loader: () => (document.title = createPageTitle(pageName)),
} as const satisfies RouteObject;

export default FixturesAndFittings;
