import { StartYourClaimRoute } from "@/views/common/StartYourClaim";
import { StartYourClaimFormValues, StartYourClaimState } from "@/views/common/StartYourClaim/types";
import { formatDateToTimeString, useGetSessionState } from "raci-react-library";
import { FormSummary } from "../../types";

export const useGetStartYourClaimSummary = (): FormSummary<StartYourClaimFormValues> => {
  const { date, time } = useGetSessionState<StartYourClaimState>(StartYourClaimRoute.path);
  return {
    date: {
      label: "When did the damage happen?",
      content: date && new Date(date).toLocaleDateString("en-AU"),
    },
    time: {
      label: "Approximate time",
      content: time && formatDateToTimeString(new Date(time)),
    },
  };
};
