import { InvoiceQuoteState } from "@/views/common/InvoiceQuote/types";

export const getInvoiceQuoteText = (state: InvoiceQuoteState) => {
  if (state.isRepairCompleted) {
    return "invoice";
  }

  if (state.hasRepairQuote) {
    return "quote";
  }
};
