import { useBffApiClient } from "@/shared/hooks/useApiClient";
import useErrorHandling from "@/shared/hooks/useErrorHandling";
import { StateMachineContext } from "@/shared/stateMachine/hooks";
import { stateUrlLookup } from "@/shared/stateMachine/lookups";
import { ContentsListRoute } from "@/views/contents/ContentsList";
import { ContentsListState } from "@/views/contents/ContentsList/types";
import { ClaimsHomeGeneralDamageApiException } from "raci-claims-home-general-damage-clientproxy";
import { YesNoImNotSure, useSessionState, useSetBackdrop } from "raci-react-library";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { CantDryCarpetsYesNoImNotSure } from "../../constants";
import { ContentsDamageFormProps, ContentsDamageFormValues, ContentsDamageState } from "../../types";

export const useContentsDamage = (): ContentsDamageFormProps => {
  const actor = StateMachineContext.useActorRef();
  const navigate = useNavigate();
  const apiClient = useBffApiClient();
  const setBackdrop = useSetBackdrop();
  const handleError = useErrorHandling();
  const [contentsDamageState, setContentsDamageState] = useSessionState<ContentsDamageState>({
    skipPageTrackingRecalculation: true,
  });

  const [contentsListState, setContentsListState] = useSessionState<ContentsListState>({
    specificKey: ContentsListRoute.path,
  });

  const form = useForm<ContentsDamageFormValues>({
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues: contentsDamageState,
  });

  const onSubmit = async (values?: ContentsDamageFormValues) => {
    try {
      setBackdrop(true);
      const cantDryCarpets = values?.waterDamageToCarpets === false ? undefined : values?.cantDryCarpets;

      await apiClient.contentsDamage({
        waterDamageToCarpets: values?.waterDamageToCarpets ?? false,
        cantDryCarpets: cantDryCarpets === undefined ? undefined : CantDryCarpetsYesNoImNotSure[`${cantDryCarpets}`],
      });

      if (values?.waterDamageToCarpets && values.cantDryCarpets === YesNoImNotSure.Yes) {
        setContentsListState({
          ...contentsListState,
          isCompleted: false,
          contentsList: [],
        });

        await apiClient.contentsList({ contentsList: [] });
      }

      setContentsDamageState({
        ...contentsDamageState,
        ...values,
        cantDryCarpets,
        isCompleted: true,
      });

      actor.send({
        type: "contentsDamage.next",
        waterDamage: values?.waterDamageToCarpets ?? false,
        cantDry: values?.cantDryCarpets === YesNoImNotSure.Yes ?? false,
      });
      navigate(stateUrlLookup[actor.getSnapshot().value]);
    } catch (exception) {
      const error = exception as ClaimsHomeGeneralDamageApiException;
      handleError({
        message: "Error submitting contents damage",
        shouldRedirect: true,
        customProperties: {
          request: "POST /step/contents-damage",
          status: error.status,
          error: error.message,
        },
      });
    } finally {
      setBackdrop(false);
    }
  };

  return {
    form,
    onSubmit,
  };
};

export default useContentsDamage;
