import useStandardPage from "@/shared/hooks/useStandardPage";
import { createPageTitle } from "@/shared/routing/TitleGuard";
import { FenceFlow } from "@/shared/stateMachine";
import { useFlowState } from "@/shared/stateMachine/hooks";
import { RacwaStandardPageTemplate, theme } from "@racwa/react-components";
import { RouteObject } from "react-router-dom";
import { Building } from "./Components/Building";
import { BuildingAndContents } from "./Components/BuildingAndContents";
import { BuildingAndFence } from "./Components/BuildingAndFence";
import { Contents } from "./Components/Contents";
import { ContentsAndFence } from "./Components/ContentsAndFence";
import { NonSharedFence } from "./Components/NonSharedFence";
import { SharedFence } from "./Components/SharedFence";
import Form from "./Form";
import useBeforeYouStart from "./hooks/useBeforeYouStart";

export const headingBuilding = "Building storm claim";
export const headingNonSharedFence = "Non-shared fence storm claim";
export const headingSharedFence = "Shared fence storm claim";
export const headingContents = "Contents storm claim";
export const contentsAndSharedFence = "Contents and shared fence storm claim";
export const contentsAndNonSharedFence = "Contents and non-shared fence storm claim";
export const buildingAndSharedFence = "Building and shared fence storm claim";
export const buildingAndNonSharedFence = "Building and non-shared fence storm claim";
export const buildingAndContents = "Building and contents storm claim";
export const buildingContentsAndFence = "Building, contents and fence storm claim";
export const pageName = "Before You Start";

export const getHeading = (
  isBuildingOnly: boolean,
  isContentsOnly: boolean,
  isContentsAndFence: boolean,
  isBuildingAndFence: boolean,
  isBuildingAndContents: boolean,
  isBuildingContentsAndFence: boolean,
  fenceFlow: FenceFlow | undefined,
) => {
  if (isBuildingOnly) {
    return headingBuilding;
  }

  if (isContentsOnly) {
    return headingContents;
  }

  if (isContentsAndFence) {
    return fenceFlow === "shared" ? contentsAndSharedFence : contentsAndNonSharedFence;
  }

  if (isBuildingAndFence) {
    return fenceFlow === "shared" ? buildingAndSharedFence : buildingAndNonSharedFence;
  }

  if (isBuildingContentsAndFence) {
    return buildingContentsAndFence;
  }

  if (isBuildingAndContents) {
    return buildingAndContents;
  }

  if (fenceFlow === "shared") {
    return headingSharedFence;
  }

  if (fenceFlow === "nonShared") {
    return headingNonSharedFence;
  }
};

export const getContent = (
  isBuildingOnly: boolean,
  isContentsOnly: boolean,
  isContentsAndFence: boolean,
  isBuildingAndFence: boolean,
  isBuildingAndContents: boolean,
  isBuildingContentsAndFence: boolean,
  fenceFlow: FenceFlow | undefined,
) => {
  if (isBuildingOnly) {
    return <Building />;
  }

  if (isContentsOnly) {
    return <Contents />;
  }

  if (isContentsAndFence) {
    return <ContentsAndFence />;
  }

  if (isBuildingAndFence) {
    return <BuildingAndFence />;
  }

  if (isBuildingAndContents) {
    return <BuildingAndContents />;
  }

  if (isBuildingContentsAndFence) {
    return <BuildingAndContents />;
  }

  if (fenceFlow === "nonShared") {
    return <NonSharedFence />;
  }

  if (fenceFlow === "shared") {
    return <SharedFence />;
  }
};

const BeforeYouStart: React.FC = () => {
  const props = useBeforeYouStart();
  const standardPageProps = useStandardPage();

  const {
    isBuildingOnly,
    isContentsAndFence,
    isContentsOnly,
    isBuildingAndFence,
    isBuildingAndContents,
    isBuildingContentsAndFence,
    fenceFlow,
  } = useFlowState();
  const heading = getHeading(
    isBuildingOnly,
    isContentsOnly,
    isContentsAndFence,
    isBuildingAndFence,
    isBuildingAndContents,
    isBuildingContentsAndFence,
    fenceFlow,
  );
  const content = getContent(
    isBuildingOnly,
    isContentsOnly,
    isContentsAndFence,
    isBuildingAndFence,
    isBuildingAndContents,
    isBuildingContentsAndFence,
    fenceFlow,
  );

  return (
    <RacwaStandardPageTemplate {...standardPageProps} heading={heading} bannerTextColour={theme.palette.primary.main}>
      <Form {...props}>{content}</Form>
    </RacwaStandardPageTemplate>
  );
};

export const BeforeYouStartRoute = {
  path: "before-you-start",
  element: <BeforeYouStart />,
  loader: () => (document.title = createPageTitle(pageName)),
} as const satisfies RouteObject;

export default BeforeYouStart;
