import { SettlementRoute } from "@/views/common/Settlement";
import { SettlementState } from "@/views/common/Settlement/types";
import { Table, TableBody, Typography } from "@mui/material";
import { BasicTooltip, formatIdString, useGetSessionState } from "raci-react-library";
import CashSettlementOption from "../CashSettlementOption";
import { StyledTableContainer } from "../StyledTableContainer";

const YourExcessTooltipTitle = "Your excess";
const YourExcessTooltipMessage = "The excess is the amount you may need to pay towards settlement of your claim.";
const EstimatedRepairCost = "Estimated repair cost";
const AsbestosInspectionFeeTooltipTitle = "Asbestos inspection fee";
const AsbestosInspectionFeeTooltipMessage =
  "This helps cover the cost of the inspection following the removal of asbestos.";

const CashSettlementOptionItems = () => {
  const settlementOptions = useGetSessionState<SettlementState>(SettlementRoute.path);
  const estimatedRepairCostTooltipMessage = (
    <>
      <Typography variant="body2" sx={{ fontWeight: 400 }}>
        <p>
          This amount is based on {settlementOptions.numberOfMetresClaimed ?? 0}m of fence. This includes materials,
          labour and painting.
        </p>
        <p>If you've made a mistake with the fence measurement, you can call us and correct it.</p>
      </Typography>
    </>
  );

  return (
    <StyledTableContainer key={"table"}>
      <Table id="cashSettlementOptionTable" data-testid="cashSettlementOptionTable">
        <TableBody>
          <CashSettlementOption.Item
            title="Number of metres claimed"
            content={`${settlementOptions.numberOfMetresClaimed}m`}
          />
          <CashSettlementOption.Item title="Cost per metre" content={settlementOptions.costPerMetre ?? 0} />
          <CashSettlementOption.Item
            title={EstimatedRepairCost}
            content={settlementOptions.subTotalBeforeAdjustments ?? 0}
          >
            <BasicTooltip
              id={formatIdString(`${EstimatedRepairCost}-tooltip`)}
              title={EstimatedRepairCost}
              message={estimatedRepairCostTooltipMessage}
              showDialog={false}
            />
          </CashSettlementOption.Item>
          {settlementOptions.asbestosInspectionFee && settlementOptions.asbestosInspectionFee > 0 ? (
            <CashSettlementOption.Item
              title="Asbestos inspection fee"
              content={settlementOptions.asbestosInspectionFee}
            >
              <BasicTooltip
                id={formatIdString(`${AsbestosInspectionFeeTooltipTitle}-tooltip`)}
                title={AsbestosInspectionFeeTooltipTitle}
                message={AsbestosInspectionFeeTooltipMessage}
                showDialog={false}
              />
            </CashSettlementOption.Item>
          ) : null}
          {settlementOptions.dividingFenceAdjustment && settlementOptions.dividingFenceAdjustment !== 0 ? (
            <CashSettlementOption.Item
              title="Shared fence adjustment 50%"
              subtitle="We cover you for your portion of the repair costs."
              content={settlementOptions.dividingFenceAdjustment}
            />
          ) : null}
          {settlementOptions.paintingCost && settlementOptions.paintingCost > 0 ? (
            <CashSettlementOption.Item title="Paint cost" content={settlementOptions.paintingCost} />
          ) : null}
          <CashSettlementOption.Item title="Less your excess" content={-(settlementOptions.currentExcess ?? 0)}>
            <BasicTooltip
              id={formatIdString(`${YourExcessTooltipTitle}-tooltip`)}
              title={YourExcessTooltipTitle}
              message={YourExcessTooltipMessage}
              showDialog={false}
            />
          </CashSettlementOption.Item>
          <CashSettlementOption.Item title="Total" content={settlementOptions.totalRepairCost ?? 0} bold />
        </TableBody>
      </Table>
    </StyledTableContainer>
  );
};

export default CashSettlementOptionItems;
