import { faHouseChimneyCrack } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NotificationCard } from "raci-react-library";

export const ExamplesNotificationCard = () => {
  return (
    <NotificationCard
      icon={<FontAwesomeIcon icon={faHouseChimneyCrack} />}
      message={{
        title: "Typical examples of fixtures and fittings:",
        content: (
          <ul>
            <li>Stoves</li>
            <li>Built-in aircon</li>
            <li>Light fittings</li>
            <li>Window furnishings</li>
            <li>Carpet and floor rugs</li>
          </ul>
        ),
      }}
      severity="info"
    />
  );
};

export default ExamplesNotificationCard;
