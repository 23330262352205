import { and, assertEvent, assign, not, setup } from "xstate";

export enum States {
  StormDamageClaim = "StormDamageClaim",
  StormDamageFenceClaim = "StormDamageFenceClaim",
  BeforeYouStart = "BeforeYouStart",
  ContactDetails = "ContactDetails",
  YourPolicy = "YourPolicy",
  StartYourClaim = "StartYourClaim",
  StartYourClaimError = "StartYourClaimError",
  InvoiceQuote = "InvoiceQuote",
  YourExcess = "YourExcess",
  BuildingDamage = "BuildingDamage",
  SpecificDamage = "SpecificDamage",
  FixturesAndFittings = "FixturesAndFittings",
  BuildingWaterDamage = "BuildingWaterDamage",
  SafetyChecks = "SafetyChecks",
  ContentsDamage = "ContentsDamage",
  ContentsList = "ContentsList",
  YourDamagedFence = "YourDamagedFence",
  SafetyAndSecurity = "SafetyAndSecurity",
  Upload = "Upload",
  ReviewYourClaim = "ReviewYourClaim",
  CashSettlement = "CashSettlement",
  SettlementOptions = "SettlementOptions",
  CashSettlementFactSheet = "CashSettlementFactSheet",
  BankDetails = "BankDetails",
  Confirmation = "Confirmation",
  SystemUnavailable = "SystemUnavailable",
  SessionTimeout = "SessionTimeout",
  YouCantMakeAClaim = "YouCantMakeAClaim",
  EndOfBuildingFlow = "EndOfBuildingFlow",
  EndOfContentsFlow = "EndOfContentsFlow",
  EndOfFenceFlow = "EndOfFenceFlow",
}

export type RepairType = "haveRepairQuote" | "noHaveRepairQuote" | "repairsCompleted";

export type IndividualFlows = `${FenceFlow}Fence` | "building" | "contents";
export type Flow = "fence" | "building" | "contents";
export type FenceFlow = "shared" | "nonShared";
export type OccupancyType = "Investment property" | "Owner occupied" | "Tenant" | "Holiday home";

export type CashSettlementState = { acceptCash: boolean; seeFactSheet: boolean };

export type ContentsWaterDamageState = { waterDamage: boolean; cantDry: boolean };

export type Context = {
  flow?: Flow[];
  fenceFlow?: FenceFlow;
  multiplePolicies?: boolean;
  claimCreated?: boolean;
  repairType?: RepairType;
  cashSettlementState?: CashSettlementState;
  contentsWaterDamageState?: ContentsWaterDamageState;
  occupancyType?: OccupancyType;
  claimSubmitted?: boolean;
};

export type Events =
  | { type: "stormDamageClaim.next"; flow: Flow[] }
  | { type: "stormDamageClaim.next.fence"; flow: Flow[] }
  | { type: "stormDamageClaim.next.contents" }
  | { type: "stormDamagedFenceClaim.next"; fenceFlow: FenceFlow }
  | { type: "beforeYouStart.next"; multiplePolicies: true }
  | { type: "beforeYouStart.next"; multiplePolicies: false; occupancyType: OccupancyType }
  | { type: "contactDetails.next" }
  | { type: "yourPolicy.next"; occupancyType: OccupancyType }
  | { type: "startYourClaim.next"; claimCreated: true }
  | { type: "startYourClaim.error"; claimCreated: false }
  | { type: "invoiceQuote.next.haveRepairQuote" }
  | { type: "invoiceQuote.next.noHaveRepairQuote" }
  | { type: "invoiceQuote.next.repairsCompleted" }
  | { type: "yourExcess.next" }
  | { type: "buildingDamage.next.toSpecificDamage" }
  | { type: "buildingDamage.next.toOther" }
  | { type: "specificDamage.next" }
  | { type: "fixturesAndFittings.next" }
  | { type: "buildingWaterDamage.next" }
  | { type: "safetyChecks.next" }
  | { type: "contentsDamage.next"; waterDamage: true; cantDry: boolean }
  | { type: "contentsDamage.next"; waterDamage: false }
  | { type: "contentsList.next" }
  | { type: "yourDamagedFence.next" }
  | { type: "safetyAndSecurity.next" }
  | { type: "upload.next" }
  | { type: "reviewClaim.submit" }
  | { type: "reviewClaim.next.confirmation" }
  | { type: "reviewClaim.next.settlement" }
  | { type: "cashSettlement.next.getQuote" }
  | { type: "cashSettlement.next.acceptCash" }
  | { type: "settlementOptions.next.allOptions" }
  | { type: "settlementOptions.next.fixFence" }
  | { type: "cashSettlementFactSheet.next.acceptCash" }
  | { type: "cashSettlementFactSheet.next.confirmation" }
  | { type: "bankDetails.next" }
  | { type: "error.systemUnavailable" }
  | { type: "error.sessionTimeout" }
  | { type: "error.youCantMakeAClaim" };

export type BackEvents =
  | { type: "back.stormDamageClaim" }
  | { type: "back.stormDamagedFenceClaim" }
  | { type: "back.beforeYouStart" }
  | { type: "back.contactDetails" }
  | { type: "back.yourPolicy" }
  | { type: "back.startYourClaim" }
  | { type: "back.invoiceQuote" }
  | { type: "back.yourExcess" }
  | { type: "back.buildingDamage" }
  | { type: "back.specificDamage" }
  | { type: "back.fixturesAndFittings" }
  | { type: "back.buildingWaterDamage" }
  | { type: "back.safetyChecks" }
  | { type: "back.contentsDamage" }
  | { type: "back.contentsList" }
  | { type: "back.yourDamagedFence" }
  | { type: "back.safetyAndSecurity" }
  | { type: "back.upload" };

export type Tags = "building" | "contents" | "sharedFence" | "nonSharedFence";

export const flowMachine = setup({
  types: {
    context: {} as Context,
    events: {} as Events | BackEvents,
    tags: {} as Tags,
  },
  guards: {
    multiplePolicies: ({ context }) => Boolean(context.multiplePolicies),
    repairsCompleted: ({ context }) => context.repairType === "repairsCompleted",
    haveRepairQuote: ({ context }) => context.repairType === "haveRepairQuote",
    noHaveRepairQuote: ({ context }) => context.repairType === "noHaveRepairQuote",
    haveRepairQuoteOrRepairsCompleted: ({ context }) =>
      context.repairType === "haveRepairQuote" || context.repairType === "repairsCompleted",
    noHaveRepairQuoteOrHaveRepairQuote: ({ context }) =>
      context.repairType === "noHaveRepairQuote" || context.repairType === "haveRepairQuote",
    sharedFence: ({ context }) => context.fenceFlow === "shared" ?? false,
    nonSharedFence: ({ context }) => context.fenceFlow === "nonShared" ?? false,
    containsFenceFlow: ({ context }) => context.flow?.includes("fence") ?? false,
    containsContentsFlow: ({ context }) => context.flow?.includes("contents") ?? false,
    containsBuildingFlow: ({ context }) => context.flow?.includes("building") ?? false,
    buildingOnlyFlow: ({ context }) => (context.flow?.length === 1 && context.flow?.includes("building")) ?? false,
    contentsOnlyFlow: ({ context }) => (context.flow?.length === 1 && context.flow?.includes("contents")) ?? false,
    fenceOnlyFlow: ({ context }) => (context.flow?.length === 1 && context.flow?.includes("fence")) ?? false,
    investmentProperty: ({ context }) => context.occupancyType === "Investment property" ?? false,
    containsBuildingOrContentsFlow: ({ context }) =>
      (context.flow?.includes("building") || context.flow?.includes("contents")) ?? false,
    contentsWaterDamageAndCantDry: ({ event }) => {
      assertEvent(event, "contentsDamage.next");
      return event.waterDamage === true && event.cantDry === true;
    },
    notContentsWaterDamage: ({ event }) => {
      assertEvent(event, "contentsDamage.next");
      return event.waterDamage === false;
    },
    claimSubmitted: ({ context }) => context.claimSubmitted ?? false,
  },
  actions: {
    setFlow: assign(({ event }) => {
      assertEvent(event, ["stormDamageClaim.next", "stormDamageClaim.next.fence"]);
      return { flow: event.flow };
    }),
    setFenceFlow: assign(({ event }) => {
      assertEvent(event, "stormDamagedFenceClaim.next");
      return { fenceFlow: event.fenceFlow };
    }),
    updateMultiplePoliciesAndOccupancyType: assign(({ event }) => {
      assertEvent(event, "beforeYouStart.next");
      if (event.multiplePolicies) {
        return { multiplePolicies: event.multiplePolicies };
      }
      return { multiplePolicies: event.multiplePolicies, occupancyType: event.occupancyType };
    }),
    updateOccupancyType: assign(({ event }) => {
      assertEvent(event, "yourPolicy.next");
      return { occupancyType: event.occupancyType };
    }),
    setNoHaveRepairQuote: assign(() => ({ repairType: "noHaveRepairQuote" as const })),
    setHaveRepairQuote: assign(() => ({ repairType: "haveRepairQuote" as const })),
    setRepairsCompleted: assign(() => ({ repairType: "repairsCompleted" as const })),
    setContentsWaterDamageState: assign(({ event }) => {
      assertEvent(event, "contentsDamage.next");
      if (event.waterDamage) {
        return { contentsWaterDamageState: { waterDamage: event.waterDamage, cantDry: event.cantDry } };
      }
      return { contentsWaterDamageState: { waterDamage: event.waterDamage, cantDry: false } };
    }),
    setClaimSubmitted: assign(() => ({ claimSubmitted: true })),
    setCashSettlement: assign(() => ({ cashSettlementState: { acceptCash: false, seeFactSheet: false } })),
    updateCashSettlementState: assign((_, params: CashSettlementState) => ({ cashSettlementState: params })),
    updateContext: assign((_, params: Partial<Context>) => params),
    setClaimCreated: assign(() => ({ claimCreated: true })),
  },
}).createMachine({
  id: "flow",
  initial: States.StormDamageClaim,
  context: {
    claimCreated: false,
    flow: undefined,
    multiplePolicies: undefined,
    occupancyType: undefined,
    repairType: undefined,
    cashSettlementState: undefined,
    contentsWaterDamageState: undefined,
    fenceFlow: undefined,
    claimSubmitted: false,
  },
  states: {
    [States.StormDamageClaim]: {
      tags: ["sharedFence", "nonSharedFence", "contents"],
      on: {
        "stormDamageClaim.next.fence": { target: States.StormDamageFenceClaim, actions: "setFlow" },
        "stormDamageClaim.next": {
          target: States.BeforeYouStart,
          actions: "setFlow",
        },
      },
    },
    [States.StormDamageFenceClaim]: {
      tags: ["sharedFence", "nonSharedFence"],
      on: {
        "stormDamagedFenceClaim.next": {
          target: States.BeforeYouStart,
          actions: "setFenceFlow",
        },
        "back.stormDamageClaim": {
          target: States.StormDamageClaim,
          actions: {
            type: "updateContext",
            params: { flow: undefined, multiplePolicies: undefined, occupancyType: undefined, repairType: undefined },
          },
        },
      },
    },
    [States.BeforeYouStart]: {
      tags: ["sharedFence", "nonSharedFence", "contents"],
      on: {
        "beforeYouStart.next": {
          target: States.ContactDetails,
          actions: "updateMultiplePoliciesAndOccupancyType",
        },
        "back.stormDamageClaim": {
          target: States.StormDamageClaim,
          actions: {
            type: "updateContext",
            params: { flow: undefined, multiplePolicies: undefined, occupancyType: undefined, repairType: undefined },
          },
        },
        "back.stormDamagedFenceClaim": {
          guard: "containsFenceFlow",
          target: States.StormDamageFenceClaim,
          actions: {
            type: "updateContext",
            params: {
              fenceFlow: undefined,
              multiplePolicies: undefined,
              occupancyType: undefined,
              repairType: undefined,
            },
          },
        },
      },
    },
    [States.ContactDetails]: {
      tags: ["sharedFence", "nonSharedFence", "contents"],
      on: {
        "contactDetails.next": [
          { guard: "multiplePolicies", target: States.YourPolicy },
          { target: States.StartYourClaim },
        ],
      },
    },
    [States.YourPolicy]: {
      tags: ["sharedFence", "nonSharedFence", "contents"],
      on: {
        "yourPolicy.next": {
          target: States.StartYourClaim,
          actions: "updateOccupancyType",
        },
        "back.contactDetails": States.ContactDetails,
      },
    },
    [States.StartYourClaim]: {
      tags: ["sharedFence", "nonSharedFence", "contents"],
      on: {
        "startYourClaim.error": States.StartYourClaimError,
        "startYourClaim.next": [
          { guard: "containsBuildingFlow", target: States.YourExcess, actions: "setClaimCreated" },
          { guard: "containsContentsFlow", target: States.YourExcess, actions: "setClaimCreated" },
          { target: States.InvoiceQuote, actions: "setClaimCreated" },
        ],
        "back.contactDetails": States.ContactDetails,
        "back.yourPolicy": {
          guard: "multiplePolicies",
          target: States.YourPolicy,
        },
      },
    },
    [States.YourExcess]: {
      tags: ["building", "contents"],
      on: {
        "yourExcess.next": [
          { guard: "containsBuildingFlow", target: States.BuildingDamage },
          { guard: "containsContentsFlow", target: States.ContentsDamage },
        ],
        "back.contactDetails": States.ContactDetails,
        "back.yourPolicy": {
          guard: "multiplePolicies",
          target: States.YourPolicy,
        },
        "back.startYourClaim": States.StartYourClaim,
      },
    },
    [States.BuildingDamage]: {
      tags: ["building"],
      on: {
        "contactDetails.next": [{ target: States.StartYourClaim }],
        "buildingDamage.next.toSpecificDamage": States.SpecificDamage,
        "buildingDamage.next.toOther": [
          {
            guard: "investmentProperty",
            target: States.FixturesAndFittings,
          },
          States.BuildingWaterDamage,
        ],
        "back.contactDetails": States.ContactDetails,
        "back.yourPolicy": {
          guard: "multiplePolicies",
          target: States.YourPolicy,
        },
        "back.startYourClaim": States.StartYourClaim,
        "back.yourExcess": States.YourExcess,
      },
    },
    [States.SpecificDamage]: {
      tags: ["building"],
      on: {
        "specificDamage.next": [
          { guard: "investmentProperty", target: States.FixturesAndFittings },
          { target: States.BuildingWaterDamage },
        ],
        "back.contactDetails": States.ContactDetails,
        "back.yourPolicy": {
          guard: "multiplePolicies",
          target: States.YourPolicy,
        },
        "back.startYourClaim": States.StartYourClaim,
        "back.yourExcess": States.YourExcess,
        "back.buildingDamage": States.BuildingDamage,
      },
    },
    [States.FixturesAndFittings]: {
      tags: ["building"],
      on: {
        "fixturesAndFittings.next": States.BuildingWaterDamage,
        "back.contactDetails": States.ContactDetails,
        "back.yourPolicy": {
          guard: "multiplePolicies",
          target: States.YourPolicy,
        },
        "back.startYourClaim": States.StartYourClaim,
        "back.yourExcess": States.YourExcess,
        "back.buildingDamage": States.BuildingDamage,
        "back.specificDamage": States.SpecificDamage,
      },
    },

    [States.BuildingWaterDamage]: {
      tags: ["building"],
      on: {
        "buildingWaterDamage.next": States.SafetyChecks,
        "back.contactDetails": States.ContactDetails,
        "back.yourPolicy": {
          guard: "multiplePolicies",
          target: States.YourPolicy,
        },
        "back.startYourClaim": States.StartYourClaim,
        "back.yourExcess": States.YourExcess,
        "back.buildingDamage": States.BuildingDamage,
        "back.specificDamage": States.SpecificDamage,
        "back.fixturesAndFittings": { guard: "investmentProperty", target: States.FixturesAndFittings },
      },
    },

    [States.SafetyChecks]: {
      tags: ["building"],
      on: {
        "safetyChecks.next": States.EndOfBuildingFlow,
        "back.contactDetails": States.ContactDetails,
        "back.yourPolicy": {
          guard: "multiplePolicies",
          target: States.YourPolicy,
        },
        "back.startYourClaim": States.StartYourClaim,
        "back.yourExcess": States.YourExcess,
        "back.buildingDamage": States.BuildingDamage,
        "back.specificDamage": States.SpecificDamage,
        "back.fixturesAndFittings": { guard: "investmentProperty", target: States.FixturesAndFittings },
        "back.buildingWaterDamage": States.BuildingWaterDamage,
      },
    },

    [States.ContentsDamage]: {
      tags: ["contents"],
      on: {
        "contentsDamage.next": [
          {
            guard: "contentsWaterDamageAndCantDry",
            target: States.EndOfContentsFlow,
            actions: "setContentsWaterDamageState",
          },
          { target: States.ContentsList, actions: "setContentsWaterDamageState" },
        ],
        "back.contactDetails": States.ContactDetails,
        "back.yourPolicy": {
          guard: "multiplePolicies",
          target: States.YourPolicy,
        },
        "back.startYourClaim": States.StartYourClaim,
        "back.yourExcess": States.YourExcess,
      },
    },
    [States.ContentsList]: {
      tags: ["contents"],
      on: {
        "contentsList.next": [States.EndOfContentsFlow],
        "back.contactDetails": States.ContactDetails,
        "back.yourPolicy": {
          guard: "multiplePolicies",
          target: States.YourPolicy,
        },
        "back.startYourClaim": States.StartYourClaim,
        "back.yourExcess": States.YourExcess,
        "back.contentsDamage": [
          {
            guard: and(["containsContentsFlow", not("containsBuildingFlow")]),
            target: States.ContentsDamage,
          },
        ],
        "back.buildingDamage": { guard: "containsBuildingFlow", target: States.BuildingDamage },
        "back.specificDamage": { guard: "containsBuildingFlow", target: States.SpecificDamage },
        "back.fixturesAndFittings": {
          guard: and(["investmentProperty", "containsBuildingFlow"]),
          target: States.FixturesAndFittings,
        },
        "back.buildingWaterDamage": { guard: "containsBuildingFlow", target: States.BuildingWaterDamage },
        "back.safetyChecks": { guard: "containsBuildingFlow", target: States.SafetyChecks },
      },
    },
    [States.InvoiceQuote]: {
      tags: ["sharedFence", "nonSharedFence"],
      on: {
        "invoiceQuote.next.noHaveRepairQuote": {
          actions: "setNoHaveRepairQuote",
          target: States.YourDamagedFence,
        },
        "invoiceQuote.next.haveRepairQuote": {
          actions: "setHaveRepairQuote",
          target: States.SafetyAndSecurity,
        },
        "invoiceQuote.next.repairsCompleted": {
          actions: "setRepairsCompleted",
          target: States.Upload,
        },
        "back.contactDetails": States.ContactDetails,
        "back.yourPolicy": {
          guard: "multiplePolicies",
          target: States.YourPolicy,
        },
        "back.startYourClaim": States.StartYourClaim,
        "back.yourExcess": { target: States.YourExcess, guard: "containsContentsFlow" },
        "back.contentsDamage": {
          target: States.ContentsDamage,
          guard: and(["containsContentsFlow", not("containsBuildingFlow")]),
        },
        "back.contentsList": { target: States.ContentsList, guard: "containsContentsFlow" },
        "back.buildingDamage": { guard: "containsBuildingFlow", target: States.BuildingDamage },
        "back.specificDamage": { guard: "containsBuildingFlow", target: States.SpecificDamage },
        "back.fixturesAndFittings": {
          guard: and(["investmentProperty", "containsBuildingFlow"]),
          target: States.FixturesAndFittings,
        },
        "back.buildingWaterDamage": { guard: "containsBuildingFlow", target: States.BuildingWaterDamage },
        "back.safetyChecks": { guard: "containsBuildingFlow", target: States.SafetyChecks },
      },
    },
    [States.YourDamagedFence]: {
      tags: ["sharedFence", "nonSharedFence"],
      on: {
        "yourDamagedFence.next": States.SafetyAndSecurity,
        "back.contactDetails": States.ContactDetails,
        "back.yourPolicy": {
          guard: "multiplePolicies",
          target: States.YourPolicy,
        },
        "back.startYourClaim": States.StartYourClaim,
        "back.invoiceQuote": States.InvoiceQuote,
        "back.yourExcess": { target: States.YourExcess, guard: "containsContentsFlow" },
        "back.contentsDamage": {
          target: States.ContentsDamage,
          guard: and(["containsContentsFlow", not("containsBuildingFlow")]),
        },
        "back.contentsList": { target: States.ContentsList, guard: "containsContentsFlow" },
        "back.buildingDamage": { guard: "containsBuildingFlow", target: States.BuildingDamage },
        "back.specificDamage": { guard: "containsBuildingFlow", target: States.SpecificDamage },
        "back.fixturesAndFittings": {
          guard: and(["investmentProperty", "containsBuildingFlow"]),
          target: States.FixturesAndFittings,
        },
        "back.buildingWaterDamage": { guard: "containsBuildingFlow", target: States.BuildingWaterDamage },
        "back.safetyChecks": { guard: "containsBuildingFlow", target: States.SafetyChecks },
      },
    },
    [States.SafetyAndSecurity]: {
      tags: ["sharedFence", "nonSharedFence"],
      on: {
        "safetyAndSecurity.next": [
          {
            guard: "noHaveRepairQuote",
            target: States.EndOfFenceFlow,
          },
          { target: States.Upload },
        ],
        "back.contactDetails": States.ContactDetails,
        "back.yourPolicy": {
          guard: "multiplePolicies",
          target: States.YourPolicy,
        },
        "back.startYourClaim": States.StartYourClaim,
        "back.invoiceQuote": States.InvoiceQuote,
        "back.yourDamagedFence": {
          guard: "noHaveRepairQuote",
          target: States.YourDamagedFence,
        },
        "back.yourExcess": { target: States.YourExcess, guard: "containsContentsFlow" },
        "back.contentsDamage": {
          target: States.ContentsDamage,
          guard: and(["containsContentsFlow", not("containsBuildingFlow")]),
        },
        "back.contentsList": { target: States.ContentsList, guard: "containsContentsFlow" },
        "back.buildingDamage": { guard: "containsBuildingFlow", target: States.BuildingDamage },
        "back.specificDamage": { guard: "containsBuildingFlow", target: States.SpecificDamage },
        "back.fixturesAndFittings": {
          guard: and(["investmentProperty", "containsBuildingFlow"]),
          target: States.FixturesAndFittings,
        },
        "back.buildingWaterDamage": { guard: "containsBuildingFlow", target: States.BuildingWaterDamage },
        "back.safetyChecks": { guard: "containsBuildingFlow", target: States.SafetyChecks },
      },
    },
    [States.Upload]: {
      tags: ["sharedFence", "nonSharedFence"],
      on: {
        "upload.next": States.EndOfFenceFlow,
        "back.contactDetails": States.ContactDetails,
        "back.yourPolicy": {
          guard: "multiplePolicies",
          target: States.YourPolicy,
        },
        "back.startYourClaim": States.StartYourClaim,
        "back.invoiceQuote": States.InvoiceQuote,
        "back.safetyAndSecurity": {
          guard: "haveRepairQuote",
          target: States.SafetyAndSecurity,
        },
        "back.yourExcess": { target: States.YourExcess, guard: "containsContentsFlow" },
        "back.contentsDamage": {
          target: States.ContentsDamage,
          guard: and(["containsContentsFlow", not("containsBuildingFlow")]),
        },
        "back.contentsList": { target: States.ContentsList, guard: "containsContentsFlow" },
        "back.buildingDamage": { guard: "containsBuildingFlow", target: States.BuildingDamage },
        "back.specificDamage": { guard: "containsBuildingFlow", target: States.SpecificDamage },
        "back.fixturesAndFittings": {
          guard: and(["investmentProperty", "containsBuildingFlow"]),
          target: States.FixturesAndFittings,
        },
        "back.buildingWaterDamage": { guard: "containsBuildingFlow", target: States.BuildingWaterDamage },
        "back.safetyChecks": { guard: "containsBuildingFlow", target: States.SafetyChecks },
      },
    },
    [States.EndOfBuildingFlow]: {
      always: [
        { guard: "containsContentsFlow", target: States.ContentsList },
        { guard: "containsFenceFlow", target: States.InvoiceQuote },
        { target: States.ReviewYourClaim },
      ],
    },
    [States.EndOfContentsFlow]: {
      always: [{ guard: "containsFenceFlow", target: States.InvoiceQuote }, { target: States.ReviewYourClaim }],
    },
    [States.EndOfFenceFlow]: {
      always: States.ReviewYourClaim,
    },
    [States.ReviewYourClaim]: {
      tags: ["sharedFence", "nonSharedFence", "contents", "building"],
      on: {
        "reviewClaim.submit": {
          actions: "setClaimSubmitted",
        },
        "reviewClaim.next.settlement": [
          {
            guard: "sharedFence",
            target: States.CashSettlement,
            actions: "setCashSettlement",
          },
          {
            target: States.SettlementOptions,
            actions: "setCashSettlement",
          },
        ],
        "reviewClaim.next.confirmation": States.Confirmation,
        "back.contactDetails": {
          target: States.ContactDetails,
          guard: not("claimSubmitted"),
        },
        "back.yourPolicy": {
          target: States.YourPolicy,
          guard: and(["multiplePolicies", not("claimSubmitted")]),
        },
        "back.startYourClaim": {
          target: States.StartYourClaim,
          guard: not("claimSubmitted"),
        },
        "back.invoiceQuote": {
          target: States.InvoiceQuote,
          guard: not("claimSubmitted"),
        },
        "back.safetyAndSecurity": {
          target: States.SafetyAndSecurity,
          guard: and(["noHaveRepairQuoteOrHaveRepairQuote", not("claimSubmitted")]),
        },
        "back.upload": {
          target: States.Upload,
          guard: and(["haveRepairQuoteOrRepairsCompleted", not("claimSubmitted")]),
        },
        "back.yourDamagedFence": {
          target: States.YourDamagedFence,
          guard: and(["noHaveRepairQuote", not("claimSubmitted")]),
        },
        "back.yourExcess": {
          target: States.YourExcess,
          guard: and(["containsBuildingOrContentsFlow", not("claimSubmitted")]),
        },
        "back.buildingDamage": {
          target: States.BuildingDamage,
          guard: and(["containsBuildingFlow", not("claimSubmitted")]),
        },
        "back.specificDamage": {
          target: States.SpecificDamage,
          guard: and(["containsBuildingFlow", not("claimSubmitted")]),
        },
        "back.safetyChecks": {
          target: States.SafetyChecks,
          guard: and(["containsBuildingFlow", not("claimSubmitted")]),
        },
        "back.contentsDamage": {
          target: States.ContentsDamage,
          guard: and(["containsContentsFlow", not("containsBuildingFlow"), not("claimSubmitted")]),
        },
        "back.contentsList": {
          target: States.ContentsList,
          guard: and(["containsContentsFlow", not("claimSubmitted")]),
        },
        "back.fixturesAndFittings": {
          guard: and(["investmentProperty", "containsBuildingFlow", not("claimSubmitted")]),
          target: States.FixturesAndFittings,
        },
        "back.buildingWaterDamage": {
          guard: and(["containsBuildingFlow", not("claimSubmitted")]),
          target: States.BuildingWaterDamage,
        },
      },
    },
    [States.CashSettlement]: {
      tags: ["sharedFence"],
      on: {
        "cashSettlement.next.acceptCash": {
          target: States.BankDetails,
          actions: { type: "updateCashSettlementState", params: { acceptCash: true, seeFactSheet: false } },
        },
        "cashSettlement.next.getQuote": {
          target: States.Confirmation,
          actions: { type: "updateCashSettlementState", params: { acceptCash: false, seeFactSheet: false } },
        },
      },
    },
    [States.SettlementOptions]: {
      tags: ["nonSharedFence"],
      on: {
        "settlementOptions.next.fixFence": States.Confirmation,
        "settlementOptions.next.allOptions": {
          target: States.CashSettlementFactSheet,
          actions: { type: "updateCashSettlementState", params: { acceptCash: false, seeFactSheet: true } },
        },
      },
    },
    [States.CashSettlementFactSheet]: {
      tags: ["nonSharedFence"],
      on: {
        "cashSettlementFactSheet.next.acceptCash": {
          target: States.BankDetails,
          actions: { type: "updateCashSettlementState", params: { acceptCash: true, seeFactSheet: true } },
        },
        "cashSettlementFactSheet.next.confirmation": States.Confirmation,
      },
    },
    [States.BankDetails]: {
      tags: ["sharedFence", "nonSharedFence"],
      on: { "bankDetails.next": States.Confirmation },
    },
    [States.Confirmation]: {
      tags: ["sharedFence", "nonSharedFence", "contents"],
    },
    [States.SystemUnavailable]: { type: "final" },
    [States.YouCantMakeAClaim]: { type: "final" },
    [States.SessionTimeout]: { type: "final" },
    [States.StartYourClaimError]: {},
  },
  on: {
    "error.systemUnavailable": "." + States.SystemUnavailable,
    "error.sessionTimeout": "." + States.SessionTimeout,
    "error.youCantMakeAClaim": "." + States.YouCantMakeAClaim,
  },
});
