import { Checkbox, FormControlLabel } from "@mui/material";
import { fieldTouched, gtm } from "@racwa/analytics";
import { Controller, useFormContext } from "react-hook-form";

const formFieldName = "dontHaveBankDetails";
const formFieldLabel = "I'll add my bank details later.";

const NoBankDetailsInput = () => {
  const { control } = useFormContext();
  return (
    <Controller
      control={control}
      aria-label={formFieldName}
      name={formFieldName}
      render={({ field: { ref, ...inputProps }, fieldState: { error } }) => (
        <FormControlLabel
          control={
            <Checkbox
              {...inputProps}
              sx={{ marginLeft: "0.25em" }}
              id={`${formFieldName}-checkbox`}
              data-testid={`${formFieldName}-checkbox`}
              checked={!!inputProps.value}
              onClick={() => gtm(fieldTouched(formFieldLabel))}
            />
          }
          label={formFieldLabel}
        />
      )}
    />
  );
};

export default NoBankDetailsInput;
