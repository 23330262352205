import { Flow } from "@/shared/stateMachine";
import { StateMachineContext } from "@/shared/stateMachine/hooks";
import { stateUrlLookup } from "@/shared/stateMachine/lookups";
import { event, fieldTouched, gtm } from "@racwa/analytics";
import { MultiChoiceOption } from "raci-react-library";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { HomeClaimSubheading } from "../../constants";
import { StormDamageClaimFormProps, StormDamageClaimState } from "../types";

export enum WhatsBeenDamaged {
  Fence = "Fence",
  Building = "Building",
  Contents = "Contents",
}

export const options: MultiChoiceOption[] = [
  {
    key: WhatsBeenDamaged.Building,
    label: "My building",
    sublabel:
      "Could be things like walls, ceilings, roof, windows, doors, hard floors (and carpets if this is an investment property)",
  },
  {
    key: WhatsBeenDamaged.Contents,
    label: "My contents",
    sublabel: "Could be things like carpets, furniture, computers, appliances, clothing",
  },
  {
    key: WhatsBeenDamaged.Fence,
    label: "My fence",
  },
];

const useStormDamageClaim = (): StormDamageClaimFormProps => {
  const navigate = useNavigate();

  const subheading = HomeClaimSubheading.StormDamageClaim;

  const form = useForm<StormDamageClaimState>({
    mode: "onTouched",
    reValidateMode: "onChange",
  });

  const actor = StateMachineContext.useActorRef();
  const onSubmit = (state: StormDamageClaimState) => {
    const selectedKeys = state.selectedDamage.map((op) => op.key);

    const flows = selectedKeys.map((key: string) => {
      switch (key) {
        case WhatsBeenDamaged.Fence:
          return "fence" as const as Flow;
        case WhatsBeenDamaged.Building:
          return "building" as const as Flow;
        case WhatsBeenDamaged.Contents:
          return "contents" as const as Flow;

        default:
          throw new Error(`Unknown flow: ${key}`);
      }
    });

    if (selectedKeys.includes(WhatsBeenDamaged.Fence)) {
      actor.send({ type: "stormDamageClaim.next.fence", flow: flows });
    } else {
      actor.send({ type: "stormDamageClaim.next", flow: flows });
    }
    navigate(stateUrlLookup[actor.getSnapshot().value]);
  };

  const onSelectionChange = (option: MultiChoiceOption) => {
    gtm(event(`${subheading} - ${option.label}`));
    gtm(fieldTouched(subheading));
  };

  return { form, subheading, options, onSubmit, onSelectionChange };
};

export default useStormDamageClaim;
