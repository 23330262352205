import { FormQuestions } from "@/shared/types";
import { ContentsDamageRequestCantDryCarpets } from "raci-claims-home-general-damage-clientproxy";
import { YesNoImNotSure } from "raci-react-library";
import { ContentsDamageFormValues } from "./types";

export const waterDamageToCarpetsName = "waterDamageToCarpets";
export const cantDryCarpetsName = "cantDryCarpets";

export const ContentsDamageQuestions: FormQuestions<ContentsDamageFormValues> = {
  waterDamageToCarpets: {
    id: waterDamageToCarpetsName,
    label: "Is there water damage to any carpet in the home?",
    name: waterDamageToCarpetsName,
  },
  cantDryCarpets: {
    id: cantDryCarpetsName,
    label: "Are the carpets so badly soaked that you can't dry them?",
    name: cantDryCarpetsName,
  },
};

type YesNoImNotSureMap<T> = Record<YesNoImNotSure, T>;

export const CantDryCarpetsYesNoImNotSure = {
  [YesNoImNotSure.Yes]: ContentsDamageRequestCantDryCarpets.Yes,
  [YesNoImNotSure.No]: ContentsDamageRequestCantDryCarpets.No,
  [YesNoImNotSure.ImNotSure]: ContentsDamageRequestCantDryCarpets.ImNotSure,
} as const satisfies YesNoImNotSureMap<ContentsDamageRequestCantDryCarpets>;
