import { type Pages } from "@/App";
import { Path } from "react-router-dom";
import { IndividualFlows, States, type BackEvents } from ".";

export const stateUrlLookup = {
  [States.StormDamageClaim]: "/claims/home/general/storm-damage-claim",
  [States.StormDamageFenceClaim]: "/claims/home/general/storm-damaged-fence-claim",
  [States.BeforeYouStart]: "/claims/home/general/before-you-start",
  [States.ContactDetails]: "/claims/home/general/contact-details",
  [States.YourPolicy]: "/claims/home/general/your-policy",
  [States.StartYourClaim]: "/claims/home/general/start-your-claim",
  [States.StartYourClaimError]: "/claims/home/general/start-your-claim",
  [States.InvoiceQuote]: "/claims/home/general/invoice-quote",
  [States.YourExcess]: "/claims/home/general/your-excess",
  [States.BuildingDamage]: "/claims/home/general/building-damage",
  [States.SpecificDamage]: "/claims/home/general/specific-damage",
  [States.FixturesAndFittings]: "/claims/home/general/fixtures-and-fittings",
  [States.BuildingWaterDamage]: "/claims/home/general/water-damage",
  [States.SafetyChecks]: "/claims/home/general/safety-checks",
  [States.ContentsDamage]: "/claims/home/general/contents-damage",
  [States.ContentsList]: "/claims/home/general/contents-list",
  [States.YourDamagedFence]: "/claims/home/general/your-damaged-fence",
  [States.SafetyAndSecurity]: "/claims/home/general/safety-and-security",
  [States.Upload]: "/claims/home/general/upload-your-invoice-quote",
  [States.ReviewYourClaim]: "/claims/home/general/review-your-claim",
  [States.CashSettlement]: "/claims/home/general/settlement",
  [States.SettlementOptions]: "/claims/home/general/settlement-options",
  [States.CashSettlementFactSheet]: "/claims/home/general/settlement",
  [States.BankDetails]: "/claims/home/general/your-bank-details",
  [States.Confirmation]: "/claims/home/general/confirmation",
  [States.SessionTimeout]: "/claims/home/general/408",
  [States.SystemUnavailable]: "/claims/home/general/error",
  [States.YouCantMakeAClaim]: "/claims/home/general/dont-have-a-policy",
  [States.EndOfBuildingFlow]: { hash: "#" },
  [States.EndOfContentsFlow]: { hash: "#" },
  [States.EndOfFenceFlow]: { hash: "#" },
} as const satisfies Record<States, Pages | Partial<Path>>;

export const urlBackEventLookup = (url: string): BackEvents | undefined => {
  const page = url as Pages;
  switch (page) {
    case "/claims/home/general/storm-damage-claim":
      return { type: "back.stormDamageClaim" };
    case "/claims/home/general/storm-damaged-fence-claim":
      return { type: "back.stormDamagedFenceClaim" };
    case "/claims/home/general/before-you-start":
      return { type: "back.beforeYouStart" };
    case "/claims/home/general/contact-details":
      return { type: "back.contactDetails" };
    case "/claims/home/general/your-policy":
      return { type: "back.yourPolicy" };
    case "/claims/home/general/start-your-claim":
      return { type: "back.startYourClaim" };
    case "/claims/home/general/invoice-quote":
      return { type: "back.invoiceQuote" };
    case "/claims/home/general/your-damaged-fence":
      return { type: "back.yourDamagedFence" };
    case "/claims/home/general/safety-and-security":
      return { type: "back.safetyAndSecurity" };
    case "/claims/home/general/upload-your-invoice-quote":
      return { type: "back.upload" };
    case "/claims/home/general/your-excess":
      return { type: "back.yourExcess" };
    case "/claims/home/general/building-damage":
      return { type: "back.buildingDamage" };
    case "/claims/home/general/specific-damage":
      return { type: "back.specificDamage" };
    case "/claims/home/general/fixtures-and-fittings":
      return { type: "back.fixturesAndFittings" };
    case "/claims/home/general/water-damage":
      return { type: "back.buildingWaterDamage" };
    case "/claims/home/general/safety-checks":
      return { type: "back.safetyChecks" };
    case "/claims/home/general/contents-damage":
      return { type: "back.contentsDamage" };
    case "/claims/home/general/contents-list":
      return { type: "back.contentsList" };
    default:
      return undefined;
  }
};

type FlowSchema = {
  key: string;
  name: string;
  path: string;
};

export const sharedFenceFlow = {
  key: "SharedFence",
  name: "Shared fence",
  path: "/shared-fence",
} as const satisfies FlowSchema;

export const nonSharedFenceFlow = {
  key: "NonSharedFence",
  name: "Non-shared fence",
  path: "/non-shared-fence",
} as const satisfies FlowSchema;

export const contentsFlow = {
  key: "Contents",
  name: "Contents",
  path: "/contents",
} as const satisfies FlowSchema;

export const buildingFlow = {
  key: "Building",
  name: "Building",
  path: "/building",
} as const satisfies FlowSchema;

export const flowLookup = {
  contents: contentsFlow,
  sharedFence: sharedFenceFlow,
  nonSharedFence: nonSharedFenceFlow,
  building: buildingFlow,
} as const satisfies Record<IndividualFlows, FlowSchema>;
