import { useFlowState } from "@/shared/stateMachine/hooks";
import {
  IconDefinition,
  faClock,
  faHouseChimney,
  faList,
  faPeopleArrowsLeftRight,
  faToolbox,
} from "@fortawesome/free-solid-svg-icons";
import { Divider, Grid, Typography } from "@mui/material";
import { colors } from "@racwa/styles";
import { AccordionCard, CardWithIcon } from "raci-react-library";
import { ReactNode } from "react";
import { AccordionContentTypography, TitleTypography } from "../styled";
import { buildingAccordionCardInfo } from "./Building";

export const BuildingAndFence: React.FC = () => {
  const { fenceFlow } = useFlowState();

  const cardInfo: { icon: IconDefinition; title: string; text?: ReactNode }[] = [
    { icon: faPeopleArrowsLeftRight, title: "Firstly, we hope you're okay." },
    {
      icon: faList,
      title: "Fill in the form to the best of your knowledge.",
    },
    {
      icon: faClock,
      title: "This may take around 10 minutes.",
    },
    {
      icon: faHouseChimney,
      title: "Your building",
      text: "This form is for both owner-occupied homes and investment properties.",
    },
  ];

  if (fenceFlow === "shared") {
    cardInfo.push({
      icon: faPeopleArrowsLeftRight,
      title: "Your shared fence",
      text: "Once your claim is accepted, you'll need to organise the repairs with your neighbour.",
    });
  } else if (fenceFlow === "nonShared") {
    cardInfo.push({
      icon: faToolbox,
      title: "Your non-shared fence",
      text: "You can organise your own fence repairs or we'll fix it for you.",
    });
  }

  return (
    <>
      {cardInfo.map((card) => (
        <Grid item xs={12} key={card.title}>
          <CardWithIcon
            border={false}
            spacing={2}
            sx={{ paddingLeft: "0px", paddingRight: "0px", color: colors.dieselDeep }}
            icon={card.icon}
            title={<TitleTypography>{card.title}</TitleTypography>}
            iconStyle={{ marginTop: "5px" }}
            paragraphs={card.text ? [<Typography sx={{ paddingLeft: "28px" }}>{card.text}</Typography>] : undefined}
          />
          <Divider />
        </Grid>
      ))}
      {buildingAccordionCardInfo.map((card, index) => (
        <Grid item xs={12} key={card.title}>
          <AccordionCard
            border={false}
            spacing={2}
            sx={{ paddingLeft: "0px", paddingRight: "0px", color: colors.dieselDeep }}
            icon={card.icon}
            title={<TitleTypography>{card.title}</TitleTypography>}
            iconStyle={{ marginTop: "5px" }}
            onClick={card.onClick}
          >
            <AccordionContentTypography>{card.content}</AccordionContentTypography>
          </AccordionCard>
          {index !== buildingAccordionCardInfo.length - 1 && <Divider />}
        </Grid>
      ))}
    </>
  );
};
