import { Link, Typography, useMediaQuery, useTheme } from "@mui/material";
import { event, fieldTouched, gtm } from "@racwa/analytics";
import { ConfirmationDialogBox } from "raci-react-library";

export interface CashSettlementDialogProps {
  showDialog: boolean;
  setShowDialog: (open: boolean) => void;
  onConfirm: () => void;
  onClickBreakdownLink?: () => void;
}

const CashSettlementDialog: React.FC<CashSettlementDialogProps> = ({
  showDialog,
  setShowDialog,
  onConfirm,
  onClickBreakdownLink,
}: CashSettlementDialogProps) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const headerSize = isSmallScreen ? "h2" : "h1";

  return (
    <ConfirmationDialogBox
      showDialog={showDialog}
      setShowDialog={setShowDialog}
      onConfirm={() => {
        gtm(fieldTouched("We'd like to confirm that you wish to accept the cash settlement"));
        gtm(event("We'd like to confirm that you wish to accept the cash settlement - Yes, accept"));
        onConfirm();
      }}
      title="We'd like to confirm that you wish to accept the cash settlement"
      titleVariant={headerSize}
      confirmText="Yes, accept"
      cancelText="No, go back"
      id="cash-settlement-confirmation-dialog"
      data-testid="cash-settlement-confirmation-dialog"
      onCancel={() => {
        gtm(fieldTouched("We'd like to confirm that you wish to accept the cash settlement"));
        gtm(event("We'd like to confirm that you wish to accept the cash settlement - No, go back"));
      }}
      content={
        <Typography>
          By accepting the cash settlement, I agree that I understand the {}
          <Link
            sx={{ cursor: "pointer" }}
            onClick={() => {
              gtm(event("cash settlement breakdown"));
              onClickBreakdownLink?.();
              setShowDialog(false);
            }}
          >
            cash settlement breakdown
          </Link>
          .
        </Typography>
      }
    />
  );
};

export default CashSettlementDialog;
