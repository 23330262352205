import { FormQuestions } from "@/shared/types";

export const uploadedFiles = "uploadedFiles";

export const UploadInvoiceQuote: FormQuestions<{ uploadedFiles: number }> = {
  uploadedFiles: {
    id: uploadedFiles,
    label: "Upload your",
    name: uploadedFiles,
  },
};
