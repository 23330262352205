import { useSessionTimeRemaining } from "raci-react-library";
import { useBffApiClient, useDocumentServiceApiClient } from "../useApiClient";

export const useOnResetExpiry = () => {
  const client = useBffApiClient();
  const documentServiceClient = useDocumentServiceApiClient();
  const { setTimeoutTime } = useSessionTimeRemaining();

  const resetSessionExpiry = async () => {
    const response = await client.resetSessionExpiry();
    await documentServiceClient.resetSessionExpiry();
    const expiry = parseInt(response.result);
    setTimeoutTime(new Date().getTime() + expiry * 1000);
  };
  return resetSessionExpiry;
};

export default useOnResetExpiry;
