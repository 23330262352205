import { Typography } from "@mui/material";
import { formatIdString } from "raci-react-library";
import { SubSectionWithLabelProps } from "../types";

export const SubSectionWithLabel = ({ label, content }: SubSectionWithLabelProps) => (
  <>
    <Typography variant="body2" id={`${formatIdString(label)}-label`}>
      <b>{label}</b>
    </Typography>
    <Typography variant="body2" id={`${formatIdString(label)}-content`}>
      {content}
    </Typography>
  </>
);
