import { useBffApiClient } from "@/shared/hooks/useApiClient";
import useErrorHandling from "@/shared/hooks/useErrorHandling";

import { OccupancyType } from "@/shared/stateMachine";
import { StateMachineContext } from "@/shared/stateMachine/hooks";
import { stateUrlLookup } from "@/shared/stateMachine/lookups";
import getPolicyOption from "@/shared/utils/getPolicyOption";
import { BeforeYouStartRoute } from "@/views/common/BeforeYouStart";
import { BeforeYouStartState } from "@/views/common/BeforeYouStart/types";
import { StartYourClaimRoute } from "@/views/common/StartYourClaim";
import { StartYourClaimState } from "@/views/common/StartYourClaim/types";
import { ClaimsHomeGeneralDamageApiException } from "raci-claims-home-general-damage-clientproxy";
import {
  HTTP_STATUS_CODE_CONTACT_SYNC_FAILURE,
  useGetSessionState,
  useSessionState,
  useSetBackdrop,
} from "raci-react-library";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { YourPolicyFormProps, YourPolicyFormValues, YourPolicyState } from "../../types";

export const useYourPolicy = (): YourPolicyFormProps => {
  const actor = StateMachineContext.useActorRef();
  const navigate = useNavigate();
  const setBackdrop = useSetBackdrop();
  const apiClient = useBffApiClient();
  const handleError = useErrorHandling();
  const [showPolicyNotListedDialog, setShowPolicyNotListedDialog] = useState(false);
  const [submitError, setSubmitError] = useState(false);
  const beforeYouStartState = useGetSessionState<BeforeYouStartState>(BeforeYouStartRoute.path);
  const [startYourClaimState, setStartYourClaimState] = useSessionState<StartYourClaimState>({
    specificKey: StartYourClaimRoute.path,
    skipPageTrackingRecalculation: true,
  });
  const claimNumber = startYourClaimState.claimNumber;
  const [yourPolicyState, setYourPolicyState] = useSessionState<YourPolicyState>({
    skipPageTrackingRecalculation: !!claimNumber,
  });

  const form = useForm<YourPolicyFormValues>({
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues: {},
  });

  const onSubmit: YourPolicyFormProps["onSubmit"] = async ({ policyNumber }) => {
    if (!policyNumber) {
      return setSubmitError(true);
    }

    try {
      setBackdrop(true);

      const policyDetailsAlreadyFetched =
        startYourClaimState.policyDetails?.policySummary?.policyNumber === policyNumber;

      let occupancyType = startYourClaimState.policyDetails?.occupancyType;

      if (!policyDetailsAlreadyFetched) {
        const response = await apiClient.getPolicy(policyNumber);
        setStartYourClaimState({
          ...startYourClaimState,
          ...response.result,
        });

        occupancyType = response.result.policyDetails?.occupancyType;
      }

      setYourPolicyState({ ...yourPolicyState, policyNumber, isCompleted: true });
      actor.send({
        type: "yourPolicy.next",
        occupancyType: occupancyType as OccupancyType,
      });
      navigate(stateUrlLookup[actor.getSnapshot().value]);
    } catch (e) {
      const error = e as ClaimsHomeGeneralDamageApiException;
      const errorHandlingProps = {
        message: "Error getting policy details",
        shouldRedirect: true,
        customProperties: { request: `GET /policy`, status: error.status, error: error.message },
      };

      handleError({
        ...errorHandlingProps,
        isMemberContactError: error.status === HTTP_STATUS_CODE_CONTACT_SYNC_FAILURE,
      });
    } finally {
      setBackdrop(false);
    }
  };

  let policyOptions = beforeYouStartState.policies?.map((policyDetails) => getPolicyOption(policyDetails)) ?? [];

  if (claimNumber) {
    policyOptions = policyOptions.filter((policy) => policy.policyNumber === yourPolicyState.policyNumber);
  }

  return {
    form,
    onSubmit,
    showPolicyNotListedDialog,
    setShowPolicyNotListedDialog,
    claimNumber,
    submitError,
    initialPolicyNumber: yourPolicyState.policyNumber ?? "",
    policyOptions,
  };
};

export default useYourPolicy;
