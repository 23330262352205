import { CallToActionButton } from "@/shared/components/CallToActionButton";
import PageTitle from "@/shared/components/PageTitle";
import { Grid } from "@mui/material";
import { event, fieldTouched, gtm } from "@racwa/analytics";
import { NotificationCard, ToggleButtonGroup, YesNoButtonGroup, YesNoImNotSure } from "raci-react-library";
import { FormProvider, useWatch } from "react-hook-form";
import { ContentsDamageQuestions, cantDryCarpetsName, waterDamageToCarpetsName } from "./constants";
import { getNotificationFlags } from "./helpers";
import { ContentsDamageFormProps } from "./types";

const { waterDamageToCarpets } = ContentsDamageQuestions;
const { cantDryCarpets } = ContentsDamageQuestions;

export const ContentsDamageForm = ({ form, onSubmit }: ContentsDamageFormProps) => {
  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
  } = form;

  const waterDamageToCarpetsWatch = useWatch({ control, name: waterDamageToCarpetsName });
  const cantDryCarpetsWatch = useWatch({ control, name: cantDryCarpetsName });

  const getNotificationCard = () => {
    const { isTakeItFromHere, isThanksForInformation } = getNotificationFlags({
      waterDamageToCarpets: waterDamageToCarpetsWatch,
      cantDryCarpets: cantDryCarpetsWatch,
    });

    const message = isTakeItFromHere
      ? {
          title: `We'll take it from here`,
          paragraphs: [
            "Once you submit your claim, we'll get a restorer to contact you. They'll assess your carpet and any other contents damage you may have.",
          ],
        }
      : isThanksForInformation
        ? {
            title: `Thanks for that information`,
            paragraphs: ["Next, you can tell us about any other damage you may have."],
          }
        : null;

    return (
      <Grid item xs={12} sx={{ paddingTop: "24px" }}>
        {message && <NotificationCard severity="info" message={message} />}
      </Grid>
    );
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)} action="#">
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <PageTitle id="header" title="Let's take care of your contents" showClaimNumber />
          </Grid>
          <Grid item xs={12}>
            <YesNoButtonGroup
              name={waterDamageToCarpets.name}
              label={waterDamageToCarpets.label}
              id={waterDamageToCarpets.id}
              data-testid={waterDamageToCarpets.id}
            />
          </Grid>
          {waterDamageToCarpetsWatch && (
            <Grid item xs={12}>
              <ToggleButtonGroup
                id={cantDryCarpets.id}
                data-testid={cantDryCarpets.id}
                name={cantDryCarpets.name}
                label={cantDryCarpets.label}
                options={YesNoImNotSure}
                errorMessage="Please select an answer"
                onChange={(_, value) => {
                  gtm(fieldTouched(cantDryCarpets.label));
                  gtm(event(`${cantDryCarpets.label} - ${value}`));
                }}
              />
            </Grid>
          )}
          {getNotificationCard()}
          <Grid item xs={12}>
            <CallToActionButton disabled={isSubmitting}>Next</CallToActionButton>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default ContentsDamageForm;
