import {
  faCircleQuestion,
  faClipboardList,
  faMoneyBillWave,
  faPeopleArrowsLeftRight,
  faRuler,
} from "@fortawesome/free-solid-svg-icons";
import { Divider, Grid } from "@mui/material";
import { event, gtm } from "@racwa/analytics";
import { colors } from "@racwa/styles";
import { AccordionCard, CardWithIcon } from "raci-react-library";
import { AccordionContentTypography, TitleTypography } from "../styled";

const cardInfo = [
  { icon: faPeopleArrowsLeftRight, title: "A shared fence is owned by you and your neighbour." },
  {
    icon: faMoneyBillWave,
    title:
      "We provide cash settlements for shared fences. We'll cover your share of the fence repairs if we accept your claim.",
  },
  {
    icon: faPeopleArrowsLeftRight,
    title: "You'll need to get a repair quote and organise the repairs with your neighbour.",
  },
  { icon: faRuler, title: "If you can, measure the damaged part of the fence in metres before you start." },
];

const accordionCardInfo = [
  {
    icon: faClipboardList,
    title: "What's the process for shared fence claims?",
    content: (
      <ol>
        <li>Make a claim.</li>
        <li>Talk to your neighbour. They're responsible for their share of the fence repair costs.</li>
        <li>Get a quote from a fence repairer.</li>
        <li>Pay for your share of the fence repair with your cash settlement.</li>
      </ol>
    ),
    onClick: () => gtm(event("What's the process for shared fence claims?")),
  },
  {
    icon: faCircleQuestion,
    title: "Why can't you just fix my fence?",
    content: (
      <>
        You and your neighbour share ownership of the fence. You're both responsible for the repairs so you'll need to
        talk to your neighbour to get your fence fixed.
      </>
    ),
    onClick: () => gtm(event("Why can't you just fix my fence?")),
  },
];

export const SharedFence: React.FC = () => {
  return (
    <>
      {cardInfo.map((card) => (
        <Grid item xs={12} key={card.title}>
          <CardWithIcon
            border={false}
            spacing={2}
            sx={{ paddingLeft: "0px", paddingRight: "0px", color: colors.dieselDeep }}
            icon={card.icon}
            title={<TitleTypography>{card.title}</TitleTypography>}
            iconStyle={{ marginTop: "5px" }}
          />
          <Divider />
        </Grid>
      ))}
      {accordionCardInfo.map((card, index) => (
        <Grid item xs={12} key={card.title}>
          <AccordionCard
            border={false}
            spacing={2}
            sx={{ paddingLeft: "0px", paddingRight: "0px", color: colors.dieselDeep }}
            icon={card.icon}
            title={<TitleTypography>{card.title}</TitleTypography>}
            iconStyle={{ marginTop: "5px" }}
            onClick={card.onClick}
          >
            <AccordionContentTypography>{card.content}</AccordionContentTypography>
          </AccordionCard>
          {index !== accordionCardInfo.length - 1 && <Divider />}
        </Grid>
      ))}
    </>
  );
};
