import { useFlowState } from "@/shared/stateMachine/hooks";
import { ContentsListRoute } from "@/views/contents/ContentsList";
import { ContentsListQuestions } from "@/views/contents/ContentsList/constants";
import { ContentsListFormValuesNoList, ContentsListState } from "@/views/contents/ContentsList/types";
import { useGetSessionState } from "raci-react-library";
import { FormSummary } from "../../types";

export const useGetContentsListSummary = (): FormSummary<ContentsListFormValuesNoList> => {
  const { anyOtherDamagedContents } = useGetSessionState<ContentsListState>(ContentsListRoute.path);
  const { isBuildingContentsAndFence, isBuildingAndContents } = useFlowState();

  return {
    anyOtherDamagedContents: {
      label: ContentsListQuestions.anyOtherDamagedContents.label.replace(
        isBuildingAndContents || isBuildingContentsAndFence ? "other " : "",
        "",
      ),
      content: anyOtherDamagedContents ? "Yes" : "No",
    },
  };
};
