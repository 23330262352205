import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { Button, Grid, Typography } from "@mui/material";
import { event, fieldTouched, gtm } from "@racwa/analytics";
import { colors } from "@racwa/styles";
import { IconWithText } from "raci-react-library";
import { StyledCard } from "./styled";

interface RepairsArrangedProps {
  onClick?: () => void;
}

export const RepairsArranged: React.FC<RepairsArrangedProps> = ({ onClick }) => {
  return (
    <>
      <Grid container item xs={12} spacing={1}>
        <IconWithText
          icon={faCircle}
          textLayer={
            <Typography sx={{ color: colors.white }} variant="h3">
              B
            </Typography>
          }
          text={<Typography variant="h3">Repairs arranged by RAC</Typography>}
          iconStyle={{ height: "28px", width: "28px" }}
        />
      </Grid>
      <StyledCard background="white">
        <Grid container>
          <Typography variant="body1" paragraph align="left">
            We'll select professional trades to complete the repairs.
          </Typography>

          <Typography variant="body1" paragraph align="left">
            We'll be in touch if you choose this option. If you have an excess, you can either pay the repairer or us.
          </Typography>
        </Grid>
        <Button
          fullWidth
          color="primary"
          onClick={() => {
            gtm(fieldTouched("Choose your claim settlement option"));
            gtm(event("Choose your claim settlement option - Choose RAC repairer"));
            onClick?.();
          }}
          data-testid="chooseRacRepairer"
        >
          Choose RAC repairer
        </Button>
      </StyledCard>
    </>
  );
};
