import {
  ADB2C_CLIENT_ID,
  BFF_API_BASE_URL,
  DOCUMENT_SERVICE_API_BASE_URL,
  LOOKUP_SERVICE_URL,
  MFA_OTP_SERVICE_API_BASE_URL,
} from "@/shared/constants";
import { ClaimsHomeGeneralDamageApiClient } from "raci-claims-home-general-damage-clientproxy";
import { DocumentServiceApiClient } from "raci-document-service-clientproxy";
import { LookupServiceApiClient } from "raci-lookup-service-clientproxy";
import { MfaOtpApiClient } from "raci-otp-service-clientproxy";
import { useFileUploadSessionKey, useSessionIdStorageKey, useSsoApiClient } from "raci-react-library";

export type ClientOptions = { fetch: (url: RequestInfo, init?: RequestInit) => Promise<Response> };

export interface UseApiClientProps<T> {
  Client: { new (baseUrl: string, http: ClientOptions): T };
  apiBaseUrl: string;
}

const useApi = <T>({ Client, apiBaseUrl }: UseApiClientProps<T>) => {
  const sessionIdStorageKey = useSessionIdStorageKey();
  return useSsoApiClient({
    Client,
    clientId: ADB2C_CLIENT_ID,
    apiBaseUrl,
    sessionIdStorageKey,
  });
};

export let useBffApiClient = () => {
  return useApi({
    Client: ClaimsHomeGeneralDamageApiClient,
    apiBaseUrl: BFF_API_BASE_URL,
  });
};

export let useLookupApiClient = () => {
  return useApi({
    Client: LookupServiceApiClient,
    apiBaseUrl: LOOKUP_SERVICE_URL,
  });
};

export let useMfaOtpServiceApiClient = () => {
  return useApi({
    Client: MfaOtpApiClient,
    apiBaseUrl: MFA_OTP_SERVICE_API_BASE_URL,
  });
};

export let useDocumentServiceApiClient = () => {
  const documentServiceSessionKey = useFileUploadSessionKey();

  return useSsoApiClient({
    Client: DocumentServiceApiClient,
    clientId: ADB2C_CLIENT_ID,
    apiBaseUrl: DOCUMENT_SERVICE_API_BASE_URL,
    sessionIdStorageKey: documentServiceSessionKey,
  });
};

/*
 ** WARNING: KNOW WHAT YOU'RE DOING BEFORE MAKING CHANGES TO
 ** TO THE BELOW IF STATEMENT.
 ** UPON A BUILD, REACT SCRIPT WILL REMOVE CODE IN
 ** THE OUTPUTTED JAVASCRIPT FILE DEPENDING ON THE TARGET
 ** ENVIRONMENT
 ** THIS MEANS THAT FOR LOCAL/DEV/SIT BUILDS WILL INCLUDE
 ** AND SHIP CODE (YOU CAN SEE AND SEARCH FOR IN OUTPUTTED
 ** JAVASCRIPT ARTIFACTS) FOR MICROSOFT AUTHENTICATION LIBRARY
 */
if (
  process.env.REACT_APP_ENVIRONMENT === "LOCAL" ||
  process.env.REACT_APP_ENVIRONMENT === "DEV" ||
  process.env.REACT_APP_ENVIRONMENT === "SIT" ||
  process.env.REACT_APP_ENVIRONMENT === "UAT"
) {
  const {
    useNpeBffApiClient,
    useNpeLookupApiClient,
    useNpeDocumentServiceApiClient,
    useNpeMfaOtpServiceApiClient,
  } = require("@/__NPE__/useApiClient");
  useBffApiClient = useNpeBffApiClient;
  useLookupApiClient = useNpeLookupApiClient;
  useDocumentServiceApiClient = useNpeDocumentServiceApiClient;
  useMfaOtpServiceApiClient = useNpeMfaOtpServiceApiClient;
}
