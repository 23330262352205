import { States } from "@/shared/stateMachine";
import { StateMachineContext } from "@/shared/stateMachine/hooks";
import { LinkProps } from "@mui/material";
import { FooterProps, RacwaResponsiveHeaderProps, RacwaStepperTemplateProps } from "@racwa/react-components";
import { EMPTY_URL } from "raci-react-library";
import useBreadcrumbs from "./useBreadcrumbs";

type CommonPageProps = Pick<
  RacwaStepperTemplateProps,
  | "breadcrumbs"
  | "responsiveHeaderProps"
  | "headerEndAction"
  | "notificationBannerContent"
  | "showNotificationBanner"
  | "footerProps"
>;

const getLinkProps = (link?: string): Partial<LinkProps> => ({
  href: link ?? EMPTY_URL,
});

export const usePage = (): CommonPageProps => {
  const state = StateMachineContext.useSelector((s) => s);

  const isActiveFlow =
    state.value !== States.StormDamageClaim &&
    state.value !== States.StormDamageFenceClaim &&
    state.value !== States.BeforeYouStart;

  const isDamagedTypeSelected = state.value !== States.StormDamageClaim && state.value !== States.StormDamageFenceClaim;

  const breadcrumbs = useBreadcrumbs(isActiveFlow, isDamagedTypeSelected);

  const responsiveHeaderProps: RacwaResponsiveHeaderProps = {
    NavBreadcrumbsProps: {
      homeLink: process.env.REACT_APP_RAC_HOME_PAGE ?? EMPTY_URL,
    },
  };

  const footerProps: FooterProps = {
    variant: "sidebar",
    privacyLinkProps: getLinkProps(process.env.REACT_APP_RAC_ABOUT_PRIVACY_PAGE),
    securityLinkProps: getLinkProps(process.env.REACT_APP_RAC_ABOUT_SECURITY_PAGE),
    disclaimerLinkProps: getLinkProps(process.env.REACT_APP_RAC_ABOUT_DISCLAIMER_PAGE),
    accessibilityLinkProps: getLinkProps(process.env.REACT_APP_RAC_ABOUT_ACCESSIBILITY_PAGE),
  };

  let notificationBannerContent;
  let headerEndAction;
  /*
   ** WARNING:
   ** KNOW WHAT YOU'RE DOING BEFORE MAKING CHANGES TO TO THE BELOW IF STATEMENT
   ** UPON A BUILD, REACT SCRIPT WILL REMOVE CODE IN THE OUTPUTTED JAVASCRIPT FILE DEPENDING ON THE TARGET ENVIRONMENT
   ** THIS MEANS THAT FOR LOCAL/DEV/SIT BUILDS WILL INCLUDE AND SHIP CODE
   ** (YOU CAN SEE AND SEARCH FOR IN OUTPUTTED JAVASCRIPT ARTIFACTS)
   */
  if (
    process.env.REACT_APP_ENVIRONMENT === "LOCAL" ||
    process.env.REACT_APP_ENVIRONMENT === "DEV" ||
    process.env.REACT_APP_ENVIRONMENT === "SIT" ||
    process.env.REACT_APP_ENVIRONMENT === "UAT"
  ) {
    const { HeaderButtons, TestBanner } = require("raci-react-library-npe");
    const { EnvironmentHealthStatus } = require("@/__NPE__/EnvironmentHealthStatus");

    notificationBannerContent = <TestBanner />;

    headerEndAction = (
      <HeaderButtons
        npeButtons={[<EnvironmentHealthStatus />]}
        isUat={process.env.REACT_APP_ENVIRONMENT === "UAT"}
        appConfig={{ showOverrideToNumberSelector: true }}
      />
    );
  }

  return {
    breadcrumbs,
    responsiveHeaderProps,
    headerEndAction,
    notificationBannerContent,
    showNotificationBanner: !!notificationBannerContent,
    footerProps,
  };
};

export default usePage;
