import useStepperPage from "@/shared/hooks/useStepperPage";
import { createPageTitle } from "@/shared/routing/TitleGuard";
import { RacwaStepperTemplate } from "@racwa/react-components";
import { RouteObject } from "react-router-dom";
import YourPolicyForm from "./Form";
import { useYourPolicy } from "./hooks/useYourPolicy";

export const pageName = "Your policy";

export const YourPolicy = () => {
  const props = useYourPolicy();
  const standardPageProps = useStepperPage();

  return (
    <RacwaStepperTemplate {...standardPageProps}>
      <YourPolicyForm {...props} />
    </RacwaStepperTemplate>
  );
};

export const YourPolicyRoute = {
  path: "your-policy",
  element: <YourPolicy />,
  loader: () => (document.title = createPageTitle(pageName)),
} as const satisfies RouteObject;

export default YourPolicy;
