import {
  STORAGE_KEY_HAS_ATTEMPTED_TO_ENTER_FLOW,
  STORAGE_KEY_LOGIN_REGISTER_REDIRECT_TRIGGERED,
  STORAGE_KEY_POLICY_NUMBER,
} from "@/shared/constants";
import { useBffApiClient } from "@/shared/hooks/useApiClient";
import useErrorHandling from "@/shared/hooks/useErrorHandling";
import { createPageTitle } from "@/shared/routing/TitleGuard";
import { OccupancyType } from "@/shared/stateMachine";
import { StateMachineContext, useFlowState } from "@/shared/stateMachine/hooks";
import { stateUrlLookup } from "@/shared/stateMachine/lookups";
import { StartYourClaimRoute } from "@/views/common/StartYourClaim";
import { StartYourClaimState } from "@/views/common/StartYourClaim/types";
import { YourPolicyRoute } from "@/views/common/YourPolicy";
import { YourPolicyState } from "@/views/common/YourPolicy/types";
import { useMsal } from "@azure/msal-react";
import { event, gtm, virtualPageView } from "@racwa/analytics";
import { ClaimsHomeGeneralDamageApiException } from "raci-claims-home-general-damage-clientproxy";
import {
  HTTP_STATUS_CODE_CONTACT_SYNC_FAILURE,
  HTTP_STATUS_CODE_NOT_FOUND,
  useHasSession,
  useIsLoggedInToMyRac,
  useSessionState,
  useSetBackdrop,
} from "raci-react-library";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { pageName } from "../..";
import { BeforeYouStartFormProps, BeforeYouStartState, RedirectType } from "../../types";

export const useBeforeYouStart = (): BeforeYouStartFormProps => {
  const navigate = useNavigate();
  const location = useLocation();
  const setBackdrop = useSetBackdrop();
  const apiClient = useBffApiClient();
  const isLoggedIn = useIsLoggedInToMyRac();
  const hasSession = useHasSession();
  const { getFlowUrl } = useFlowState();
  const handleError = useErrorHandling();
  const [userHasNavigated, setUserHasNavigated] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const actor = StateMachineContext.useActorRef();

  const { instance } = useMsal();

  const [beforeYouStartState, setBeforeYouStartState] = useSessionState<BeforeYouStartState>({
    skipPageTrackingRecalculation: true,
  });
  const [, setYourPolicyState] = useSessionState<YourPolicyState>({
    specificKey: YourPolicyRoute.path,
    skipPageTrackingRecalculation: true,
  });
  const [startYourClaimState, setStartYourClaimState] = useSessionState<StartYourClaimState>({
    specificKey: StartYourClaimRoute.path,
    skipPageTrackingRecalculation: true,
  });

  const policyNumber = sessionStorage.getItem(STORAGE_KEY_POLICY_NUMBER);

  const setPolicyNumber = (policyNumber: string) => {
    sessionStorage.setItem(STORAGE_KEY_POLICY_NUMBER, policyNumber);
    setYourPolicyState({
      policyNumber,
      isCompleted: true,
    });
  };

  const getPolicy = async (policyNumber: string) => {
    try {
      const policyDetailsAlreadyFetched =
        startYourClaimState.policyDetails?.policySummary?.policyNumber === policyNumber;

      let occupancyType = startYourClaimState.policyDetails?.occupancyType;

      if (!policyDetailsAlreadyFetched) {
        const response = await apiClient.getPolicy(policyNumber);
        setPolicyNumber(policyNumber);
        setStartYourClaimState({
          ...startYourClaimState,
          ...response.result,
        });

        occupancyType = response.result.policyDetails?.occupancyType;
      }

      setBeforeYouStartState({
        ...beforeYouStartState,
        isCompleted: true,
      });

      actor.send({
        type: "beforeYouStart.next",
        multiplePolicies: false,
        occupancyType: occupancyType as OccupancyType,
      });
      navigate(stateUrlLookup[actor.getSnapshot().value]);
    } catch (e) {
      const error = e as ClaimsHomeGeneralDamageApiException;
      sessionStorage.removeItem(STORAGE_KEY_POLICY_NUMBER);
      const errorHandlingProps = {
        message: "Error retrieving policy details",
        shouldRedirect: true,
        customProperties: { request: `GET /policy`, status: error.status, message: error.message },
      };

      handleError({
        ...errorHandlingProps,
        isMemberContactError: error.status === HTTP_STATUS_CODE_CONTACT_SYNC_FAILURE,
      });
    }
  };

  const redirectToNextPage = async () => {
    sessionStorage.setItem(STORAGE_KEY_HAS_ATTEMPTED_TO_ENTER_FLOW, true.toString());

    if (policyNumber) {
      return getPolicy(policyNumber);
    }

    let policies = beforeYouStartState.policies;

    try {
      if (!policies) {
        const response = await apiClient.getPolicies();
        policies = response.result.policies;
      }

      if (policies?.length === 1) {
        return getPolicy(policies[0]?.policySummary?.policyNumber ?? "");
      }

      setBeforeYouStartState({
        ...beforeYouStartState,
        policies,
        isCompleted: true,
      });

      actor.send({
        type: "beforeYouStart.next",
        multiplePolicies: true,
      });
      navigate(stateUrlLookup[actor.getSnapshot().value]);
    } catch (ex) {
      const error = ex as ClaimsHomeGeneralDamageApiException;
      const errorHandlingProps = {
        message: "Error retrieving policies",
        shouldRedirect: true,
        customProperties: { request: `GET /policies`, status: error.status, message: error.message },
      };

      if (error.status === HTTP_STATUS_CODE_NOT_FOUND) {
        actor.send({ type: "error.youCantMakeAClaim" });
        navigate(stateUrlLookup[actor.getSnapshot().value]);
      } else {
        handleError({
          ...errorHandlingProps,
          isMemberContactError: error.status === HTTP_STATUS_CODE_CONTACT_SYNC_FAILURE,
        });
      }
    }
  };

  const handleRedirect = async (redirectType: RedirectType) => {
    setBackdrop(true);
    setDisabled(true);
    setUserHasNavigated(true);

    if (redirectType === RedirectType.Next) {
      await redirectToNextPage();
      setUserHasNavigated(false);
    } else {
      sessionStorage.setItem(STORAGE_KEY_LOGIN_REGISTER_REDIRECT_TRIGGERED, true.toString());
      const eventDescription = redirectType === RedirectType.Register ? "Register now" : "Log in and claim";
      gtm(event(eventDescription));
      setUserHasNavigated(false);
      await instance.loginRedirect({ redirectUri: process.env.REACT_APP_ADB2C_RETURN_URL, scopes: [] });
    }

    setDisabled(false);
    setBackdrop(false);
  };

  // We do not want to record page view when it is redirected
  useEffect(() => {
    const loginRegisterRedirectTriggered = !!sessionStorage.getItem(STORAGE_KEY_LOGIN_REGISTER_REDIRECT_TRIGGERED);
    const hasAttemptedToEnterFlow = !!sessionStorage.getItem(STORAGE_KEY_HAS_ATTEMPTED_TO_ENTER_FLOW);
    if (!userHasNavigated && (!loginRegisterRedirectTriggered || hasAttemptedToEnterFlow)) {
      const virtualPageViewUrl = `${location.pathname.toLocaleLowerCase()}${getFlowUrl()}`;
      gtm(virtualPageView({ url: virtualPageViewUrl, title: createPageTitle(pageName) }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  // Redirect to next page automatically if returning from login / linking
  useEffect(() => {
    const loginRegisterRedirectTriggered = !!sessionStorage.getItem(STORAGE_KEY_LOGIN_REGISTER_REDIRECT_TRIGGERED);
    const hasAttemptedToEnterFlow = !!sessionStorage.getItem(STORAGE_KEY_HAS_ATTEMPTED_TO_ENTER_FLOW);
    const isReturningFromLoginOrLinking = loginRegisterRedirectTriggered && !hasAttemptedToEnterFlow;

    if (isLoggedIn && hasSession && isReturningFromLoginOrLinking) {
      handleRedirect(RedirectType.Next);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn, hasSession]);

  return {
    isLoggedIn,
    disabled,
    handleRedirect,
  };
};

export default useBeforeYouStart;
