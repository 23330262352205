import { flowCompletedAtom } from "@/shared/atoms";
import { useBffApiClient, useDocumentServiceApiClient } from "@/shared/hooks/useApiClient";
import useErrorHandling from "@/shared/hooks/useErrorHandling";
import { StateMachineContext, useFlowState } from "@/shared/stateMachine/hooks";
import { stateUrlLookup } from "@/shared/stateMachine/lookups";
import { BuildingDamageRoute } from "@/views/building/BuildingDamage";
import { BuildingDamageState } from "@/views/building/BuildingDamage/types";
import { FixturesAndFittingsRoute } from "@/views/building/FixturesAndFittings";
import { FixturesAndFittingsState } from "@/views/building/FixturesAndFittings/types";
import { SafetyChecksRoute } from "@/views/building/SafetyChecks";
import { SafetyChecksState } from "@/views/building/SafetyChecks/types";
import { SpecificDamageRoute } from "@/views/building/SpecificDamage";
import { SpecificDamageState } from "@/views/building/SpecificDamage/types";
import { WaterDamageRoute } from "@/views/building/WaterDamage";
import { WaterDamageState } from "@/views/building/WaterDamage/types";
import { ConfirmationRoute } from "@/views/common/Confirmation";
import { ConfirmationState } from "@/views/common/Confirmation/types";
import { InvoiceQuoteRoute } from "@/views/common/InvoiceQuote";
import { InvoiceQuoteState } from "@/views/common/InvoiceQuote/types";
import { SettlementRoute } from "@/views/common/Settlement";
import { SettlementState } from "@/views/common/Settlement/types";
import { StartYourClaimRoute } from "@/views/common/StartYourClaim";
import { StartYourClaimState } from "@/views/common/StartYourClaim/types";
import { ContentsDamageRoute } from "@/views/contents/ContentsDamage";
import { ContentsDamageState } from "@/views/contents/ContentsDamage/types";
import { ContentsListRoute } from "@/views/contents/ContentsList";
import { ContentsListState } from "@/views/contents/ContentsList/types";
import { YourSettlementOptionsRoute } from "@/views/nonSharedFence/YourSettlementOptions";
import { YourSettlementOptionsState as NonSharedFenceSettlementState } from "@/views/nonSharedFence/YourSettlementOptions/types";
import {
  ClaimsHomeGeneralDamageApiException,
  ReviewYourClaimResponseClaimCondition,
} from "raci-claims-home-general-damage-clientproxy";
import {
  currencyFormatter,
  useFileUpload,
  useGetSessionState,
  useSessionState,
  useSetBackdrop,
} from "raci-react-library";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { ReviewYourClaimFormProps, ReviewYourClaimState, SummaryDetails } from "../../types";
import { useGetBuildingDamageSummary } from "../useGetBuildingDamageSummary";
import { useGetBuildingWaterDamageSummary } from "../useGetBuildingWaterDamageSummary";
import { useGetContactDetailsSummary } from "../useGetContactDetailsSummary";
import { useGetContentsDamageSummary } from "../useGetContentsDamageSummary";
import { useGetContentsListSummary } from "../useGetContentsListSummary";
import { useGetFixturesAndFittingsSummary } from "../useGetFixturesAndFittingsSummary";
import { useGetInvoiceQuoteSummary } from "../useGetInvoiceQuoteSummary";
import { useGetSafetyAndSecuritySummary } from "../useGetSafetyAndSecuritySummary";
import { useGetSafetyChecksSummary } from "../useGetSafetyChecksSummary";
import { useGetSpecificDamageSummary } from "../useGetSpecificDamageSummary";
import { useGetStartYourClaimSummary } from "../useGetStartYourClaimSummary";
import { useGetUploadInvoiceQuoteSummary } from "../useGetUploadInvoiceQuoteSummary";
import { useGetYourFenceSummary } from "../useGetYourFenceSummary";

export const useReviewYourClaim = (): ReviewYourClaimFormProps => {
  const navigate = useNavigate();
  const setBackdrop = useSetBackdrop();
  const apiClient = useBffApiClient();
  const handleError = useErrorHandling();
  const [, setYourSettlementOptionsState] = useSessionState<SettlementState>({
    specificKey: SettlementRoute.path,
    skipPageTrackingRecalculation: true,
  });
  const [nonSharedFenceSettlementOptionsState, setNonSharedFenceSettlementOptionsState] =
    useSessionState<NonSharedFenceSettlementState>({
      specificKey: YourSettlementOptionsRoute.path,
      skipPageTrackingRecalculation: true,
    });
  const [, setConfirmationState] = useSessionState<ConfirmationState>({
    specificKey: ConfirmationRoute.path,
    skipPageTrackingRecalculation: true,
  });
  const buildingDamageState = useGetSessionState<BuildingDamageState>(BuildingDamageRoute.path);
  const specificDamageState = useGetSessionState<SpecificDamageState>(SpecificDamageRoute.path);
  const fixturesAndFittingsState = useGetSessionState<FixturesAndFittingsState>(FixturesAndFittingsRoute.path);
  const buildingWaterDamageState = useGetSessionState<WaterDamageState>(WaterDamageRoute.path);
  const safetyChecksState = useGetSessionState<SafetyChecksState>(SafetyChecksRoute.path);
  const contentsDamageState = useGetSessionState<ContentsDamageState>(ContentsDamageRoute.path);
  const contentsListState = useGetSessionState<ContentsListState>(ContentsListRoute.path);
  const [reviewYourClaimState, setReviewYourClaimState] = useSessionState<ReviewYourClaimState>({
    skipPageTrackingRecalculation: true,
  });
  const { policyDetails, excess } = useGetSessionState<StartYourClaimState>(StartYourClaimRoute.path);
  const {
    isRepairCompleted,
    hasRepairQuote,
    isCompleted: isInvoiceQuoteCompleted,
  } = useGetSessionState<InvoiceQuoteState>(InvoiceQuoteRoute.path);
  const startYourClaimSummary = useGetStartYourClaimSummary();
  const contactDetailsSummary = useGetContactDetailsSummary();
  const yourFenceSummary = useGetYourFenceSummary();
  const invoiceQuoteSummary = useGetInvoiceQuoteSummary();
  const uploadInvoiceQuoteSummary = useGetUploadInvoiceQuoteSummary();
  const safetyAndSecuritySummary = useGetSafetyAndSecuritySummary();
  const contentsDamageSummary = useGetContentsDamageSummary();
  const contentsListSummary = useGetContentsListSummary();
  const buildingDamageSummary = useGetBuildingDamageSummary();
  const specificDamageSummary = useGetSpecificDamageSummary();
  const fixturesAndFittingsSummary = useGetFixturesAndFittingsSummary();
  const buildingWaterDamageSummary = useGetBuildingWaterDamageSummary();
  const safetyChecksSummary = useGetSafetyChecksSummary();
  const {
    flow,
    fenceFlow,
    isFenceOnly,
    isBuildingOnly,
    isContentsOnly,
    isContentsAndFence,
    isBuildingAndFence,
    isBuildingAndContents,
    isBuildingContentsAndFence,
  } = useFlowState();
  const actor = StateMachineContext.useActorRef();
  const setFlowCompleted = useSetRecoilState(flowCompletedAtom);
  const redirectToSystemUnavailable = () => handleError({ message: "File upload error", shouldRedirect: true });

  const documentServiceApiClient = useDocumentServiceApiClient();
  const { previouslyUploadedFiles, onSubmitFiles } = useFileUpload(
    documentServiceApiClient,
    undefined,
    redirectToSystemUnavailable,
  );

  const onSubmit: ReviewYourClaimFormProps["onSubmit"] = async () => {
    try {
      if (!flow) throw new Error("Flow is undefined");
      setBackdrop(true);
      actor.send({
        type: "reviewClaim.submit",
      });
      const { result } = await apiClient.reviewYourClaim({});
      const isEligibleForSettlement =
        result.claimCondition === ReviewYourClaimResponseClaimCondition.EligibleForSettlement;
      const paymentBlock = result.claimCondition === ReviewYourClaimResponseClaimCondition.PaymentBlock;

      if (previouslyUploadedFiles.length > 0 && (isRepairCompleted || hasRepairQuote)) {
        await onSubmitFiles();
      }

      setReviewYourClaimState({ ...reviewYourClaimState, isCompleted: true });
      setConfirmationState({
        assignedServiceProviders: result.serviceProviders,
        paymentBlock: paymentBlock,
        claimCondition: result.claimCondition,
      });

      // Ineligible claims should never be able to visit settlement options pages, for both non shared and shared fence
      setYourSettlementOptionsState({
        ...result,
        isEligibleForSettlement,
        isCompleted: !isEligibleForSettlement,
      });

      // We have to complete the non-shared settlement options page for shared fence, otherwise navigate to incomplete page will kick in
      setNonSharedFenceSettlementOptionsState({
        ...nonSharedFenceSettlementOptionsState,
        isCompleted: !isEligibleForSettlement || fenceFlow !== "nonShared",
      });

      if (hasRepairQuote || isRepairCompleted || !isEligibleForSettlement) {
        setFlowCompleted(true);

        actor.send({
          type: "reviewClaim.next.confirmation",
        });
        navigate(stateUrlLookup[actor.getSnapshot().value]);
      } else {
        actor.send({
          type: "reviewClaim.next.settlement",
        });

        navigate(stateUrlLookup[actor.getSnapshot().value]);
      }
    } catch (exception) {
      const error = exception as ClaimsHomeGeneralDamageApiException;
      handleError({
        message: "Error on review your claim",
        shouldRedirect: true,
        customProperties: {
          request: "PATCH step/review-your-claim",
          status: error.status,
          error: error.message,
        },
      });
    } finally {
      setBackdrop(false);
    }
  };

  if (!policyDetails) {
    handleError({
      message: "Policy details not found",
      shouldRedirect: true,
    });
  }

  const form = useForm<ReviewYourClaimState>({
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues: reviewYourClaimState,
  });

  const getDamageTypeContent = () => {
    if (isFenceOnly) {
      return fenceFlow === "shared" ? "Storm, shared fence" : "Storm, non-shared fence";
    }

    if (isBuildingOnly) {
      return "Storm, building";
    }

    if (isContentsOnly) {
      return "Storm, contents";
    }

    if (isContentsAndFence) {
      return "Storm, contents and fence";
    }

    if (isBuildingAndFence) {
      return fenceFlow === "shared" ? "Storm, building and shared fence" : "Storm, building and non-shared fence";
    }

    if (isBuildingAndContents) {
      return "Storm, building and contents";
    }

    if (isBuildingContentsAndFence) {
      return fenceFlow === "shared"
        ? "Storm, building, contents and shared fence"
        : "Storm, building, contents and non-shared fence";
    }
  };

  const damageTypeContent = getDamageTypeContent();

  const summaryDetails: SummaryDetails = {
    yourClaim: {
      damageType: { label: "Damage type", content: damageTypeContent },
      excess: {
        label: "Your excess",
        content: currencyFormatter(excess ?? 0, { withDecimals: true, stripZeroCents: true }),
      },
    },
    contactDetails: contactDetailsSummary,
    invoiceQuote: invoiceQuoteSummary,
    safetyAndSecurity: safetyAndSecuritySummary,
    startYourClaim: startYourClaimSummary,
    yourFence: yourFenceSummary,
    uploadInvoiceQuote: uploadInvoiceQuoteSummary,
    contentsDamage: contentsDamageSummary,
    contentsList: contentsListSummary,
    buildingDamage: buildingDamageSummary,
    specificDamage: specificDamageSummary,
    fixturesAndFittings: fixturesAndFittingsSummary,
    buildingWaterDamage: buildingWaterDamageSummary,
    safetyChecks: safetyChecksSummary,
  };

  const isYourDamagedFenceCompleted = isRepairCompleted === false && hasRepairQuote === false;
  const isUploadInvoiceQuoteCompleted = (isRepairCompleted || hasRepairQuote) ?? false;
  const isSafetyAndSecurityCompleted = isRepairCompleted === false;

  return {
    form,
    policyDetails: policyDetails!,
    summaryDetails,
    contentsList: contentsListState.contentsList,
    isContentsListCompleted: contentsListState.isCompleted ?? false,
    isBuildingDamageCompleted: buildingDamageState.isCompleted ?? false,
    isSpecificDamageCompleted: specificDamageState.isCompleted ?? false,
    isFixturesAndFittingsCompleted: fixturesAndFittingsState.isCompleted ?? false,
    isBuildingWaterDamageCompleted: buildingWaterDamageState.isCompleted ?? false,
    isSafetyChecksCompleted: safetyChecksState.isCompleted ?? false,
    isInvoiceQuoteCompleted: isInvoiceQuoteCompleted ?? false,
    isContentsDamagedCompleted: contentsDamageState.isCompleted ?? false,
    isYourDamagedFenceCompleted,
    isSafetyAndSecurityCompleted,
    isUploadInvoiceQuoteCompleted,
    onSubmit,
  };
};

export default useReviewYourClaim;
