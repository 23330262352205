import { useBffApiClient } from "@/shared/hooks/useApiClient";
import useErrorHandling from "@/shared/hooks/useErrorHandling";
import { StateMachineContext } from "@/shared/stateMachine/hooks";
import { stateUrlLookup } from "@/shared/stateMachine/lookups";
import { ClaimsHomeGeneralDamageApiException } from "raci-claims-home-general-damage-clientproxy";
import { useSessionState, useSetBackdrop } from "raci-react-library";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { SafetyAndSecurityFormProps, SafetyAndSecurityFormValues, SafetyAndSecurityState } from "../../types";

export const useSafetyAndSecurity = (): SafetyAndSecurityFormProps => {
  const actor = StateMachineContext.useActorRef();
  const navigate = useNavigate();
  const apiClient = useBffApiClient();
  const setBackdrop = useSetBackdrop();
  const handleError = useErrorHandling();
  // If there is no repair quote, we need to skip the recalculation because the page will be marked as completed
  // If we don't skip the recalc, the page will lose it's completed status and cause a loop
  const [safetyAndSecurityState, setSafetyAndSecurityState] = useSessionState<SafetyAndSecurityState>({
    skipPageTrackingRecalculation: true,
  });

  const form = useForm<SafetyAndSecurityFormValues>({
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues: safetyAndSecurityState,
  });

  const onSubmit = async (values: SafetyAndSecurityFormValues) => {
    try {
      setBackdrop(true);
      setSafetyAndSecurityState({
        ...values,
        isCompleted: true,
      });
      await apiClient.fenceSafetyAndSecurity({ temporaryFenceRequired: values.isSafetyIssue ?? false });
      actor.send({ type: "safetyAndSecurity.next" });
      navigate(stateUrlLookup[actor.getSnapshot().value]);
    } catch (exception) {
      const error = exception as ClaimsHomeGeneralDamageApiException;
      handleError({
        message: "Error submitting safety and security",
        shouldRedirect: true,
        customProperties: {
          request: "POST /step/fence-safety-and-security",
          status: error.status,
          error: error.message,
        },
      });
    } finally {
      setBackdrop(false);
    }
  };

  return {
    form,
    onSubmit,
  };
};

export default useSafetyAndSecurity;
