import { useMediaQuery, useTheme } from "@mui/material";
import { event, fieldTouched, gtm } from "@racwa/analytics";
import { ConfirmationDialogBox } from "raci-react-library";

export interface ConfirmRacRepairerDialogProps {
  showDialog: boolean;
  setShowDialog: (open: boolean) => void;
  onConfirm: () => void;
}

const ConfirmRacRepairerDialog: React.FC<ConfirmRacRepairerDialogProps> = ({
  showDialog,
  setShowDialog,
  onConfirm,
}: ConfirmRacRepairerDialogProps) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const headerSize = isSmallScreen ? "h2" : "h1";

  return (
    <ConfirmationDialogBox
      showDialog={showDialog}
      setShowDialog={setShowDialog}
      onConfirm={() => {
        onConfirm();
        gtm(fieldTouched("Please confirm that you'd like an RAC repairer"));
        gtm(event("Please confirm that you'd like an RAC repairer - Yes, accept"));
      }}
      titleVariant={headerSize}
      title="Please confirm that you'd like an RAC repairer"
      confirmText="Yes, accept"
      cancelText="No, go back"
      id="rac-repairer-confirmation-dialog"
      data-testid="rac-repairer-confirmation-dialog"
      onCancel={() => {
        gtm(fieldTouched("Please confirm that you'd like an RAC repairer"));
        gtm(event("Please confirm that you'd like an RAC repairer - No, go back"));
      }}
      content={<></>}
    />
  );
};

export default ConfirmRacRepairerDialog;
