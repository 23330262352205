import { FormQuestions } from "@/shared/types";
import { SelectedBuildingDamageLevels } from "raci-claims-home-general-damage-clientproxy";
import { BuildingDamageFormValues } from "./types";

export const damageLevelsName = "damageLevels";

export const BuildingDamageOptionsQuestions = {
  damageLevels: {
    id: "selected-damage",
    name: damageLevelsName,
    label: "",
  },
} as const satisfies FormQuestions<BuildingDamageFormValues>;

export const BuildingDamageLevelsTitles = {
  [SelectedBuildingDamageLevels.Level1]: "Level 1: Specific items have been damaged",
  [SelectedBuildingDamageLevels.Level2]: "Level 2: My home has been damaged",
  [SelectedBuildingDamageLevels.Level3]: "Level 3: My home isn't safe",
} as const satisfies Record<SelectedBuildingDamageLevels, string>;
