import { PolicyDetailsResponse } from "raci-claims-home-general-damage-clientproxy";
import { SessionState } from "raci-react-library";

export enum RedirectType {
  Login = "login",
  Next = "next",
  Register = "register",
}

export interface BeforeYouStartFormProps extends React.PropsWithChildren {
  isLoggedIn: boolean;
  disabled: boolean;
  handleRedirect: (redirectType: RedirectType) => Promise<void>;
}

export type BeforeYouStartState = SessionState<{
  policies: PolicyDetailsResponse[];
}>;
