import { faEnvelope, faPhone, faToolbox, faUserAlt } from "@fortawesome/free-solid-svg-icons";
import { Grid, Typography } from "@mui/material";
import { CardWithIcon } from "raci-react-library";
import { cardContainerStyles } from "../../ConfirmationHeader/styles";
import { NextStepsCardProps } from "./types";

const NextStepsCard: React.FC<NextStepsCardProps> = ({ variant }) => {
  const getTextForIfNotUploaded = (document: "quote" | "invoice") => (
    <Typography variant="body1">
      If you haven't uploaded your {document}, you'll need to do so. The email will explain how.
    </Typography>
  );

  const getResponseTimeSentenceFor = (document: "quote" | "invoice") => (
    <Typography variant="body1">
      Once we've received your {document}, we'll let you know the next steps within ten business days.
    </Typography>
  );

  const invoiceQuoteParagraphs = [
    <Typography variant="body1">
      If you have, we'll contact you within ten business days to let you know the next steps.
    </Typography>,
  ];

  const getIcon = () => {
    if (
      variant.type === "AcceptCashSettlement" ||
      variant.type === "SettlementDeclinedForRacRepairs" ||
      variant.type === "ContentsRestorerMakeSafe"
    ) {
      return faToolbox;
    }

    if (variant.type === "SettlementDeclinedForContactMe") {
      return faPhone;
    }

    if (variant.type === "SettlementDeclinedForTakeTimeToDecide") {
      return faEnvelope;
    }

    return faUserAlt;
  };

  const getParagraphs = () => {
    switch (variant.type) {
      case "AcceptCashSettlement":
        return [
          <Typography variant="body1">After you receive the cash, you can organise your fence repairs.</Typography>,
          <Typography variant="body1">
            If the cash amount doesn't cover your costs, please send us your quote. We'll review the amount and discuss
            your options.
          </Typography>,
        ];

      case "NotEligibleForCashSettlement":
      case "CashSettlementDeclinedToGetRepairQuote":
        return [
          <Typography variant="body1">
            We'll leave you to get a quote for your fence. Then you can send it to us.
          </Typography>,
          <Typography variant="body1">{getResponseTimeSentenceFor("quote")}</Typography>,
        ];

      case "HasRepairQuote":
        return [getTextForIfNotUploaded("quote"), ...invoiceQuoteParagraphs];

      case "HasRepairInvoice":
        return [getTextForIfNotUploaded("invoice"), ...invoiceQuoteParagraphs];

      case "NotEligibleCashSettlementDueToWoodOrBrick": {
        const text = [
          <Typography variant="body1">
            One of our allocated builders will contact you within two business days to assess your fence.
          </Typography>,
        ];

        if (variant.isSharedFence) {
          text.push(
            <Typography variant="body1">You'll still need to organise your own fence repairs after this.</Typography>,
          );
        }

        return text;
      }

      case "SettlementDeclinedForRacRepairs":
        return [
          <Typography variant="body1">
            You've chosen to have your fence repaired. One of our RAC repairers will contact you within two business
            days to assess your fence.
          </Typography>,
        ];

      case "SettlementDeclinedForContactMe":
        return [
          <Typography variant="body1">We'll call you within one business day to discuss your options.</Typography>,
        ];

      case "SettlementDeclinedForTakeTimeToDecide":
        return [
          <Typography variant="body1">
            We'll email you so you can take more time to decide how you'd like to settle your claim.
          </Typography>,
        ];

      case "ContentsRestorerMakeSafe":
        return [<Typography variant="body1">We've assigned a repairer to assess your damage.</Typography>];

      case "ContentsList":
        return [
          <Typography variant="body1">
            We'll contact you within one business day. We'll also start assessing your claim.
          </Typography>,
        ];

      case "ContentsPaymentBlockAllocationFailure":
      case "ExcessHigherThanRepairCost":
      case "ClearSomethingUp":
        return undefined;
    }
  };

  const getTitle = () => {
    switch (variant.type) {
      case "SettlementDeclinedForContactMe":
      case "SettlementDeclinedForRacRepairs":
      case "SettlementDeclinedForTakeTimeToDecide":
      case "ContentsList":
      case "ContentsRestorerMakeSafe":
        return "Next steps";

      default:
        return "Your next steps";
    }
  };

  const paragraphs = getParagraphs();

  return (
    paragraphs && (
      <Grid item xs={12} sx={cardContainerStyles}>
        <CardWithIcon
          icon={getIcon()}
          title={<Typography variant="h3">{getTitle()}</Typography>}
          iconStyle={{ height: "24px", width: "24px" }}
          paragraphs={paragraphs}
          data-testid="next-steps"
        />
      </Grid>
    )
  );
};

export default NextStepsCard;
