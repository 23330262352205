import useStepperPage from "@/shared/hooks/useStepperPage";
import { createPageTitle } from "@/shared/routing/TitleGuard";
import { RacwaStepperTemplate } from "@racwa/react-components";
import { RouteObject } from "react-router-dom";
import WaterDamageForm from "./Form";
import useWaterDamage from "./hooks/useWaterDamage";

export const pageName = "Water damage";

export const WaterDamage = () => {
  const props = useWaterDamage();
  const stepperProps = useStepperPage();

  return (
    <RacwaStepperTemplate {...stepperProps}>
      <WaterDamageForm {...props} />
    </RacwaStepperTemplate>
  );
};

export const WaterDamageRoute = {
  path: "water-damage",
  element: <WaterDamage />,
  loader: () => (document.title = createPageTitle(pageName)),
} as const satisfies RouteObject;

export default WaterDamage;
