import styled from "@emotion/styled";
import { Grid } from "@mui/material";

export const StyledGridChooseOptions = styled(Grid)({
  "& h3": {
    fontSize: "18px",
    fontWeight: 400,
  },
  marginTop: "8px",
});
