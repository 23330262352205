import { ButtonProps, Grid, Link } from "@mui/material";
import { event, fieldTouched, gtm } from "@racwa/analytics";
import { RegisterNowTypography, StyledButton, StyledGrid, SubHeadingContainer, SubHeadingTypography } from "./styled";
import { BeforeYouStartFormProps, RedirectType } from "./types";

const submitButtonProps: ButtonProps = {
  type: "button",
  color: "primary",
  variant: "contained",
  fullWidth: true,
};

const eventLoginClaimRegister = "Log in and claim, Register now or Next";

export const BeforeYouStartForm: React.FC<BeforeYouStartFormProps> = ({
  isLoggedIn,
  disabled,
  handleRedirect,
  children,
}) => {
  return (
    <Grid container alignItems="center" justifyContent="center" direction="column">
      <SubHeadingContainer item xs={12}>
        <SubHeadingTypography variant="h2">Before you start</SubHeadingTypography>
      </SubHeadingContainer>

      <StyledGrid container item spacing={1} justifyContent="center">
        {children}
        {isLoggedIn ? (
          <Grid item xs={12}>
            <StyledButton
              {...submitButtonProps}
              onClick={() => {
                gtm(fieldTouched(eventLoginClaimRegister));
                gtm(event("Next"));
                handleRedirect(RedirectType.Next);
              }}
              disabled={disabled}
              data-testid="next"
            >
              Next
            </StyledButton>
          </Grid>
        ) : (
          <>
            <Grid item xs={12}>
              <StyledButton
                {...submitButtonProps}
                onClick={() => {
                  gtm(fieldTouched(eventLoginClaimRegister));
                  handleRedirect(RedirectType.Login);
                }}
                disabled={disabled}
                data-testid="login-register"
              >
                Log in and claim
              </StyledButton>
            </Grid>
            <Grid item xs={12}>
              <RegisterNowTypography>
                Don't have an account?{" "}
                <Link
                  href="#"
                  rel="noreferrer noopener"
                  onClick={() => {
                    gtm(fieldTouched(eventLoginClaimRegister));
                    handleRedirect(RedirectType.Register);
                  }}
                >
                  Register now
                </Link>
              </RegisterNowTypography>
            </Grid>
          </>
        )}
      </StyledGrid>
    </Grid>
  );
};

export default BeforeYouStartForm;
