import useStepperPage from "@/shared/hooks/useStepperPage";
import { createPageTitle } from "@/shared/routing/TitleGuard";
import { RacwaStepperTemplate } from "@racwa/react-components";
import { RouteObject } from "react-router-dom";
import ContentsListForm from "./Form";
import useContentsList from "./hooks/useContentsList";

export const pageName = "Contents list";

export const ContentsList = () => {
  const props = useContentsList();
  const stepperProps = useStepperPage();

  return (
    <RacwaStepperTemplate {...stepperProps}>
      <ContentsListForm {...props} />
    </RacwaStepperTemplate>
  );
};

export const ContentsListRoute = {
  path: "contents-list",
  element: <ContentsList />,
  loader: () => (document.title = createPageTitle(pageName)),
} as const satisfies RouteObject;

export default ContentsList;
