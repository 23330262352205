import { Box, Typography, styled } from "@mui/material";

export const StyledBox = styled(Box)({
  paddingTop: "8px",
});

export const StyledTypography = styled(Typography)({
  paddingTop: "8px",
  fontWeight: 400,
});
