import useStepperPage from "@/shared/hooks/useStepperPage";
import { createPageTitle } from "@/shared/routing/TitleGuard";
import { RacwaStepperTemplate } from "@racwa/react-components";
import { RouteObject } from "react-router-dom";
import ContentsDamageForm from "./Form";
import useContentsDamage from "./hooks/useContentsDamage";

export const pageName = "Contents damage";

export const ContentsDamage = () => {
  const props = useContentsDamage();
  const stepperProps = useStepperPage();

  return (
    <RacwaStepperTemplate {...stepperProps}>
      <ContentsDamageForm {...props} />
    </RacwaStepperTemplate>
  );
};

export const ContentsDamageRoute = {
  path: "contents-damage",
  element: <ContentsDamage />,
  loader: () => (document.title = createPageTitle(pageName)),
} as const satisfies RouteObject;

export default ContentsDamage;
