import useStepperPage from "@/shared/hooks/useStepperPage";
import { createPageTitle } from "@/shared/routing/TitleGuard";
import { RacwaStepperTemplate } from "@racwa/react-components";
import { RouteObject } from "react-router-dom";
import { StartYourClaimForm } from "./Form";
import useStartYourClaim from "./hooks/useStartYourClaim";

export const pageName = "Start your claim";

export const StartYourClaim = () => {
  const props = useStartYourClaim();
  const stepperProps = useStepperPage();

  return (
    <RacwaStepperTemplate {...stepperProps}>
      <StartYourClaimForm {...props} />
    </RacwaStepperTemplate>
  );
};

export const StartYourClaimRoute = {
  path: "start-your-claim",
  element: <StartYourClaim />,
  loader: () => (document.title = createPageTitle(pageName)),
} as const satisfies RouteObject;

export default StartYourClaim;
