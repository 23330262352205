import { useStepperPage } from "@/shared/hooks/useStepperPage";
import { createPageTitle } from "@/shared/routing/TitleGuard";
import { RacwaStepperTemplate } from "@racwa/react-components";
import { RouteObject } from "react-router-dom";
import { ConfirmationForm } from "./Form";
import { useConfirmation } from "./hooks/useConfirmation";

export const pageName = "Confirmation";

export const Confirmation: React.FC = () => {
  const { ...props } = useConfirmation();
  const stepperProps = useStepperPage();

  return (
    <RacwaStepperTemplate {...stepperProps}>
      <ConfirmationForm {...props} />
    </RacwaStepperTemplate>
  );
};

export const ConfirmationRoute = {
  path: "confirmation",
  element: <Confirmation />,
  loader: () => (document.title = createPageTitle(pageName)),
} as const satisfies RouteObject;

export default Confirmation;
