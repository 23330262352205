import { event, gtm } from "@racwa/analytics";
import { NotificationCard } from "raci-react-library";
import { memo } from "react";

const notificationCardTitle = "Looks like there's no contents damage to claim for";

const NoContentsInfoCard = memo(() => {
  gtm(event(notificationCardTitle));
  return (
    <NotificationCard
      severity="info"
      message={{
        title: notificationCardTitle,
        paragraphs: ["Please check your answers or select 'Next' to continue."],
      }}
    />
  );
});

export default NoContentsInfoCard;
