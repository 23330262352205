import useErrorHandling from "@/shared/hooks/useErrorHandling";

import { useSessionState, useSetBackdrop } from "raci-react-library";

import { useBffApiClient } from "@/shared/hooks/useApiClient";
import { StateMachineContext } from "@/shared/stateMachine/hooks";
import { stateUrlLookup } from "@/shared/stateMachine/lookups";
import {
  ClaimsHomeGeneralDamageApiException,
  WaterDamageProblems,
  WaterDamageRequest,
} from "raci-claims-home-general-damage-clientproxy";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import {
  BuildingWaterDamageFormProps,
  BuildingWaterDamageFormValues,
  WaterDamageState as BuildingWaterDamageState,
} from "../../types";

export const useWaterDamage = (): BuildingWaterDamageFormProps => {
  const actor = StateMachineContext.useActorRef();
  const isInvestmentPropertyOrContentsFlow = StateMachineContext.useSelector(
    (state) =>
      (state.context.occupancyType === "Investment property" || state.context.flow?.includes("contents")) ?? false,
  );
  const navigate = useNavigate();
  const apiClient = useBffApiClient();
  const setBackdrop = useSetBackdrop();
  const handleError = useErrorHandling();
  const [buildingWaterDamageState, setBuildingWaterDamageState] = useSessionState<BuildingWaterDamageState>({
    skipPageTrackingRecalculation: true,
  });

  const form = useForm<BuildingWaterDamageFormValues>({
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues: buildingWaterDamageState,
  });

  const getRequest = (values?: BuildingWaterDamageFormValues): WaterDamageRequest => {
    return {
      waterDamageProblems: values?.waterDamageProblems.map((problem) => problem.key as WaterDamageProblems),
    };
  };

  const onSubmit: BuildingWaterDamageFormProps["onSubmit"] = async (values?: BuildingWaterDamageFormValues) => {
    try {
      setBackdrop(true);

      await apiClient.waterDamage(getRequest(values));

      setBuildingWaterDamageState({
        ...buildingWaterDamageState,
        ...values,
        isCompleted: true,
      });

      actor.send({
        type: "buildingWaterDamage.next",
      });

      navigate(stateUrlLookup[actor.getSnapshot().value]);
    } catch (exception) {
      const error = exception as ClaimsHomeGeneralDamageApiException;
      handleError({
        message: "Error submitting water damage",
        shouldRedirect: true,
        customProperties: {
          request: "POST /step/water-damage",
          status: error.status,
          error: error.message,
        },
      });
    } finally {
      setBackdrop(false);
    }
  };

  return {
    form,
    onSubmit,
    isInvestmentPropertyOrContentsFlow,
  };
};

export default useWaterDamage;
