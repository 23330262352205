import { flowCompletedAtom } from "@/shared/atoms";
import { useBffApiClient } from "@/shared/hooks/useApiClient";
import useErrorHandling from "@/shared/hooks/useErrorHandling";
import { StateMachineContext } from "@/shared/stateMachine/hooks";
import { stateUrlLookup } from "@/shared/stateMachine/lookups";
import { SettlementRoute } from "@/views/common/Settlement";
import { SettlementState } from "@/views/common/Settlement/types";
import {
  SettlementOption,
  YourSettlementOptionsFormProps,
  YourSettlementOptionsFormValues,
  YourSettlementOptionsState,
} from "@/views/nonSharedFence/YourSettlementOptions/types";
import {
  ClaimsHomeGeneralDamageApiException,
  SettlementRequest,
  SettlementRequestDecision,
} from "raci-claims-home-general-damage-clientproxy";
import { useSessionState, useSetBackdrop } from "raci-react-library";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { YourSettlementOptionsRoute } from "../..";

export const useYourSettlementOptions = (): YourSettlementOptionsFormProps => {
  const actor = StateMachineContext.useActorRef();
  const navigate = useNavigate();
  const apiClient = useBffApiClient();
  const setBackdrop = useSetBackdrop();
  const handleError = useErrorHandling();
  const setFlowCompleted = useSetRecoilState(flowCompletedAtom);

  const [settlementState, setSettlementState] = useSessionState<SettlementState>({
    specificKey: SettlementRoute.path,
    skipPageTrackingRecalculation: true,
  });

  const [nonSharedSettlementOptionsState, setNonSharedSettlementOptionsState] =
    useSessionState<YourSettlementOptionsState>({
      specificKey: YourSettlementOptionsRoute.path,
      skipPageTrackingRecalculation: true,
    });

  const form = useForm<YourSettlementOptionsFormValues>({
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues: nonSharedSettlementOptionsState,
  });

  const onAcceptRacRepairer = async (): Promise<void> => {
    try {
      setBackdrop(true);

      const response = await apiClient.settlement({
        decision: SettlementRequestDecision.DeclinedForRacRepairs,
        wasSettlementOffered: false,
      } as SettlementRequest);

      setNonSharedSettlementOptionsState({
        ...nonSharedSettlementOptionsState,

        settlementOption: SettlementOption.FixMyFence,
        isCompleted: true,
      });

      setSettlementState({
        ...settlementState,
        assignedServiceProviders: response.result.assignedServiceProviders,
        isCompleted: true,
        decision: SettlementRequestDecision.DeclinedForRacRepairs,
      });
      setFlowCompleted(true);
      actor.send({
        type: "settlementOptions.next.fixFence",
      });
      navigate(stateUrlLookup[actor.getSnapshot().value]);
    } catch (exception) {
      const error = exception as ClaimsHomeGeneralDamageApiException;
      handleError({
        message: "Error on accept rac repairer",
        shouldRedirect: true,
        customProperties: {
          request: "POST /claims/settlement",
          status: error.status,
          error: error.message,
        },
      });
    } finally {
      setBackdrop(false);
    }
  };

  const onSeeMyOptions = () => {
    setNonSharedSettlementOptionsState({
      ...nonSharedSettlementOptionsState,
      settlementOption: SettlementOption.SeeMyOptions,
      isCompleted: true,
    });

    actor.send({
      type: "settlementOptions.next.allOptions",
    });
    navigate(stateUrlLookup[actor.getSnapshot().value]);
  };

  return {
    form,
    onSeeMyOptions,
    onAcceptRacRepairer,
  };
};

export default useYourSettlementOptions;
