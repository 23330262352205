import { useBffApiClient } from "@/shared/hooks/useApiClient";
import useErrorHandling from "@/shared/hooks/useErrorHandling";
import { StateMachineContext } from "@/shared/stateMachine/hooks";
import { stateUrlLookup } from "@/shared/stateMachine/lookups";
import {
  ClaimsHomeGeneralDamageApiException,
  SafetyChecksRequest,
  SelectedProblems,
} from "raci-claims-home-general-damage-clientproxy";
import { useSessionState, useSetBackdrop } from "raci-react-library";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { SafetyChecksFormProps, SafetyChecksFormValues, SafetyChecksState } from "../../types";

export const useSafetyChecks = (): SafetyChecksFormProps => {
  const actor = StateMachineContext.useActorRef();
  const navigate = useNavigate();
  const apiClient = useBffApiClient();
  const setBackdrop = useSetBackdrop();
  const handleError = useErrorHandling();
  const [safetyChecksState, setSafetyChecksState] = useSessionState<SafetyChecksState>({
    skipPageTrackingRecalculation: true,
  });

  const form = useForm<SafetyChecksFormValues>({
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues: safetyChecksState,
  });

  const getRequest = (values?: SafetyChecksFormValues): SafetyChecksRequest => {
    return {
      selectedProblems: values?.selectedProblems.map((problem) => problem.key as SelectedProblems),
    };
  };

  const onSubmit = async (values?: SafetyChecksFormValues) => {
    try {
      setBackdrop(true);

      await apiClient.safetyChecks(getRequest(values));

      setSafetyChecksState({
        ...safetyChecksState,
        ...values,
        isCompleted: true,
      });

      actor.send({
        type: "safetyChecks.next",
      });

      navigate(stateUrlLookup[actor.getSnapshot().value]);
    } catch (exception) {
      const error = exception as ClaimsHomeGeneralDamageApiException;
      handleError({
        message: "Error submitting safety checks",
        shouldRedirect: true,
        customProperties: {
          request: "POST /step/safety-checks",
          status: error.status,
          error: error.message,
        },
      });
    } finally {
      setBackdrop(false);
    }
  };

  return {
    form,
    onSubmit,
  };
};

export default useSafetyChecks;
