import { useFlowState } from "@/shared/stateMachine/hooks";
import { flowLookup } from "@/shared/stateMachine/lookups";
import { RacwaBreadcrumbDetails } from "@racwa/react-components";

const FRAGMENT = "#";

export enum Breadcrumb {
  MakeAClaim = "Make a claim",
  HomeInsurance = "Home insurance",
  DamageType = "Damage type",
  Claim = "Claim",
}

export const useBreadcrumbs = (isActiveFlow: boolean, isDamagedTypeSelected: boolean): RacwaBreadcrumbDetails => {
  const {
    isBuildingContentsAndFence,
    isBuildingAndFence,
    isBuildingAndContents,
    isBuildingOnly,
    isFenceOnly,
    isContentsOnly,
    isContentsAndFence,
    flow,
    fenceFlow,
  } = useFlowState();

  const damageTypeBreadcrumb = () => {
    if (isBuildingOnly) {
      return flowLookup["building"].name;
    }

    if (isFenceOnly) {
      return fenceFlow === "shared" ? flowLookup["sharedFence"].name : flowLookup["nonSharedFence"].name;
    }

    if (isContentsOnly) {
      return flowLookup["contents"].name;
    }

    if (isContentsAndFence) {
      return `Contents and ${fenceFlow === "shared" ? "shared fence" : "non-shared fence"}`;
    }

    if (isBuildingAndContents) {
      return "Building and contents";
    }

    if (isBuildingAndFence) {
      return `Building and ${fenceFlow === "shared" ? "shared fence" : "non-shared fence"}`;
    }

    if (isBuildingContentsAndFence) {
      return `Building, contents and ${fenceFlow === "shared" ? "shared fence" : "non-shared fence"}`;
    }
  };

  type BreadcrumbLink = RacwaBreadcrumbDetails["links"][number];

  const breadcrumbs: Record<Breadcrumb, BreadcrumbLink> = {
    [Breadcrumb.MakeAClaim]: {
      name: Breadcrumb.MakeAClaim,
      key: Breadcrumb.MakeAClaim,
      href: process.env.REACT_APP_EXTERNAL_LINK_RAC_MAKE_A_CLAIM ?? FRAGMENT,
    },
    [Breadcrumb.HomeInsurance]: {
      name: Breadcrumb.HomeInsurance,
      key: Breadcrumb.HomeInsurance,
      href: process.env.REACT_APP_TRIAGE_APP_BUILDING_AND_CONTENTS_URL ?? FRAGMENT,
    },
    [Breadcrumb.DamageType]: {
      name: flow ? `${damageTypeBreadcrumb()} storm damage` : "",
      key: flow ? `${damageTypeBreadcrumb()} storm damage` : "",
      href: process.env.REACT_APP_BASE_URL ?? FRAGMENT,
    },
    [Breadcrumb.Claim]: {
      name: Breadcrumb.Claim,
      key: Breadcrumb.Claim,
      href: FRAGMENT,
    },
  };

  const links = [breadcrumbs[Breadcrumb.MakeAClaim]];

  let currentPage: RacwaBreadcrumbDetails["currentPage"];

  if (isActiveFlow) {
    links.push(breadcrumbs[Breadcrumb.HomeInsurance]);
    links.push(breadcrumbs[Breadcrumb.DamageType]);
    currentPage = breadcrumbs[Breadcrumb.Claim];
  } else if (isDamagedTypeSelected) {
    links.push(breadcrumbs[Breadcrumb.HomeInsurance]);
    currentPage = breadcrumbs[Breadcrumb.DamageType];
  } else {
    currentPage = breadcrumbs[Breadcrumb.HomeInsurance];
  }

  return {
    links,
    currentPage,
  };
};

export default useBreadcrumbs;
