import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NotificationCard } from "raci-react-library";

export const DiscussNotificationCard = () => {
  return (
    <NotificationCard
      icon={<FontAwesomeIcon icon={faUser} />}
      message={{
        title: "Once you submit your claim, we'll contact you to discuss this",
      }}
      severity="info"
    />
  );
};

export default DiscussNotificationCard;
