export const SESSION_KEY_PREFIX = "RAC_CHG_";
export const NON_BREAKING_SPACE = "\u00a0";

export const BFF_API_BASE_URL = process.env.REACT_APP_API_BASE_URL ?? "";
export const LOOKUP_SERVICE_URL = process.env.REACT_APP_LOOKUP_SERVICE_API_URL || "";
export const MFA_OTP_SERVICE_API_BASE_URL = process.env.REACT_APP_MFA_OTP_SERVICE_API_BASE_URL || "";
export const DOCUMENT_SERVICE_API_BASE_URL = process.env.REACT_APP_DOCUMENT_SERVICE_API_BASE_URL ?? "";

export const STORAGE_KEY_POLICY_NUMBER = `${SESSION_KEY_PREFIX}POLICY_NUMBER`;
export const STORAGE_KEY_LOGIN_REGISTER_REDIRECT_TRIGGERED = `${SESSION_KEY_PREFIX}_LOGIN_REGISTER_REDIRECT_TRIGGERED`;
export const STORAGE_KEY_HAS_ATTEMPTED_TO_ENTER_FLOW = `${SESSION_KEY_PREFIX}HAS_ATTEMPTED_TO_ENTER_FLOW`;
export const ADB2C_DOMAIN = process.env.REACT_APP_ADB2C_DOMAIN ?? "";
export const ADB2C_TENANT = process.env.REACT_APP_ADB2C_TENANT ?? "";
export const ADB2C_CLIENT_ID = process.env.REACT_APP_ADB2C_CLIENT_ID ?? "";
export const ADB2C_RETURN_URL = process.env.REACT_APP_ADB2C_RETURN_URL ?? "";

export const PRODUCT_LINKING_REDIRECT_URL = process.env.REACT_APP_PRODUCT_LINKING_REDIRECT_URL ?? "";

export const SILENT_LOGIN_REDIRECT_URL = `${process.env.PUBLIC_URL}/redirect`;

// <------------------>
//  Atoms
// <------------------>
export const ATOM_SESSION_ID = "AtomSessionId";
export const ATOM_FLOW_COMPLETED_ID = "AtomFlowCompleted";
export const ATOM_ERROR_STATE_ID = "AtomErrorState";

export const FenceTypeIdMapping: { [key: string]: string } = {
  Colorbond: "1000114",
  Hardifence: "1000111",
  SuperSix: "1000112",
  Brick: "1000115",
  Wooden: "1000113",
  Asbestos: "1000116",
  Glass: "68000000",
  Other: "1000117",
};
