import useStepperPage from "@/shared/hooks/useStepperPage";
import { createPageTitle } from "@/shared/routing/TitleGuard";
import { RacwaStepperTemplate } from "@racwa/react-components";
import { RouteObject } from "react-router-dom";
import BuildingDamageForm from "./Form";
import useBuildingDamage from "./hooks/useBuildingDamage";

export const pageName = "Your building damage";

export const BuildingDamage = () => {
  const props = useBuildingDamage();
  const stepperProps = useStepperPage();

  return (
    <RacwaStepperTemplate {...stepperProps}>
      <BuildingDamageForm {...props} />
    </RacwaStepperTemplate>
  );
};

export const BuildingDamageRoute = {
  path: "building-damage",
  element: <BuildingDamage />,
  loader: () => (document.title = createPageTitle(pageName)),
} as const satisfies RouteObject;

export default BuildingDamage;
