import { event, gtm } from "@racwa/analytics";
import { NotificationCard } from "raci-react-library";
import { memo } from "react";

const notificationCardTitle = "Looks like there's no damage to claim for";

const NoDamageNotificationCard = memo(() => {
  gtm(event(notificationCardTitle));
  return (
    <NotificationCard
      severity="error"
      message={{
        title: notificationCardTitle,
        paragraphs: ["Please check your answers."],
      }}
    />
  );
});

export default NoDamageNotificationCard;
