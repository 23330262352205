import { MenuItem } from "@mui/material";
import { fieldTouched, gtm } from "@racwa/analytics";
import { RacwaSelect } from "@racwa/react-components";
import { FenceType } from "raci-claims-home-general-damage-clientproxy";
import { Controller, useFormContext } from "react-hook-form";
import { YourFenceQuestions, fenceTypeId } from "../../constants";

export interface SelectFenceTypeProps {
  fenceTypes?: FenceType[];
}

const { fenceType } = YourFenceQuestions;

export const SelectFenceType = ({ fenceTypes }: SelectFenceTypeProps) => {
  const { control, setValue } = useFormContext();

  return (
    <Controller
      control={control}
      name={fenceTypeId}
      defaultValue={""}
      rules={{
        required: {
          value: true,
          message: `Please select a fence type`,
        },
      }}
      render={({ field: { ref, value, onChange, ...inputProps }, fieldState: { error } }) => (
        <RacwaSelect
          {...inputProps}
          value={value}
          color="primary"
          id={fenceTypeId}
          data-testid={fenceTypeId}
          label={fenceType.label}
          fullWidth
          inputRef={ref}
          displayEmpty
          error={!!error}
          helperText={error?.message || ""}
          onChange={(e: any) => {
            const value = e.target.value as string;
            onChange && onChange(value);
            value && setValue(inputProps.name, value);
            gtm(fieldTouched(fenceType.label));
          }}
        >
          <MenuItem key="disabled" disabled selected hidden value="">
            Please select
          </MenuItem>
          {fenceTypes?.map((fenceType: FenceType, index) => (
            <MenuItem id={fenceType.fenceTypeId} key={index} value={fenceType.fenceTypeId}>
              {fenceType.description}
            </MenuItem>
          ))}
        </RacwaSelect>
      )}
    />
  );
};
