import { createActorContext } from "@xstate/react";
import { useEffect } from "react";
import { flowMachine } from ".";
import { flowLookup } from "./lookups";

export const STATE_MACHINE_SESSION_STORAGE_KEY = "STATE_MACHINE";

export const StateMachineContext = createActorContext(flowMachine, {
  snapshot: JSON.parse(sessionStorage.getItem(STATE_MACHINE_SESSION_STORAGE_KEY) || "null"),
});

export const usePersistStateMachine = () => {
  const actor = StateMachineContext.useActorRef();

  useEffect(() => {
    const subscribe = actor.subscribe((_state) => {
      const state = actor.getPersistedSnapshot();
      const serialisedState = JSON.stringify(state);
      sessionStorage.setItem(STATE_MACHINE_SESSION_STORAGE_KEY, serialisedState);
    });

    return subscribe.unsubscribe;
  }, [actor]);
};

export const useFlowState = () => {
  const flow = StateMachineContext.useSelector((state) => state.context.flow);
  const fenceFlow = StateMachineContext.useSelector((state) => state.context.fenceFlow);

  const getFlowUrl = () => {
    let virtualPageViewUrl = "";

    if (flow?.includes("building")) {
      virtualPageViewUrl += flowLookup["building"].path;
    }

    if (flow?.includes("contents")) {
      virtualPageViewUrl += flowLookup["contents"].path;
    }

    if (flow?.includes("fence")) {
      if (fenceFlow === undefined) {
        virtualPageViewUrl += "/fence";
      } else {
        virtualPageViewUrl +=
          fenceFlow === "shared" ? flowLookup["sharedFence"].path : flowLookup["nonSharedFence"].path;
      }
    }

    return virtualPageViewUrl;
  };

  return {
    isBuildingOnly: flow?.length === 1 && flow.includes("building"),
    isFenceOnly: flow?.length === 1 && flow.includes("fence"),
    isContentsOnly: flow?.length === 1 && flow.includes("contents"),
    isContentsAndFence: flow?.length === 2 && flow.includes("contents") && flow.includes("fence"),
    isBuildingAndContents: flow?.length === 2 && flow.includes("building") && flow.includes("contents"),
    isBuildingAndFence: flow?.length === 2 && flow.includes("building") && flow.includes("fence"),
    isBuildingContentsAndFence:
      flow?.length === 3 && flow.includes("building") && flow.includes("contents") && flow.includes("fence"),
    flow,
    fenceFlow,
    getFlowUrl,
  };
};
