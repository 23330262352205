import { SafetyChecksRoute } from "@/views/building/SafetyChecks";
import { problemOptions } from "@/views/building/SafetyChecks/constants";
import { SafetyChecksState } from "@/views/building/SafetyChecks/types";
import { SelectedProblems } from "raci-claims-home-general-damage-clientproxy";
import { useGetSessionState } from "raci-react-library";

export const useGetSafetyChecksSummary = (): string[] => {
  const { selectedProblems } = useGetSessionState<SafetyChecksState>(SafetyChecksRoute.path);
  const damageTypesOrder = Object.values(SelectedProblems);
  return (
    selectedProblems
      ?.sort(
        (a, b) =>
          damageTypesOrder.indexOf(a.key as SelectedProblems) - damageTypesOrder.indexOf(b.key as SelectedProblems),
      )
      .map((problem) => problemOptions.find((option) => option.key === problem.key)?.label ?? "") ?? []
  );
};
