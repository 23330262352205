import {
  CarGarage,
  ClothesPeg,
  Flooring,
  Glass,
  HomeGRFX021,
  Leadlight,
  RoofWithAerial,
  SecuritySystem,
  SolarPanel,
} from "@racwa/react-components";
import { SelectedSpecificDamageTypes } from "raci-claims-home-general-damage-clientproxy";

export const specificDamageOptions = [
  {
    key: SelectedSpecificDamageTypes.Flooring,
    label: "Flooring",
    icon: <Flooring />,
  },
  {
    key: SelectedSpecificDamageTypes.SolarPanels,
    label: "Solar panels",
    icon: <SolarPanel />,
  },
  {
    key: SelectedSpecificDamageTypes.GarageDoorOrMotor,
    label: "Garage door or motor",
    icon: <CarGarage />,
  },
  {
    key: SelectedSpecificDamageTypes.TvAerial,
    label: "TV aerial",
    icon: <RoofWithAerial />,
  },
  {
    key: SelectedSpecificDamageTypes.Clothesline,
    label: "Clothesline",
    icon: <ClothesPeg />,
  },
  {
    key: SelectedSpecificDamageTypes.SecuritySystem,
    label: "Security system",
    icon: <SecuritySystem />,
  },
  {
    key: SelectedSpecificDamageTypes.Glass,
    label: "Glass",
    icon: <Glass />,
  },
  {
    key: SelectedSpecificDamageTypes.Leadlight,
    label: "Leadlight",
    icon: <Leadlight />,
  },
  {
    key: SelectedSpecificDamageTypes.OtherItems,
    label: "Other items",
    icon: <HomeGRFX021 />,
  },
];
