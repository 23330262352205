import { SpecificDamageRoute } from "@/views/building/SpecificDamage";
import { specificDamageOptions } from "@/views/building/SpecificDamage/constants";
import { SpecificDamageState } from "@/views/building/SpecificDamage/types";
import { SelectedSpecificDamageTypes } from "raci-claims-home-general-damage-clientproxy";
import { useGetSessionState } from "raci-react-library";

export const useGetSpecificDamageSummary = (): string[] => {
  const { damagedItems } = useGetSessionState<SpecificDamageState>(SpecificDamageRoute.path);
  const damageTypesOrder = Object.values(SelectedSpecificDamageTypes);
  return (
    damagedItems
      ?.sort((a, b) => damageTypesOrder.indexOf(a) - damageTypesOrder.indexOf(b))
      .map((damagedItem) => specificDamageOptions.find((option) => option.key === damagedItem)?.label ?? "") ?? []
  );
};
