import { faWarning } from "@fortawesome/free-solid-svg-icons";
import { Typography } from "@mui/material";
import { CardWithIcon } from "raci-react-library";
import { ConfirmationVariant } from "../../../hooks/useConfirmation/types";

export interface TemporaryFenceCardProps {
  variant: ConfirmationVariant;
}

export const TemporaryFenceCard: React.FC<TemporaryFenceCardProps> = ({ variant }) => {
  const getParagraphs = () => {
    switch (variant.type) {
      case "NotEligibleCashSettlementDueToWoodOrBrick":
        return [<Typography variant="body1">The builder will also organise your temporary fence.</Typography>];
      default:
        return [
          <Typography variant="body1">
            One of our allocated builders will call you within one to two business days to organise your temporary
            fence.
          </Typography>,
        ];
    }
  };

  return (
    <CardWithIcon
      icon={faWarning}
      title={<Typography variant="h3">Your temporary fence</Typography>}
      iconStyle={{ height: "24px", width: "24px" }}
      paragraphs={getParagraphs()}
      data-testid="temporary-fence"
    />
  );
};

export default TemporaryFenceCard;
