import { FormQuestions } from "@/shared/types";
import { ContentsListFormValues } from "./types";

export const anyOtherDamagedContentsName = "anyOtherDamagedContents";
export const contentsListName = "contentsList";

export const ContentsListQuestions: FormQuestions<ContentsListFormValues> = {
  anyOtherDamagedContents: {
    id: "anyOtherDamagedContentsQuestion",
    label: "Do you have any other damaged contents?",
    name: anyOtherDamagedContentsName,
  },
  contentsList: {
    id: "contentsListQuestion",
    label: "List each item that's been damaged",
    name: contentsListName,
  },
};
