import useStepperPage from "@/shared/hooks/useStepperPage";
import { createPageTitle } from "@/shared/routing/TitleGuard";
import { RacwaStepperTemplate } from "@racwa/react-components";
import { RouteObject } from "react-router-dom";
import YourExcessForm from "./Form";
import useYourExcess from "./hooks/useYourExcess";

export const pageName = "Your excess";

export const YourExcess = () => {
  const props = useYourExcess();
  const stepperProps = useStepperPage();

  return (
    <RacwaStepperTemplate {...stepperProps}>
      <YourExcessForm {...props} />
    </RacwaStepperTemplate>
  );
};

export const YourExcessRoute = {
  path: "your-excess",
  element: <YourExcess />,
  loader: () => (document.title = createPageTitle(pageName)),
} as const satisfies RouteObject;

export default YourExcess;
