import styled from "@emotion/styled";
import { Grid, Link } from "@mui/material";

export const StyledLink = styled(Link)({
  cursor: "pointer",
  listStyleType: "disc",
  display: "list-item",
  marginLeft: "24px",
  marginTop: "8px",
  fontSize: "18px",
  textDecoration: "none",
});

export const StyledGridIcon = styled(Grid)({
  display: "inline-block",
  borderRadius: "100px",
  height: "25px",
  width: "25px",
  marginRight: "8px",
});

export const StyledGridChooseOptions = styled(Grid)({
  "& h3": {
    fontSize: "18px",
    fontWeight: 400,
  },
  marginTop: "8px",
});
