import { StateMachineContext } from "@/shared/stateMachine/hooks";
import { ClaimsHomeGeneralDamageApiException } from "raci-claims-home-general-damage-clientproxy";
import {
  HTTP_STATUS_CODE_TIMEOUT,
  setIntervalGuarded,
  useSessionIdStorageKey,
  useSessionTimeRemaining,
} from "raci-react-library";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useBffApiClient } from "../useApiClient";
import useOnExpiry from "../useOnExpiry";

export const EXPIRY_CHECK_INTERVAL = 30000;

export const useSessionExpiry = () => {
  const { setTimeoutTime } = useSessionTimeRemaining();
  const location = useLocation();
  const navigate = useNavigate();
  const client = useBffApiClient();
  const sessionIdStorageKey = useSessionIdStorageKey();
  const [wait, setWait] = useState(EXPIRY_CHECK_INTERVAL);
  const onExpiry = useOnExpiry();

  const state = StateMachineContext.useSelector((state) => state);
  const isFlowCompleted = state.status === "done";

  const getNextDelay = (expiry: number) => Math.max(expiry * 500, EXPIRY_CHECK_INTERVAL);

  useEffect(() => {
    let isCancelled = false;

    const checkSessionExpiry = async () => {
      try {
        const response = await client.getSessionExpiryInSeconds();
        if (!isCancelled) {
          const expiry = parseInt(response.result);
          setTimeoutTime(new Date().getTime() + expiry * 1000);
          setWait(getNextDelay(expiry));
        }
      } catch (ex) {
        const response = ex as ClaimsHomeGeneralDamageApiException;
        if (response?.status === HTTP_STATUS_CODE_TIMEOUT && !isFlowCompleted) {
          setWait(getNextDelay(-1));
          onExpiry();
        }
      }
    };

    const isSessionTimeoutPage = location.pathname.endsWith(HTTP_STATUS_CODE_TIMEOUT.toString());

    if (!isSessionTimeoutPage) {
      const handle = setIntervalGuarded(async () => {
        if (sessionStorage.getItem(sessionIdStorageKey)) {
          await checkSessionExpiry();
        }
      }, wait);
      return () => {
        isCancelled = true;
        clearTimeout(handle);
      };
    }

    return () => {
      isCancelled = true;
    };
  }, [wait, client, navigate, location.pathname, sessionIdStorageKey, isFlowCompleted, setTimeoutTime, onExpiry]);
};

export default useSessionExpiry;
