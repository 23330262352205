import { faMoneyBillWave, faRuler } from "@fortawesome/free-solid-svg-icons";
import { Divider, Grid } from "@mui/material";
import { colors } from "@racwa/styles";
import { CardWithIcon } from "raci-react-library";
import { TitleTypography } from "../styled";

const cardInfo = [
  { icon: faRuler, title: "If you can, measure the damaged part of the fence in metres before you start." },
  {
    icon: faMoneyBillWave,
    title: "We offer the choice of a cash settlement or a repairer for non-shared fences.",
  },
];

export const NonSharedFence: React.FC = () => {
  return (
    <>
      {cardInfo.map((card, index) => (
        <Grid item xs={12} key={card.title}>
          <CardWithIcon
            border={false}
            spacing={2}
            sx={{ paddingLeft: "0px", paddingRight: "0px", color: colors.dieselDeep }}
            icon={card.icon}
            title={<TitleTypography>{card.title}</TitleTypography>}
            iconStyle={{ marginTop: "5px" }}
          />
          {index !== cardInfo.length - 1 && <Divider />}
        </Grid>
      ))}
    </>
  );
};
