import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { createBrowserHistory } from "history";

const browserHistory = createBrowserHistory();
const reactPlugin = new ReactPlugin();

const appInsights = new ApplicationInsights({
  config: {
    connectionString: `InstrumentationKey=${process.env.REACT_APP_INSTRUMENTATION_KEY};IngestionEndpoint=${process.env.REACT_APP_INSIGHTS_URL}`,
    extensions: [reactPlugin as any],
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory },
    },
    disableAjaxTracking: true,
    disableFetchTracking: true,
    disableExceptionTracking: true,
  },
});

if (
  process.env.REACT_APP_INSTRUMENTATION_KEY &&
  process.env.REACT_APP_INSTRUMENTATION_KEY.trim() !== "" &&
  process.env.REACT_APP_INSIGHTS_URL &&
  process.env.REACT_APP_INSIGHTS_URL.trim() !== ""
) {
  appInsights.loadAppInsights();
}

export { reactPlugin };
