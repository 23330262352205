import { ContactDetailsRoute } from "@/views/common/ContactDetails";
import { ContactDetailsFormValues, ContactDetailsState } from "@/views/common/ContactDetails/types";
import { formatPhoneNumber, useGetSessionState } from "raci-react-library";
import { FormSummary } from "../../types";

export const useGetContactDetailsSummary = (): FormSummary<ContactDetailsFormValues> => {
  const { contactNumber, email } = useGetSessionState<ContactDetailsState>(ContactDetailsRoute.path);
  return {
    contactNumber: {
      label: "Contact number",
      content: contactNumber && formatPhoneNumber(contactNumber),
    },
    email: {
      label: "Email",
      content: email,
    },
  };
};
