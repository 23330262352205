import { CallToActionButton } from "@/shared/components/CallToActionButton";
import ConfirmRacRepairerDialog from "@/shared/components/ConfirmRacRepairerDialog";
import PageTitle from "@/shared/components/PageTitle";
import { YourSettlementOptionsQuestions } from "@/views/nonSharedFence/YourSettlementOptions/constants";
import { Grid, Typography } from "@mui/material";
import { event, fieldTouched, gtm } from "@racwa/analytics";
import { RadioButtonGroup, RadioButtonGroupItem } from "raci-react-library";
import { useState } from "react";
import { FormProvider } from "react-hook-form";
import { StyledGridChooseOptions } from "./styled";
import { SettlementOption, YourSettlementOptionsFormProps } from "./types";

const { settlementOption: settlementOptionQuestion } = YourSettlementOptionsQuestions;

const items: RadioButtonGroupItem[] = [
  {
    id: "1",
    value: SettlementOption[SettlementOption.FixMyFence],
    label: "I'd like my fence fixed",
    sublabel: "We'll arrange the repairs for you. Professional trades will manage and complete the repairs.",
  },
  {
    id: "2",
    value: SettlementOption[SettlementOption.SeeMyOptions],
    label: "I'd like to see all my options",
    sublabel: "Next, we'll show you a cash settlement fact sheet outlining your options.",
  },
];

export const YourSettlementOptionsForm = ({
  form,
  onSeeMyOptions,
  onAcceptRacRepairer,
}: YourSettlementOptionsFormProps) => {
  const [repairerDialogOpen, setRepairerDialogOpen] = useState(false);

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = form;

  const onOptionSelected = () => {
    const formValues = form.getValues();

    if (formValues.settlementOption === SettlementOption.FixMyFence) {
      setRepairerDialogOpen(true);
    } else if (formValues.settlementOption === SettlementOption.SeeMyOptions) {
      onSeeMyOptions();
    }
  };

  return (
    <FormProvider {...form}>
      <form action="#" onSubmit={handleSubmit(onOptionSelected)} data-testid="yourSettlementOptionsForm">
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <PageTitle id="header" title="Your claim settlement options" showClaimNumber />
          </Grid>
          <StyledGridChooseOptions item xs={12}>
            <Typography variant="h3">Let us know how you'd like to go ahead with your claim.</Typography>
          </StyledGridChooseOptions>
          <Grid sx={{ marginTop: "16px" }} item xs={12}>
            <RadioButtonGroup
              onChange={(_, value) => {
                gtm(fieldTouched("Let us know how you'd like to go ahead with your claim"));

                if (value === SettlementOption.FixMyFence) {
                  gtm(event("Let us know how you'd like to go ahead with your claim - I'd like my fence fixed"));
                }

                if (value === SettlementOption.SeeMyOptions) {
                  gtm(event("Let us know how you'd like to go ahead with your claim - I'd like to see all my options"));
                }
              }}
              name={settlementOptionQuestion.name}
              id={settlementOptionQuestion.id}
              data-testid={settlementOptionQuestion.id}
              fullwidth
              items={items}
            />
          </Grid>
          <Grid item xs={12}>
            <CallToActionButton sx={{ margin: "40px 0 32px 0" }} disabled={isSubmitting}>
              Next
            </CallToActionButton>
          </Grid>
        </Grid>
        <ConfirmRacRepairerDialog
          showDialog={repairerDialogOpen}
          setShowDialog={setRepairerDialogOpen}
          onConfirm={onAcceptRacRepairer}
        />
      </form>
    </FormProvider>
  );
};

export default YourSettlementOptionsForm;
