import { FormQuestions } from "@/shared/types";
import { SafetyAndSecurityFormValues } from "./types";

export const isSafetyIssueName = "isSafetyIssue";

export const SafetyAndSecurityQuestions: FormQuestions<SafetyAndSecurityFormValues> = {
  isSafetyIssue: {
    id: isSafetyIssueName,
    label: "As a result of this fence damage, do any of the following apply",
    name: isSafetyIssueName,
  },
};
