import { Pages } from "@/App";

import { StateMachineContext } from "@/shared/stateMachine/hooks";
import { LogRequest, useLogger } from "raci-react-library";
import { useLocation, useNavigate } from "react-router-dom";

interface ErrorHandlingProps {
  message: string;
  shouldRedirect?: boolean;
  customProperties?: { [key: string]: string | number | undefined };
  isMemberContactError?: boolean;
}

export const useErrorHandling = () => {
  const flow = StateMachineContext.useSelector((state) => state.context.flow);
  const fenceFlow = StateMachineContext.useSelector((state) => state.context.fenceFlow);
  const navigate = useNavigate();
  const location = useLocation();
  const { logError } = useLogger();
  const actor = StateMachineContext.useActorRef();

  const handleError = ({
    message,
    shouldRedirect = false,
    customProperties,
    isMemberContactError,
  }: ErrorHandlingProps) => {
    const currentLocation = location.pathname.toLocaleLowerCase();
    const request: LogRequest = {
      location: currentLocation,
      message: message,
      customProperties: {
        flow: flow?.map((f) => f as string).join(", ") ?? "undefined",
        fenceFlow: fenceFlow ?? "undefined",
        ...customProperties,
      },
    };

    logError(request);

    if (shouldRedirect) {
      actor.send({ type: "error.systemUnavailable" });
      navigate("/claims/home/general/error" as const satisfies Pages, {
        replace: true,
        state: {
          referrer: currentLocation,
          isMemberContactError: isMemberContactError,
          exception: { request: customProperties?.request, status: customProperties?.status },
        },
      });
    }
  };

  return handleError;
};

export default useErrorHandling;
