import { StateMachineContext } from "@/shared/stateMachine/hooks";
import { faCircle, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Divider, Grid, Link, TableCell, Typography } from "@mui/material";
import { event, fieldTouched, gtm } from "@racwa/analytics";
import { Card } from "@racwa/react-components";
import { colors } from "@racwa/styles";
import { IconWithText, InlineAccordion, NotificationCard, currencyFormatter, formatIdString } from "raci-react-library";
import { ReactNode } from "react";
import { StyledRowTooltipParent } from "./styled";

export interface CashSettlementOptionProps {
  onClick: () => void;
  onToggleOpen: () => void;
  open: boolean;
  children?: ReactNode;
  cashSettlementAmount: number;
  title?: string;
}

export interface CashSettlementOptionItemProps {
  title: string;
  subtitle?: string;
  content: number | string;
  children?: ReactNode;
  bold?: boolean;
}

const Item = ({ title, subtitle, children, content, bold }: CashSettlementOptionItemProps) => (
  <StyledRowTooltipParent>
    <TableCell style={children ? undefined : { padding: "12px 0px 12px 0px" }}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Typography variant="body1" paragraph>
          <b style={{ fontWeight: bold ? 600 : undefined }}>{title}</b>
        </Typography>

        {children}
      </div>
      {subtitle && (
        <Typography variant="body2" paragraph>
          {subtitle}
        </Typography>
      )}
    </TableCell>
    <TableCell align="right" id={formatIdString(`${title}-cell`)}>
      <b style={{ fontWeight: bold ? 600 : undefined }}>
        {typeof content === "number"
          ? currencyFormatter(content, {
              withDecimals: true,
            })
          : content}
      </b>
    </TableCell>
  </StyledRowTooltipParent>
);

const CashSettlementOption = ({
  onClick,
  onToggleOpen,
  open,
  children,
  cashSettlementAmount,
  title,
}: CashSettlementOptionProps) => {
  const fenceFlow = StateMachineContext.useSelector((state) => state.context.fenceFlow);

  return (
    <>
      <Grid container item xs={12} spacing={1} paddingBottom={3}>
        <IconWithText
          icon={faCircle}
          textLayer={
            <Typography sx={{ color: colors.white }} variant="h3">
              A
            </Typography>
          }
          text={<Typography variant="h3">{title}</Typography>}
          iconStyle={{ width: "28px", height: "28px" }}
        />
      </Grid>
      <Card
        background="white"
        sx={{
          padding: "0px",
          marginLeft: "0px",
          marginTop: "0px",
          textAlign: "left",
        }}
      >
        <Grid container item xs={12} sx={{ padding: "24px" }}>
          <Grid item>
            <Typography sx={{ fontWeight: 600 }} variant="h4">
              Cash settlement
            </Typography>
            <Typography
              sx={{ fontSize: "28px", fontWeight: 600, color: colors.dieselDeep, marginBottom: "16px" }}
              id="cash-settlement-total"
              variant="body1"
            >
              {currencyFormatter(cashSettlementAmount, { withDecimals: true })}
            </Typography>
          </Grid>
          <Grid item sx={{ paddingLeft: "4px" }}>
            <InlineAccordion
              id="cashSettlementOption-accordion"
              title="Hide cash settlement breakdown"
              titleClosed="See cash settlement breakdown"
              open={open}
              onClick={() => {
                gtm(event("See cash settlement breakdown"));
                onToggleOpen();
              }}
            >
              {children}
            </InlineAccordion>
          </Grid>
        </Grid>
        <Divider sx={{ width: "100%" }} />
        <Grid container item paddingTop={3} paddingBottom={3} spacing={3} sx={{ padding: "24px" }}>
          <Grid item xs={12}>
            <Typography variant="body1">You'll receive this cash settlement in your bank account.</Typography>
          </Grid>
          {fenceFlow === "nonShared" && (
            <Grid item xs={12}>
              <NotificationCard
                id="important-information-notification"
                icon={
                  <div style={{ marginTop: "4px" }}>
                    <FontAwesomeIcon icon={faInfoCircle} />
                  </div>
                }
                message={{
                  title: "Important information",
                  content: (
                    <>
                      If you choose a cash settlement:
                      <ul style={{ lineHeight: "24px" }}>
                        <li>RAC won't arrange the repairs or replacements.</li>
                        <li> We won't offer a warranty or help with any disputes.</li>
                        <li>You'll arrange the repairs or replacements yourself.</li>
                        <li>You can ask us for a copy of the information we've based our claim assessment on. </li>
                        <li>You can have your claim reviewed by contacting us.</li>
                        <li>
                          If you're still concerned, you can{" "}
                          <Link
                            onClick={() => gtm(event("lodge a complaint"))}
                            href={process.env.REACT_APP_RAC_COMPLAINTS_URL}
                          >
                            lodge a complaint
                          </Link>
                          .
                        </li>
                      </ul>
                    </>
                  ),
                }}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <Button
              fullWidth
              color="primary"
              data-testid="acceptSettlementButton"
              onClick={() => {
                gtm(fieldTouched("Choose your claim settlement option"));
                gtm(event("Choose your claim settlement option - Accept settlement"));
                onClick();
              }}
            >
              Accept settlement
            </Button>
          </Grid>
        </Grid>
      </Card>
    </>
  );
};

CashSettlementOption.Item = Item;
export default CashSettlementOption;
