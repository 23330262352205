import useStepperPage from "@/shared/hooks/useStepperPage";
import { createPageTitle } from "@/shared/routing/TitleGuard";
import { StateMachineContext } from "@/shared/stateMachine/hooks";
import { RacwaStepperTemplate } from "@racwa/react-components";
import { useEffect } from "react";
import { RouteObject } from "react-router-dom";
import UploadInvoiceQuoteForm from "./Form";
import useUploadInvoiceQuote from "./hooks/useUploadInvoiceQuote";

export const pageName = "Upload your";

export const UploadInvoiceQuote = () => {
  const props = useUploadInvoiceQuote();
  const stepperProps = useStepperPage();

  const repairType = StateMachineContext.useSelector((state) => state.context.repairType);

  useEffect(() => {
    const title = createPageTitle(`${pageName} ${repairType === "haveRepairQuote" ? "quote" : "invoice"}`);
    document.title = title;
  }, [repairType]);

  return (
    <RacwaStepperTemplate {...stepperProps}>
      <UploadInvoiceQuoteForm {...props} />
    </RacwaStepperTemplate>
  );
};

export const UploadInvoiceQuoteRoute = {
  path: "upload-your-invoice-quote",
  element: <UploadInvoiceQuote />,
} as const satisfies RouteObject;

export default UploadInvoiceQuote;
