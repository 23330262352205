import { faAdd, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  Card,
  CardContent,
  FormHelperText,
  Grid,
  OutlinedInput,
  OutlinedInputProps,
  TextField,
  Typography,
} from "@mui/material";
import { event, fieldTouched, gtm } from "@racwa/analytics";
import { RacwaFormControl } from "@racwa/react-components";
import { colors } from "@racwa/styles";
import { BasicTooltip, THOUSAND_SEPARATOR_THRESHOLD } from "raci-react-library";
import React from "react";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { NumericFormat } from "react-number-format";
import { StyledBox } from "../../styled";
import { ContentsDynamicListProps, ContentsListFormValues } from "../../types";

export const NumericOutlinedInput: React.FC<OutlinedInputProps> = (props: OutlinedInputProps) => (
  <OutlinedInput {...props} inputProps={{ type: "text", inputMode: "numeric", "aria-label": "value" }} />
);

export const ContentsDynamicList: React.FC<ContentsDynamicListProps> = ({ id, label }) => {
  const { control, register, setValue } = useFormContext<ContentsListFormValues>();
  const { fields, append, remove } = useFieldArray<ContentsListFormValues>({
    name: "contentsList",
    control,
    shouldUnregister: false,
  });

  const handleAddItem = () => {
    const newItem = { name: "", value: "" };
    append(newItem);
  };

  const handleDeleteItem = (index: number) => {
    gtm(event(`Delete item`));
    remove(index);
  };

  return (
    <>
      <StyledBox>
        <Grid item xs={11}>
          <Typography id={`${id}-label`} data-testid={`${id}-label`} variant="body1" fontWeight={400}>
            {label}
          </Typography>
        </Grid>
        <Grid item xs={1} sx={{ textAlign: "end" }}>
          <BasicTooltip
            title="Name and description of item"
            showDialog={false}
            id={`${id}-tooltip`}
            data-testid={`${id}-tooltip`}
            message={
              <>
                <Typography variant="body2" sx={{ fontWeight: 400 }}>
                  <>Name each item, e.g. TV, kettle, armchair. If known, provide the make, model and size.</>
                  <>
                    <p>Typical examples:</p>
                    <ul>
                      <li>Fridge LG French door 637L</li>
                      <li>Couch IKEA fabric two-seater</li>
                      <li>Bed sheets Sheridan Egyptian cotton king</li>
                      <li>Toaster Sunbeam Fresh Start two-slice</li>
                    </ul>
                  </>
                  <>
                    <p>For 'Spoilt food', simply enter the total value.</p>
                  </>
                </Typography>
              </>
            }
          />
        </Grid>
      </StyledBox>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          {fields.map((item, index) => {
            return (
              <Card
                variant="outlined"
                key={index}
                id={`${id}-card-${index}`}
                data-testid={`${id}-card-${index}`}
                sx={{
                  border: "1px solid #A5B6C2",
                  borderBottom: index !== fields.length - 1 ? "none" : "1px solid #A5B6C2",
                  padding: 0,
                }}
              >
                <CardContent sx={{ margin: "24px", padding: "0 !important" }}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Controller
                        key={item.id}
                        control={control}
                        name={`contentsList.${index}.name`}
                        defaultValue={item.name ?? ""}
                        rules={{
                          required: {
                            value: true,
                            message: "Please enter a description of the item",
                          },
                        }}
                        render={({ field: { ref, ...props }, fieldState: { error } }) => {
                          const onBlur = (value: string) => {
                            gtm(fieldTouched("Name and description of item"));
                            if (value !== "") {
                              setValue(`contentsList.${index}.name`, value, {
                                shouldValidate: false,
                              });
                            }
                          };
                          return (
                            <>
                              <Box mb={2}>
                                <StyledBox>
                                  <Typography
                                    id={`contentsList-${index}-name-label`}
                                    data-testid={`contentsList-${index}-name-label`}
                                    variant="body1"
                                    fontWeight={400}
                                    color={error ? colors.brandDangerNew : colors.dieselDeeper}
                                  >
                                    Name and description of item
                                  </Typography>
                                </StyledBox>
                                <TextField
                                  {...props}
                                  {...register(`contentsList.${index}.name` as const)}
                                  onBlur={(e) => onBlur(e.currentTarget.value)}
                                  id={`contentsList-${index}-name-field`}
                                  data-testid={`contentsList-${index}-name-field`}
                                  type="string"
                                  onChange={(e) => {
                                    const value = e.currentTarget.value;
                                    if (/^[\w\s~!@#$%^&()_+=`<>?,.:;'{}|[\]/\n\r\t"-]{0,100}$/.test(value)) {
                                      props.onChange(value);
                                    }
                                  }}
                                  placeholder={`e.g. TV Sony Bravia 60"`}
                                  error={!!error}
                                  helperText={error?.message || ""}
                                  inputRef={ref}
                                  sx={{ marginTop: "8px" }}
                                  fullWidth
                                />
                              </Box>
                            </>
                          );
                        }}
                      />
                      <Grid item xs={12}>
                        <Controller
                          key={item.id}
                          control={control}
                          name={`contentsList.${index}.value`}
                          defaultValue={item.value ?? ""}
                          rules={{
                            validate: {
                              valueEntered: (value) => {
                                return (
                                  (value !== undefined && value !== "" && Number(value) > 0) ||
                                  "Please enter an estimated value"
                                );
                              },
                            },
                          }}
                          render={({ field: { ref, ...props }, fieldState: { error } }) => {
                            const onBlur = (value: string) => {
                              gtm(fieldTouched("Estimated value"));
                              const number = parseFloat(value);
                              if (!Number.isNaN(number) && number >= 0) {
                                setValue(`contentsList.${index}.value`, (Math.round(number * 100) / 100).toFixed(0), {
                                  shouldValidate: false,
                                });
                              }
                            };
                            return (
                              <Box>
                                <StyledBox>
                                  <Typography
                                    id={`contentsList-${index}-value-label`}
                                    data-testid={`contentsList-${index}-value-label`}
                                    variant="body1"
                                    fontWeight={400}
                                    color={error ? colors.brandDangerNew : colors.dieselDeeper}
                                  >
                                    Estimated value
                                  </Typography>
                                </StyledBox>
                                <RacwaFormControl error={!!error} fullWidth sx={{ marginTop: "8px" }}>
                                  <NumericFormat
                                    {...props}
                                    {...register(`contentsList.${index}.value` as const)}
                                    inputRef={ref}
                                    id={`contentsList-${index}-value-field`}
                                    data-testid={`contentsList-${index}-value-field`}
                                    placeholder="e.g. $1000"
                                    thousandSeparator={
                                      Number(props?.value) >= THOUSAND_SEPARATOR_THRESHOLD ? true : undefined
                                    }
                                    onValueChange={(e) => {
                                      const value = e.floatValue;
                                      if (value !== undefined) {
                                        props.onChange(value);
                                        setValue(`contentsList.${index}.value`, value.toFixed(0));
                                      }
                                    }}
                                    onBlur={(e) => onBlur(e.currentTarget.value)}
                                    isAllowed={(values) => {
                                      const { value } = values;
                                      return value.length <= 20;
                                    }}
                                    allowNegative={false}
                                    allowLeadingZeros={false}
                                    decimalScale={0}
                                    prefix="$"
                                    customInput={NumericOutlinedInput}
                                    margin="none"
                                  />
                                  {error?.message && (
                                    <FormHelperText id={`contentsList-${index}-value-field-helper-text`}>
                                      {error.message}
                                    </FormHelperText>
                                  )}
                                </RacwaFormControl>
                              </Box>
                            );
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  {fields.length > 1 && (
                    <Box display="flex" justifyContent="center">
                      <Button
                        style={{ color: colors.linkBlue }}
                        variant="text"
                        sx={{ padding: "16px 0px 0px 0px" }}
                        onClick={() => {
                          handleDeleteItem(index);
                        }}
                        startIcon={<FontAwesomeIcon icon={faTrash} style={{ fontSize: "16px" }} />}
                        id={`${id}-card-${index}-delete-button`}
                        data-testid={`${id}-card-${index}-delete-button`}
                      >
                        <Typography sx={{ fontSize: "16px", fontWeight: "400" }}>
                          <u>Delete item</u>
                        </Typography>
                      </Button>
                    </Box>
                  )}
                </CardContent>
              </Card>
            );
          })}
        </Grid>

        <Grid item xs={12}>
          <Button
            color="secondary"
            variant="contained"
            fullWidth
            sx={{ margin: "8px 0 0px 0" }}
            onClick={handleAddItem}
            startIcon={<FontAwesomeIcon icon={faAdd} style={{ fontSize: "18px" }} />}
            id={`${id}-add-button`}
            data-testid={`${id}-add-button`}
          >
            Add another item
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default ContentsDynamicList;
