import { BuildingDamageRoute } from "@/views/building/BuildingDamage";
import { BuildingDamageState } from "@/views/building/BuildingDamage/types";
import { useGetSessionState } from "raci-react-library";

export const useGetBuildingDamageSummary = () => {
  const { damageLevels: selectedBuildingDamageLevels } = useGetSessionState<BuildingDamageState>(
    BuildingDamageRoute.path,
  );

  return selectedBuildingDamageLevels !== undefined ? selectedBuildingDamageLevels.sort() : [];
};
