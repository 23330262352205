/**
 * @description Maps each property in `FormValues` to an input component's `name`/`label`/`sublabel`/`id` prop types.
 * The `-?` mapping modifier is used to enforce that all properties in `FormValues` are provided.
 * @see https://www.typescriptlang.org/docs/handbook/2/mapped-types.html#mapping-modifiers
 *
 * @prop `name` is typed to match it's key, the property from `FormValues` (so that the `name` passed to `react-hook-form` is always correct)
 * @prop `label` can be a `string`
 * @prop `sublabel` is an optional `string`
 * @prop `id` is an optional `string`
 */
export type FormQuestions<FormValues> = {
  [Name in keyof FormValues]-?: {
    name: Name;
    label: string;
    subLabel?: string;
    id?: string;
  };
};

export enum ErrorState {
  SessionExpired = "SessionExpired",
  SystemUnavailable = "SystemUnavailable",
  None = "None",
}
