import useStandardPage from "@/shared/hooks/useStandardPage";
import { createPageTitle } from "@/shared/routing/TitleGuard";
import { RacwaStandardPageTemplate, theme } from "@racwa/react-components";
import { RouteObject } from "react-router-dom";
import StormDamagedFenceClaimForm from "./Form";
import useStormDamagedFenceClaim from "./hooks";

export const heading = "Storm damage claim";
export const pageName = "Storm damage claim";

const StormDamagedFenceClaim = () => {
  const state = useStormDamagedFenceClaim();
  const standardPageProps = useStandardPage();

  return (
    <RacwaStandardPageTemplate {...standardPageProps} heading={heading} bannerTextColour={theme.palette.primary.main}>
      <StormDamagedFenceClaimForm {...state} />
    </RacwaStandardPageTemplate>
  );
};

export const StormDamagedFenceClaimRoute = {
  path: "storm-damaged-fence-claim",
  element: <StormDamagedFenceClaim />,
  loader: () => (document.title = createPageTitle(pageName)),
} as const satisfies RouteObject;

export default StormDamagedFenceClaim;
