import { CallToActionButton } from "@/shared/components/CallToActionButton";
import PageTitle from "@/shared/components/PageTitle";
import MultiChoiceInput from "@/shared/components/ProblemInput";
import YourSafetyNotificationCard from "@/shared/components/YourSafetyNotificationCard";
import { Grid } from "@mui/material";
import { WaterDamageProblems } from "raci-claims-home-general-damage-clientproxy";
import { MultiChoiceOption, NotificationCard } from "raci-react-library";
import { FormProvider } from "react-hook-form";
import { waterDamageName, waterDamageOptions } from "./constants";
import { BuildingWaterDamageFormProps } from "./types";

export const WaterDamageForm = ({
  form,
  isInvestmentPropertyOrContentsFlow,
  onSubmit,
}: BuildingWaterDamageFormProps) => {
  const {
    handleSubmit,
    formState: { isSubmitting },
    watch,
  } = form;

  const filteredOptions = !isInvestmentPropertyOrContentsFlow
    ? waterDamageOptions.filter((option) => option.key !== WaterDamageProblems.BadlySoakedCarpets)
    : waterDamageOptions;

  const watchSelectedProblems = watch(waterDamageName);

  const showYourSafetyCard =
    watchSelectedProblems?.length > 0 &&
    watchSelectedProblems.some((problem: MultiChoiceOption) =>
      [
        WaterDamageProblems.BadlySoakedCarpets,
        WaterDamageProblems.HouseIsFlooded,
        WaterDamageProblems.SewageOrDrainWaterInTheHouse,
        WaterDamageProblems.WaterInTheElectrics,
      ].includes(problem.key),
    );

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)} action="#">
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <PageTitle id="header" title="Water damage inside the home" showClaimNumber />
          </Grid>
          <Grid item xs={12} sx={{ marginTop: "8px" }}>
            <NotificationCard
              severity="info"
              message={{
                title: "Please read this carefully before answering",
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <MultiChoiceInput
              name="waterDamageProblems"
              id="water-damage-problems"
              options={filteredOptions}
              deselectAllOption={WaterDamageProblems.NoWaterDamage}
            />
            <Grid sx={{ paddingTop: "24px" }}>{showYourSafetyCard && <YourSafetyNotificationCard />}</Grid>
          </Grid>
          <Grid item xs={12}>
            <CallToActionButton disabled={isSubmitting}>Next</CallToActionButton>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default WaterDamageForm;
