import { errorStateAtom, flowCompletedAtom } from "@/shared/atoms";
import useStandardPage from "@/shared/hooks/useStandardPage";
import { createPageTitle } from "@/shared/routing/TitleGuard";

import { ErrorState } from "@/shared/types";
import { StartYourClaimState } from "@/views/common/StartYourClaim/types";
import { RacwaStandardPageTemplate } from "@racwa/react-components";
import { SessionTimeoutContent, useClearSession, useGetSessionState } from "raci-react-library";
import { useEffect } from "react";
import { RouteObject } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { StartYourClaimRoute } from "../StartYourClaim";

export const heading = "Uh oh!";
export const pageName = "Session timed out";

export const SessionTimeout = () => {
  const clearSession = useClearSession();
  const setErrorState = useSetRecoilState(errorStateAtom);
  const setFlowCompleted = useSetRecoilState(flowCompletedAtom);
  const { claimNumber } = useGetSessionState<StartYourClaimState>(StartYourClaimRoute.path);

  const standardPageProps = useStandardPage();

  useEffect(() => {
    document.title = createPageTitle(pageName);
    clearSession();
    setErrorState(ErrorState.SessionExpired);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <RacwaStandardPageTemplate {...standardPageProps} heading={heading}>
      <SessionTimeoutContent
        variant="claims"
        claimNumber={claimNumber}
        startClaimAgainUrl={process.env.REACT_APP_TRIAGE_APP_BUILDING_AND_CONTENTS_URL ?? "#"}
        onStartClaimAgain={() => {
          setErrorState(ErrorState.None);
          setFlowCompleted(false);
          sessionStorage.clear();
        }}
      />
    </RacwaStandardPageTemplate>
  );
};

export const SessionTimeoutRoute = {
  path: "408",
  element: <SessionTimeout />,
} as const satisfies RouteObject;

export default SessionTimeout;
