import useStepperPage from "@/shared/hooks/useStepperPage";
import { createPageTitle } from "@/shared/routing/TitleGuard";
import { useFlowState } from "@/shared/stateMachine/hooks";
import { RacwaStepperTemplate } from "@racwa/react-components";
import { useEffect } from "react";
import { RouteObject } from "react-router-dom";
import { InvoiceQuoteForm } from "./Form";
import useInvoiceQuote from "./hooks/useInvoiceQuote";

export const pageNameFenceOnly = "Your excess";
export const pageName = "Your fence repairs";

export const InvoiceQuote = () => {
  const props = useInvoiceQuote();
  const stepperProps = useStepperPage();
  const { isFenceOnly } = useFlowState();

  useEffect(() => {
    document.title = createPageTitle(isFenceOnly ? pageNameFenceOnly : pageName);
  }, [isFenceOnly]);

  return (
    <RacwaStepperTemplate {...stepperProps}>
      <InvoiceQuoteForm {...props} />
    </RacwaStepperTemplate>
  );
};

export const InvoiceQuoteRoute = {
  path: "invoice-quote",
  element: <InvoiceQuote />,
} as const satisfies RouteObject;

export default InvoiceQuote;
