import { WaterDamageRoute } from "@/views/building/WaterDamage";
import { WaterDamageState } from "@/views/building/WaterDamage/types";
import { WaterDamageProblems } from "raci-claims-home-general-damage-clientproxy";
import { useGetSessionState } from "raci-react-library";

export const useGetBuildingWaterDamageSummary = (): string[] => {
  const { waterDamageProblems } = useGetSessionState<WaterDamageState>(WaterDamageRoute.path);
  const damageTypesOrder = Object.values(WaterDamageProblems);
  return (
    waterDamageProblems
      ?.sort(
        (a, b) =>
          damageTypesOrder.indexOf(a.key as WaterDamageProblems) -
          damageTypesOrder.indexOf(b.key as WaterDamageProblems),
      )
      .map((problem) => waterDamageProblems.find((option) => option.key === problem.key)?.label ?? "") ?? []
  );
};
