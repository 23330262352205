import { FormQuestions } from "@/shared/types";
import { FixturesAndFittingsFormValues } from "./types";

export const damageToFixturesOrFittingsName = "damageToFixturesOrFittings";

export const FixturesAndFittingsQuestions: FormQuestions<FixturesAndFittingsFormValues> = {
  damageToFixturesOrFittings: {
    id: damageToFixturesOrFittingsName,
    label: "Did the storm cause any damage to your fixtures and fittings?",
    name: damageToFixturesOrFittingsName,
  },
};
