import { flowCompletedAtom } from "@/shared/atoms";
import { useBffApiClient } from "@/shared/hooks/useApiClient";
import useErrorHandling from "@/shared/hooks/useErrorHandling";
import { StateMachineContext } from "@/shared/stateMachine/hooks";
import { stateUrlLookup } from "@/shared/stateMachine/lookups";
import {
  ClaimsHomeGeneralDamageApiException,
  YourBankDetailsRequest,
} from "raci-claims-home-general-damage-clientproxy";
import { AddNewAccountValue, useSessionState, useSetBackdrop } from "raci-react-library";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { YourBankDetailsFormProps, YourBankDetailsFormValues, YourBankDetailsState } from "../../types";

const createRequest = (state: YourBankDetailsState): YourBankDetailsRequest => {
  const request = {
    hasProvidedBankDetails: state.dontHaveBankDetails === false && state.mfaFailed !== true,
  };

  // undefined means the selector was not shown as no accounts were available
  if (state.bankAccountSelection !== undefined && state.bankAccountSelection !== AddNewAccountValue) {
    return { ...request, selectedBankAccount: state.bankAccountSelection };
  } else {
    if (request.hasProvidedBankDetails) {
      return {
        ...request,
        accountNumber: state.accountNumber,
        bsb: state.bsb,
        accountName: state.accountName,
      };
    } else {
      return request;
    }
  }
};

const useYourBankDetails = (): YourBankDetailsFormProps => {
  const actor = StateMachineContext.useActorRef();
  const apiClient = useBffApiClient();
  const setBackdrop = useSetBackdrop();
  const navigate = useNavigate();
  const handleError = useErrorHandling();
  const [yourBankDetailsState, setYourBankDetailsState] = useSessionState<YourBankDetailsState>({
    skipPageTrackingRecalculation: true,
  });
  const setFlowCompleted = useSetRecoilState(flowCompletedAtom);

  const form = useForm<YourBankDetailsFormValues>({
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues: yourBankDetailsState,
  });

  useEffect(() => {
    const fetchBankAccounts = async () => {
      try {
        setBackdrop(true);
        const response = await apiClient.getActiveAccounts();
        setYourBankDetailsState({
          ...yourBankDetailsState,
          activeAccounts: response.result?.sort((a, b) => (a.isDefault ? 1 : 0)) ?? [],
        });
      } catch (exception) {
        const error = exception as ClaimsHomeGeneralDamageApiException;
        handleError({
          message: "Error fetching bank accounts",
          shouldRedirect: true,
          customProperties: { request: "GET /accounts", status: error.status, error: error.message },
        });
      } finally {
        setBackdrop(false);
      }
    };

    if (!yourBankDetailsState.activeAccounts) {
      fetchBankAccounts();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit: YourBankDetailsFormProps["onSubmit"] = async (values) => {
    try {
      setBackdrop(true);
      values.dontHaveBankDetails = !!values.dontHaveBankDetails;
      const request = createRequest(values);
      const response = await apiClient.yourBankDetails(request);
      const wasBankAccountCaptured = response.result.successfullyCapturedBankAccount;

      setYourBankDetailsState({
        ...yourBankDetailsState,
        ...values,
        wasBankAccountCaptured,
        isCompleted: true,
      });
      setFlowCompleted(true);
      actor.send({
        type: "bankDetails.next",
      });
      navigate(stateUrlLookup[actor.getSnapshot().value]);
      return true;
    } catch (exception) {
      const error = exception as ClaimsHomeGeneralDamageApiException;
      handleError({
        message: "Error submitting bank details",
        shouldRedirect: true,
        customProperties: { request: "PATCH /step/your-bank-details", status: error.status, error: error.message },
      });
    } finally {
      setBackdrop(false);
    }

    return false;
  };

  return {
    defaultSelection: yourBankDetailsState.activeAccounts?.find((x) => x.isDefault)?.id,
    hasNoPaymentAccounts: yourBankDetailsState.activeAccounts?.length === 0,
    activeAccounts: yourBankDetailsState.activeAccounts,
    form,
    onSubmit,
  };
};

export default useYourBankDetails;
