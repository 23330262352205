import { useBffApiClient } from "@/shared/hooks/useApiClient";
import useErrorHandling from "@/shared/hooks/useErrorHandling";
import { StateMachineContext } from "@/shared/stateMachine/hooks";
import { stateUrlLookup } from "@/shared/stateMachine/lookups";
import { ClaimsHomeGeneralDamageApiException } from "raci-claims-home-general-damage-clientproxy";
import { useSessionState, useSetBackdrop } from "raci-react-library";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { SpecificDamageFormProps, SpecificDamageFormValues, SpecificDamageState } from "../../types";

export const useSpecificDamage = (): SpecificDamageFormProps => {
  const actor = StateMachineContext.useActorRef();
  const navigate = useNavigate();
  const apiClient = useBffApiClient();
  const setBackdrop = useSetBackdrop();
  const handleError = useErrorHandling();
  const [specificDamageState, setSpecificDamageState] = useSessionState<SpecificDamageState>({
    skipPageTrackingRecalculation: true,
  });

  const form = useForm<SpecificDamageFormValues>({
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues: specificDamageState,
  });

  const onSubmit = async (values?: SpecificDamageFormValues) => {
    try {
      setBackdrop(true);

      await apiClient.specificDamage({
        selectedSpecificDamageTypes: values?.damagedItems,
      });

      setSpecificDamageState({
        ...specificDamageState,
        ...values,
        isCompleted: true,
      });

      actor.send({
        type: "specificDamage.next",
      });

      navigate(stateUrlLookup[actor.getSnapshot().value]);
    } catch (exception) {
      const error = exception as ClaimsHomeGeneralDamageApiException;
      handleError({
        message: "Error submitting specific damage",
        shouldRedirect: true,
        customProperties: {
          request: "POST /step/specific-damage",
          status: error.status,
          error: error.message,
        },
      });
    } finally {
      setBackdrop(false);
    }
  };

  return {
    form,
    onSubmit,
  };
};

export default useSpecificDamage;
