import { CallToActionButton } from "@/shared/components/CallToActionButton";
import PageTitle from "@/shared/components/PageTitle";
import { Grid } from "@mui/material";
import { event, fieldTouched, gtm } from "@racwa/analytics";
import { FenceDamagedSides, SelectedSpecificDamageTypes } from "raci-claims-home-general-damage-clientproxy";
import { MultiChoiceInput, MultiChoiceOption } from "raci-react-library";
import { FormProvider, useWatch } from "react-hook-form";
import FenceLengthInput from "./components/FenceLengthInput";
import FencePaintInput from "./components/FencePaintInput";
import SelectFenceType from "./components/SelectFenceType";
import { YourFenceQuestions, fenceSideDamagedSideOptionsId } from "./constants";
import { YourFenceFormProps } from "./types";

export const multiChoiceInputDefaultId = "multiChoiceInput";
const { fenceDamagedSides } = YourFenceQuestions;

export const YourFenceForm = ({
  fenceTypes,
  form,
  onSubmit,
  fenceSideOptions,
  defaultFenceDamagedSideOptions,
}: YourFenceFormProps) => {
  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    formState: { isSubmitting },
  } = form;

  const selectedFenceTypeId = useWatch({
    control,
    name: "fenceType",
  });

  const hasTroubleMeasuring = useWatch({
    control,
    name: "fenceLength.hasTroubleMeasuring",
  });

  const canBePainted = fenceTypes?.find((f) => f.fenceTypeId === selectedFenceTypeId)?.canBePainted;
  const requiresMeasurement = fenceTypes?.find((f) => f.fenceTypeId === selectedFenceTypeId)?.requiresMeasurement;

  const syncFenceSideOptions = (option: MultiChoiceOption, checked: boolean) => {
    gtm(event(`Which fence has been damaged - ${option.label}`));

    const selectedOptions = getValues(fenceSideDamagedSideOptionsId);
    const newOptions = selectedOptions?.filter((item) => item.key !== option.key);

    if (checked) {
      newOptions.push(option);
    }

    setValue(fenceSideDamagedSideOptionsId, newOptions);
  };

  return (
    <FormProvider {...form}>
      <form action="#" onSubmit={handleSubmit(onSubmit)} data-testid="yourFenceForm" autoComplete="on" noValidate>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <PageTitle id="header" title="Your damaged fence" showClaimNumber />
          </Grid>
          <Grid item xs={12}>
            <SelectFenceType fenceTypes={fenceTypes} />
          </Grid>
          {requiresMeasurement && (
            <>
              <Grid item xs={12}>
                <FenceLengthInput />
              </Grid>
              {canBePainted && !hasTroubleMeasuring && (
                <Grid item xs={12}>
                  <FencePaintInput />
                </Grid>
              )}
            </>
          )}
          <Grid item xs={12}>
            <MultiChoiceInput
              name={fenceSideDamagedSideOptionsId}
              label={fenceDamagedSides.label}
              sublabel={fenceDamagedSides.subLabel}
              optionsFullWidth
              options={
                selectedFenceTypeId ===
                  fenceTypes?.find((f) => f.description === SelectedSpecificDamageTypes.Glass)?.fenceTypeId &&
                selectedFenceTypeId !== undefined
                  ? fenceSideOptions
                  : fenceSideOptions.filter((i) => i.label !== FenceDamagedSides.Pool_fence)
              }
              errorLabel="Please select all options that apply"
              data-testid={fenceSideDamagedSideOptionsId}
              onChange={() => gtm(fieldTouched(fenceDamagedSides.label))}
              defaultValue={defaultFenceDamagedSideOptions}
              onSelectionChange={(option, checked) => syncFenceSideOptions(option, checked)}
            />
          </Grid>
          <Grid item xs={12}>
            <CallToActionButton disabled={isSubmitting}>Next</CallToActionButton>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default YourFenceForm;
