import useStandardPage from "@/shared/hooks/useStandardPage";
import { createPageTitle } from "@/shared/routing/TitleGuard";
import { RacwaStandardPageTemplate, theme } from "@racwa/react-components";
import { RouteObject } from "react-router-dom";
import StormDamageClaimForm from "./Form";
import useStormDamageClaim from "./hooks";

export const heading = "Storm damage claim";
export const pageName = "Storm damage claim";

const StormDamageClaim = () => {
  const state = useStormDamageClaim();
  const standardPageProps = useStandardPage();

  return (
    <RacwaStandardPageTemplate {...standardPageProps} heading={heading} bannerTextColour={theme.palette.primary.main}>
      <StormDamageClaimForm {...state} />
    </RacwaStandardPageTemplate>
  );
};

export const StormDamageClaimRoute = {
  path: "storm-damage-claim",
  element: <StormDamageClaim />,
  loader: () => (document.title = createPageTitle(pageName)),
} as const satisfies RouteObject;

export default StormDamageClaim;
