import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { Grid, Typography } from "@mui/material";
import { CardWithIcon } from "raci-react-library";
import { cardContainerStyles } from "../../../styles";

export const WhatsNextCard: React.FC = () => {
  return (
    <Grid item xs={12} sx={cardContainerStyles}>
      <CardWithIcon
        icon={faEnvelope}
        title={<Typography variant="h3">What's next</Typography>}
        iconStyle={{ height: "24px", width: "24px" }}
        paragraphs={[
          <Typography variant="body1">
            Please check your email. This will have details on how we'll help and the timeframes.
          </Typography>,
          <Typography variant="body1">Please check your junk email too.</Typography>,
        ]}
        data-testid="whats-next"
      />
    </Grid>
  );
};

export default WhatsNextCard;
