import { Grid, Typography } from "@mui/material";
import { RacwaCardNotification } from "@racwa/react-components";

const BankDetailsNotice = () => {
  return (
    <Grid sx={{ marginTop: "1em" }}>
      <RacwaCardNotification severity="info" id="bank-details-information" title="Check your bank details are correct">
        <Typography variant="body2" id="bank-details-information-content">
          Please check the BSB and account number you entered are correct. If you enter incorrect details, the wrong
          account may be credited and you may not be able to recover the funds. To the extent permitted by law, RAC
          Insurance is not liable for loss arising from any error in instructions you give. We don't check the details
          you provide are correct or that the account name matches the account number.
        </Typography>
      </RacwaCardNotification>
    </Grid>
  );
};

export default BankDetailsNotice;
