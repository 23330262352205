import { Checkbox, ClickAwayListener, FormControlLabel } from "@mui/material";
import { event, fieldTouched, gtm } from "@racwa/analytics";
import { RacwaNumericInput } from "@racwa/react-components";
import { colors } from "@racwa/styles";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { YourFenceQuestions, fenceLengthId } from "../../constants";
import { FenceLengthValue } from "../../types";

export const fenceLengthDefaultCheckboxLabel = "I can't measure my fence.";
export const fenceLengthRequiredError = "Please enter a valid amount or select the checkbox below";
export const fenceLengthTooltipTitle = "Metres damaged";
export const fenceLengthTooltipMessage =
  "We use this figure to estimate your fence repair cost. If you make a mistake, you can contact us later and correct it.";

const { fenceLength } = YourFenceQuestions;

export const FenceLengthInput = () => {
  const [open, setOpen] = React.useState(false);
  const { setValue, control } = useFormContext();

  return (
    <Controller
      control={control}
      name={fenceLengthId}
      defaultValue={{ fenceLength: "", hasTroubleMeasuring: false }}
      rules={{
        validate: {
          valueEntered: (value) => {
            return (
              value.hasTroubleMeasuring ||
              (value.fenceLength !== "" && value.fenceLength > 0) ||
              fenceLengthRequiredError
            );
          },
        },
      }}
      render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => {
        const onTextChanged = (text: string) => {
          const typedValued = value as FenceLengthValue;
          typedValued.fenceLength = text;
          onChange(typedValued);
        };

        const onTextBlur = (text: string) => {
          const number = parseFloat(text);
          if (!Number.isNaN(number)) {
            const typedValued = value as FenceLengthValue;
            typedValued.fenceLength = (Math.round(number * 100) / 100).toFixed(0);
            setValue(fenceLengthId, typedValued);
          }
          onBlur();
        };

        const onCheckChanged = (checked: boolean) => {
          const typedValued = value as FenceLengthValue;
          typedValued.fenceLength = "";
          typedValued.hasTroubleMeasuring = checked;
          onChange(typedValued);
        };

        return (
          <ClickAwayListener onClickAway={() => setOpen(false)}>
            <div>
              <RacwaNumericInput
                color="primary"
                id={`${fenceLengthId}-textbox`}
                data-testid={fenceLengthId}
                label={fenceLength.label}
                sublabel={fenceLength.subLabel}
                disabledInput={value.hasTroubleMeasuring}
                placeholder="e.g. 11"
                value={value.fenceLength}
                fullWidth
                error={!!error}
                helperText={error?.message || ""}
                onChange={(e: any) => {
                  onTextChanged && onTextChanged(e.target.value);
                }}
                tooltipProps={{
                  id: `${fenceLengthId}-tooltip`,
                  open: open,
                  title: fenceLengthTooltipTitle,
                  message: fenceLengthTooltipMessage,
                  onClickClose: () => setOpen(false),
                  onClick: () => {
                    gtm(event(`Tooltip: ${fenceLength.label}`));
                    setOpen(true);
                  },
                }}
                slotProps={{
                  input: {
                    type: "number",
                    onWheel: (event) => event.currentTarget.blur(),
                  },
                }}
                onBlur={(e) => {
                  onTextBlur && onTextBlur(e.target.value);
                  gtm(fieldTouched(fenceLength.label));
                }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    id={`${fenceLengthId}-checkbox`}
                    name={`${fenceLengthId}-checkbox`}
                    data-testid={`${fenceLengthId}-checkbox`}
                    checked={value.hasTroubleMeasuring}
                    onChange={(e: any) => {
                      onCheckChanged && onCheckChanged(e.target.checked);
                      gtm(event(fenceLengthDefaultCheckboxLabel));
                    }}
                  />
                }
                label={fenceLengthDefaultCheckboxLabel}
                sx={{ color: error ? colors.brandDangerNew : null }}
              />
            </div>
          </ClickAwayListener>
        );
      }}
    />
  );
};
