import { Button, ButtonProps, styled } from "@mui/material";

const submitButtonId = "submit-button";

export const CallToActionButton = styled((props: ButtonProps) => (
  <Button
    type="submit"
    id={submitButtonId}
    data-testid={submitButtonId}
    color="primary"
    variant="contained"
    fullWidth
    {...props}
  />
))({
  margin: "24px 0 32px 0",
});
