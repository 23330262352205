import { FixturesAndFittingsRoute } from "@/views/building/FixturesAndFittings";
import { FixturesAndFittingsQuestions } from "@/views/building/FixturesAndFittings/constants";
import { FixturesAndFittingsFormValues, FixturesAndFittingsState } from "@/views/building/FixturesAndFittings/types";
import { useGetSessionState } from "raci-react-library";
import { FormSummary } from "../../types";

export const useGetFixturesAndFittingsSummary = (): FormSummary<FixturesAndFittingsFormValues> => {
  const { damageToFixturesOrFittings } = useGetSessionState<FixturesAndFittingsState>(FixturesAndFittingsRoute.path);

  return {
    damageToFixturesOrFittings: {
      label: FixturesAndFittingsQuestions.damageToFixturesOrFittings.label,
      content: damageToFixturesOrFittings ? "Yes" : "No",
    },
  };
};
