import PageTitle from "@/shared/components/PageTitle";

import { getInvoiceQuoteText } from "@/shared/utils/getInvoiceQuoteText";
import { FileUpload, NotificationCard, useGetSessionState } from "raci-react-library";
import { InvoiceQuoteRoute } from "../InvoiceQuote";
import { InvoiceQuoteState } from "../InvoiceQuote/types";
import { UploadInvoiceQuoteFormProps } from "./types";

const acceptedFileTypes = {
  "application/pdf": [".pdf"],
  "image/jpeg": [".jpg", ".jpeg"],
  "image/png": [".png"],
};

const maxFileSize = 8.5; // MB

export const UploadInvoiceQuoteForm = ({ onSubmit, ...props }: UploadInvoiceQuoteFormProps) => {
  const invoiceQuoteState = useGetSessionState<InvoiceQuoteState>(InvoiceQuoteRoute.path);
  const invoiceOrQuoteText = getInvoiceQuoteText(invoiceQuoteState);

  const sublabel = (remainingFiles: number, maxFiles: number) => {
    if (remainingFiles === 0) {
      return "You've uploaded the maximum number of files.";
    }

    if (remainingFiles === 1) {
      return "You can upload one more file for this claim.";
    }

    const remainingFilesText = remainingFiles === maxFiles ? `${remainingFiles}` : `${remainingFiles} more`;

    return `You can upload ${remainingFilesText} files for this claim.`;
  };

  return (
    <>
      <PageTitle id="header" title={`Now, please upload your ${invoiceOrQuoteText}`} showClaimNumber />
      <FileUpload
        {...props}
        label={`Upload your ${invoiceOrQuoteText}`}
        sublabel={sublabel}
        acceptedFileTypes={acceptedFileTypes}
        maxIndividualFileSize={maxFileSize}
        onSubmitFiles={async () => onSubmit()}
        buttonText="Next"
      >
        <NotificationCard
          severity="info"
          message={{
            title: "Or you can upload later",
            paragraphs: ["We'll email you to explain how."],
          }}
        />
      </FileUpload>
    </>
  );
};

export default UploadInvoiceQuoteForm;
