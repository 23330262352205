import { useFlowState } from "@/shared/stateMachine/hooks";
import { faMoneyBillWave } from "@fortawesome/free-solid-svg-icons";
import { Typography } from "@mui/material";
import { CardWithIcon, currencyFormatter } from "raci-react-library";
import { CardContent } from "./styles";

export interface ExcessCardProps {
  excess?: number;
}

export const ExcessCard: React.FC<ExcessCardProps> = ({ excess }) => {
  const { flow, fenceFlow } = useFlowState();

  const getExcessMessage = () => {
    if (flow?.includes("contents") || flow?.includes("building")) {
      return "Once we've assessed your claim, we'll let you know how to pay your excess.";
    }

    if (flow?.includes("fence")) {
      switch (fenceFlow) {
        case "shared":
          return "We'll deduct your excess from your cash settlement.";
        case "nonShared":
          return "We can't start repairs until you pay your excess. You can pay us or the repairer.";
        default:
          throw new Error(`Unknown fenceFlow: ${fenceFlow}`);
      }
    }
  };

  const excessMessage = getExcessMessage();

  const title =
    excess === 0
      ? "You don't have an excess"
      : `Your excess is ${currencyFormatter(excess ?? 0, { withDecimals: true, stripZeroCents: true })}`;
  const message =
    excess === 0
      ? [<CardContent>So you won't need to pay anything towards the settlement of your claim.</CardContent>]
      : [
          <CardContent>The excess is the amount you need to pay towards settlement of your claim.</CardContent>,
          <CardContent sx={{ paddingTop: "8px" }}>{excessMessage}</CardContent>,
        ];

  return (
    <CardWithIcon
      icon={faMoneyBillWave}
      title={<Typography variant="h3">{title}</Typography>}
      iconStyle={{ height: "24px", width: "24px" }}
      paragraphs={message}
      data-testid="your-excess"
    />
  );
};

export default ExcessCard;
