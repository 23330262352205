import { Link, Typography } from "@mui/material";
import { event, gtm, phoneCall } from "@racwa/analytics";
import { CreateClaimErrorResponseIneligibleReason, MissingDamages } from "raci-claims-home-general-damage-clientproxy";
import { InsurancePhoneNumber, NotificationCard } from "raci-react-library";
import { useEffect } from "react";

export interface IneligibleForClaimErrorProps {
  reason: CreateClaimErrorResponseIneligibleReason;
  duplicateClaimNumber?: string;
  missingDamages?: MissingDamages[];
}

export const IneligibleForClaimError: React.FC<IneligibleForClaimErrorProps> = ({
  reason,
  duplicateClaimNumber,
  missingDamages,
}) => {
  const getDamage = () => {
    if (missingDamages?.every((d) => d === MissingDamages.NonSharedFence || d === MissingDamages.SharedFence)) {
      return "fence";
    }

    if (missingDamages?.every((d) => d === MissingDamages.Contents)) {
      return "contents";
    }

    if (missingDamages?.every((d) => d === MissingDamages.Building)) {
      return "building";
    }

    if (
      missingDamages?.some((d) => d === MissingDamages.Building) &&
      missingDamages?.some((d) => d === MissingDamages.NonSharedFence || d === MissingDamages.SharedFence)
    ) {
      return "building or fence";
    }
  };

  const policyNotCovered = `Unfortunately, the cover you had on this date doesn't include ${getDamage()} damage`;
  const existingStormClaim = "This policy has an existing storm claim for this date";
  const policyNotActive = "Unfortunately, your policy wasn't active on this date";
  const cantClaimOnline = "Sorry, you can't claim online";

  useEffect(() => {
    if (reason === CreateClaimErrorResponseIneligibleReason.PolicyNotActive) {
      gtm(event(policyNotActive));
    } else if (
      reason === CreateClaimErrorResponseIneligibleReason.PolicyNotCoveredForParentCover ||
      reason === CreateClaimErrorResponseIneligibleReason.PolicyNotCoveredForChildCover
    ) {
      gtm(event(policyNotCovered));
    } else if (reason === CreateClaimErrorResponseIneligibleReason.DuplicateClaim) {
      gtm(event(existingStormClaim));
    } else if (reason === CreateClaimErrorResponseIneligibleReason.PolicyChangedSinceEventDate) {
      gtm(event(cantClaimOnline));
    }
  }, [reason, policyNotCovered]);

  const messageContent = (
    <Typography variant="body2">
      If you'd like to discuss this or if you think there's been a mistake, call us on{" "}
      <Link href={InsurancePhoneNumber.href} onClick={() => gtm(phoneCall(InsurancePhoneNumber.displayWithoutSpaces))}>
        {InsurancePhoneNumber.display}
      </Link>
      .
    </Typography>
  );

  if (reason === CreateClaimErrorResponseIneligibleReason.PolicyNotActive) {
    return (
      <NotificationCard
        severity="error"
        message={{
          title: policyNotActive,
          content: messageContent,
        }}
      />
    );
  } else if (
    reason === CreateClaimErrorResponseIneligibleReason.PolicyNotCoveredForChildCover ||
    reason === CreateClaimErrorResponseIneligibleReason.PolicyNotCoveredForParentCover
  ) {
    return (
      <NotificationCard
        severity="error"
        message={{
          title: policyNotCovered,
          content: messageContent,
        }}
      />
    );
  } else if (reason === CreateClaimErrorResponseIneligibleReason.DuplicateClaim) {
    const duplicateClaimContent = (
      <Typography variant="body2">
        To continue this claim, please call{" "}
        <Link
          href={InsurancePhoneNumber.href}
          onClick={() => gtm(phoneCall(InsurancePhoneNumber.displayWithoutSpaces))}
        >
          {InsurancePhoneNumber.display}
        </Link>{" "}
        and quote the previous <b>claim number {duplicateClaimNumber}</b>.
      </Typography>
    );

    return (
      <NotificationCard severity="error" message={{ title: existingStormClaim, content: duplicateClaimContent }} />
    );
  } else if (reason === CreateClaimErrorResponseIneligibleReason.PolicyChangedSinceEventDate) {
    const messageContent = (
      <Typography variant="body2">
        We're having trouble matching the details on your policy.
        <Typography variant="body2" pt={1}>
          Please call us on{" "}
          <Link
            href={InsurancePhoneNumber.href}
            onClick={() => gtm(phoneCall(InsurancePhoneNumber.displayWithoutSpaces))}
          >
            {InsurancePhoneNumber.display}
          </Link>{" "}
          so we can help you with your claim.
        </Typography>
      </Typography>
    );

    return <NotificationCard severity="error" message={{ title: cantClaimOnline, content: messageContent }} />;
  } else {
    return null;
  }
};

export default IneligibleForClaimError;
