import { useBffApiClient } from "@/shared/hooks/useApiClient";
import useErrorHandling from "@/shared/hooks/useErrorHandling";
import { FenceFlow, Flow } from "@/shared/stateMachine";
import { StateMachineContext } from "@/shared/stateMachine/hooks";
import { stateUrlLookup } from "@/shared/stateMachine/lookups";
import { event, gtm } from "@racwa/analytics";
import { formatISO } from "date-fns";
import {
  ClaimsHomeGeneralDamageApiException,
  CreateClaimErrorResponse,
  CreateClaimErrorResponseIneligibleReason,
  CreateClaimRequest,
  Damages,
} from "raci-claims-home-general-damage-clientproxy";
import { HTTP_STATUS_CODE_BAD_REQUEST, HTTP_STATUS_CODE_OK, useSessionState, useSetBackdrop } from "raci-react-library";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { StartYourClaimFormProps, StartYourClaimFormValues, StartYourClaimState } from "../../types";

const createRequest = (
  values: StartYourClaimState,
  allowSimilarClaim: boolean,
  flow?: Flow[],
  fenceFlow?: FenceFlow,
): CreateClaimRequest => {
  // can be confident the form didn't submit without Date/Time validation
  // the time is derived from the date picked and time, so it contains the full value
  const dateTime = values.time!;

  const damages = flow?.map((f) => {
    switch (f) {
      case "contents":
        return Damages.Contents;
      case "fence":
        return fenceFlow === "shared" ? Damages.SharedFence : Damages.NonSharedFence;
      case "building":
        return Damages.Building;
      default:
        throw new Error(`Unknown flow: ${f}`);
    }
  });

  const request: CreateClaimRequest = {
    // JavaScript serialises this date incorrectly
    // their ISO format is a "simplified" version of the actual ISO format
    // @ts-expect-error
    eventDate: formatISO(dateTime),
    allowSimilarClaim,
    damages,
  };

  return request;
};

export const useStartYourClaim = (): StartYourClaimFormProps => {
  const { flow, fenceFlow } = StateMachineContext.useSelector((state) => ({
    flow: state.context.flow,
    fenceFlow: state.context.fenceFlow,
  }));
  const actor = StateMachineContext.useActorRef();
  const navigate = useNavigate();
  const [startYourClaimState, setStartYourClaimState] = useSessionState<StartYourClaimState>({
    skipPageTrackingRecalculation: true,
  });
  const setBackdrop = useSetBackdrop();
  const apiClient = useBffApiClient();
  const handleError = useErrorHandling();
  const isClaimCreated = !!startYourClaimState.claimNumber;
  const claimNumber = startYourClaimState.claimNumber;
  const isIneligibleForClaim =
    !!startYourClaimState.error &&
    startYourClaimState.error.ineligibleReason !== CreateClaimErrorResponseIneligibleReason.SimilarClaim;
  const [disabled, setDisabled] = useState(isClaimCreated || isIneligibleForClaim);
  const [showSimilarClaimDialog, setShowSimilarClaimDialog] = useState<boolean>(false);

  const state = {
    ...startYourClaimState,
    date: startYourClaimState.date ? new Date(startYourClaimState.date) : undefined,
    time: startYourClaimState.time ? new Date(startYourClaimState.time) : undefined,
  };

  const redirectToNextPage = () => {
    actor.send({
      type: "startYourClaim.next",
      claimCreated: true,
    });

    navigate(stateUrlLookup[actor.getSnapshot().value]);
  };

  const createClaim = async (newValues: StartYourClaimState, allowSimilarClaim?: boolean) => {
    const request = createRequest(newValues, allowSimilarClaim ?? false, flow, fenceFlow);

    try {
      setBackdrop(true);
      const response = await apiClient.createClaim(request);
      if (response.status === HTTP_STATUS_CODE_OK) {
        const responseState = {
          isCompleted: true,
          claimNumber: response.result.claimNumber,
          excess: response.result.excess,
          ineligibleForClaimError: undefined,
        };

        const state: StartYourClaimState = {
          ...newValues,
          ...responseState,
          firstName: startYourClaimState.firstName,
        };

        setStartYourClaimState(state);

        redirectToNextPage();
      }
    } catch (exception) {
      const error = exception as ClaimsHomeGeneralDamageApiException;
      if (error.status === HTTP_STATUS_CODE_BAD_REQUEST) {
        const result = error.result as CreateClaimErrorResponse;
        const responseState: StartYourClaimState = {
          ...newValues,
          ...startYourClaimState,
          isCompleted: result.ineligibleReason !== CreateClaimErrorResponseIneligibleReason.SimilarClaim,
          error: {
            ineligibleReason: result.ineligibleReason,
            duplicateOrSimilarClaimNumber: result.duplicateOrSimilarClaimNumber,
            missingDamages: result.missingDamages,
            // the generated dto has the date as javascript Date, but the returned value from the API is actually a string
            // @ts-expect-error
            similarClaimEventDate: result.similarClaimEventDate,
          },
        };

        setStartYourClaimState(responseState);
        if (result.ineligibleReason === CreateClaimErrorResponseIneligibleReason.SimilarClaim) {
          setShowSimilarClaimDialog(true);
        } else {
          actor.send({ type: "startYourClaim.error", claimCreated: false });
          setDisabled(true);
        }
      } else {
        handleError({
          message: "Error creating claim",
          shouldRedirect: true,
          customProperties: { request: "POST /claims/claim", status: error.status, error: error.message },
        });
      }
    } finally {
      setBackdrop(false);
    }
  };

  const onSimilarClaimAllowed = async (newValues: StartYourClaimState) => {
    gtm(event("Or make a new claim"));
    setShowSimilarClaimDialog(false);
    return createClaim(newValues, true);
  };

  const onSubmit = async (newValues: StartYourClaimFormValues) => {
    if (!isClaimCreated) {
      return createClaim(newValues);
    }

    redirectToNextPage();
  };

  const form = useForm<StartYourClaimFormValues>({
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues: state,
  });

  return {
    form,
    policy: startYourClaimState.policyDetails,
    onSubmit,
    onSimilarClaimAllowed,
    disabled,
    claimNumber,
    isClaimCreated,
    showSimilarClaimDialog,
    error: startYourClaimState.error,
    showClaimDetailsLockedCard: isClaimCreated,
  };
};

export default useStartYourClaim;
