import { useDocumentServiceApiClient } from "@/shared/hooks/useApiClient";
import { useFileUploadSession } from "raci-react-library";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export const DocumentServiceSession = () => {
  const documentServiceApiClient = useDocumentServiceApiClient();
  const location = useLocation();
  const { completed } = useFileUploadSession({ apiClient: documentServiceApiClient });

  useEffect(() => {
    if (completed) {
      documentServiceApiClient.resetSessionExpiry();
    }
  }, [completed, documentServiceApiClient, location.pathname]);

  return null;
};
