import useStepperPage from "@/shared/hooks/useStepperPage";
import { createPageTitle } from "@/shared/routing/TitleGuard";
import YourSettlementOptionsForm from "@/views/nonSharedFence/YourSettlementOptions/Form";
import { useYourSettlementOptions } from "@/views/nonSharedFence/YourSettlementOptions/hooks/useYourSettlementOptions";
import { RacwaStepperTemplate } from "@racwa/react-components";

export const pageName = "Your settlement options";

export const YourSettlementOptions: React.FC = () => {
  const props = useYourSettlementOptions();
  const stepperProps = useStepperPage();

  return (
    <RacwaStepperTemplate {...stepperProps}>
      <YourSettlementOptionsForm {...props} />
    </RacwaStepperTemplate>
  );
};

export const YourSettlementOptionsRoute = {
  path: "settlement-options",
  element: <YourSettlementOptions />,
  loader: () => (document.title = createPageTitle(pageName)),
} as const;

export default YourSettlementOptions;
