import { SafetyAndSecurityRoute } from "@/views/common/SafetyAndSecurity";
import { SafetyAndSecurityFormValues, SafetyAndSecurityState } from "@/views/common/SafetyAndSecurity/types";
import { useGetSessionState } from "raci-react-library";
import { FormSummary } from "../../types";

export const useGetSafetyAndSecuritySummary = (): FormSummary<SafetyAndSecurityFormValues> => {
  const { isSafetyIssue } = useGetSessionState<SafetyAndSecurityState>(SafetyAndSecurityRoute.path);
  return {
    isSafetyIssue: {
      label: "Safety concerns",
      content: isSafetyIssue ? "Yes" : "No",
    },
  };
};
