import { TableContainer, styled } from "@mui/material";

export const StyledTableContainer = styled(TableContainer)({
  "& p": {
    margin: "0px",
  },
  "& tbody > tr:last-child > td": {
    borderBottom: "0",
  },
  "& td": {
    padding: "12px 0",
  },
  "& button": {
    padding: "12px",
  },
});
